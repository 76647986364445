import { Modal } from 'antd'
import { CustomButton, Loading } from 'components'
import React from 'react'

const ModalConfirmRecommendationService = ({ open, onCancel, onConfirm, isLoading = false }) => {
  return (
    <Modal
      open={open}
      wrapClassName=""
      footer={null}
      width=""
      centered
      className={`overflow-y-hidden rounded-lg w-full max-w-md`}
      zIndex={999}
      onCancel={onCancel}
      maskClosable={false}
      openModal={open}
      title={<span className="text-xl-medium text-gray-900">Edit Servis</span>}>
      <div className="flex flex-col w-full gap-8">
        <span className="font-medium text-left text-gray-500">
          Apakah Anda yakin menyimpan perubahan servis?
        </span>
        <div className="flex flex-row w-full items-center justify-end space-x-4">
          {isLoading ? (
            <Loading title="" visible classNameWidth="" />
          ) : (
            <>
              <CustomButton type="plain" onClick={() => onCancel()}>
                Cancel
              </CustomButton>
              <CustomButton onClick={() => onConfirm()}>Confirm</CustomButton>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ModalConfirmRecommendationService
