import React, { useRef, useState, useEffect } from 'react'
import { Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useReactToPrint } from 'react-to-print'
import { useHistory } from 'react-router-dom'
import { HeaderPrint, SalesOrderPrintCard } from 'components'
import {
  inspeksiSalesOrderDataDummies,
  jadwalServiceSalerOrderDataDummies,
  jualUnitKatalogSalesOrderDataDummeies
} from 'assets/dummies'

const SalesOrderPrintPage = () => {
  const rootMenu = window.location.pathname.split('/')[1]
  const router = useHistory()
  const [salesOrder, setSalesOrder] = useState()
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    pageStyle: `@media print {
      @page {
        margin: 2cm 2cm 2cm 2cm !important;
      }
    }`,
    content: () => componentRef.current,
    documentTitle: `${window.location.pathname.split('/')[1]}-${
      window.location.pathname.split('/')[2]
    }-sales-order`
  })

  useEffect(() => {
    switch (rootMenu) {
      case 'jual-unit':
        return setSalesOrder(jualUnitKatalogSalesOrderDataDummeies)
      case 'services':
        return setSalesOrder(jadwalServiceSalerOrderDataDummies)
      case 'inspeksi':
        return setSalesOrder(inspeksiSalesOrderDataDummies)
      default:
        break
    }
  }, [])

  return (
    <Layout>
      <Content className="flex w-screen justify-center py-[2%] px-[30%] bg-[#F5F5F5]">
        <div className="flex flex-col w-full space-y-5">
          <HeaderPrint
            titleDownload="Download Sales Order"
            onPressDownload={handlePrint}
            onPressBack={() => router.goBack()}
          />
          {salesOrder ? (
            <SalesOrderPrintCard
              key={`${window.location.pathname.split('/')[1]}-sales-order`}
              ref={componentRef}
              data={salesOrder}
            />
          ) : null}
        </div>
      </Content>
    </Layout>
  )
}

export default SalesOrderPrintPage
