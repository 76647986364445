import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiGet, urls } from 'api'
import { CardContainer, CustomButton } from 'components'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { logout } from 'store/actions'
import CustomStep from './components/CustomStep'
import useServicePackets from './hooks/useServicePackets'
import CardServiceCart from './components/card/card-service-cart'
import useCartService from './hooks/useCartService'
import CardReviewServis from './components/card/card-review-servis'
import CardPilihLayananSparepart from './components/card/card-pilih-layanan-sparepart'
import ModalConfirmRecommendationService from './components/modal/modal-confirm-recommendation-service'

const stepList = ['Edit Servis', 'Review']

const RekomendasiServisPage = ({ isMechanicView = false }) => {
  const { idUnit } = useParams()
  const router = useHistory()
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }
  const [detailServis, setDetailServis] = useState({})
  const [recommendationServiceData, setRecommendationServiceData] = useState({})
  const [currentStep, setCurrentStep] = useState(0)
  const [modalConfirm, setModalConfirm] = useState(false)
  const { cartList, cartIsLoading, handleSimulateCartService, deleteCartList } =
    useCartService(idUnit)
  const {
    removeCartItem,
    handlePacketService,
    handleGeneralService,
    services,
    servicePackets,
    servicesGenerals,
    selectedGeneralServiceIds,
    selectedPacketIds,
    changeKeywordHandler,
    keyword,
    handleSelectedCategory,
    selectedCategory,
    serviceCategories,
    handleTampilLebihBanyak,
    servicesIsFetching,
    isAddServisBtnDisabled
  } = useServicePackets(
    detailServis?.unit_information?.type?.id,
    recommendationServiceData?.general_services,
    recommendationServiceData?.packets
  )

  useQuery({
    queryKey: ['detail-data'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.servicePickup.root}/${idUnit}?is_show_relation=true`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setDetailServis(res?.resData?.data ?? {})
    }
  })
  useQuery({
    queryKey: ['getRecommendationServiceData'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.booking}/${idUnit}/v3/${urls?.service?.recommendationService}/calculation`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setRecommendationServiceData(res?.resData?.data ?? {})
    }
  })

  useEffect(() => {
    if (servicePackets.length > 0 || servicesGenerals.length > 0) {
      handleSimulateCartService(servicePackets, servicesGenerals, detailServis?.unit_id)
    } else {
      deleteCartList()
    }
  }, [selectedGeneralServiceIds, servicePackets, servicesGenerals])

  useEffect(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [currentStep])
  function handleNextStep(nextStep) {
    // Submit
    if (nextStep > stepList.length - 1) {
      return openModalConfirm()
    } else {
      setCurrentStep(nextStep)
    }
  }

  function handlePreviousStep(prevStep) {
    if (prevStep < 0) {
      router.goBack(-1)
    } else {
      setCurrentStep(prevStep)
    }
  }

  function openModalConfirm() {
    setModalConfirm(true)
  }
  function closeModalConfirm() {
    setModalConfirm(false)
  }
  function submitRecommendationService() {
    return handleSimulateCartService(servicePackets, servicesGenerals, detailServis?.unit_id, true)
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%', minHeight: '100vh' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">Servis</Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() =>
            isMechanicView
              ? router.push('/mekanik/services/jadwal')
              : router.push('/services/jadwal')
          }>
          Jadwal Service
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="font-inter font-medium text-gray-500 cursor-pointer"
          onClick={() => router.go(-2)}>
          {detailServis?.workshop_name}
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => router.go(-1)}
          className="font-inter font-medium text-gray-500 cursor-pointer">
          {detailServis?.unit_name}
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => router.go(-1)}
          className="font-inter font-medium text-gray-500 cursor-pointer">
          Detail Servis
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Rekomendasi Servis
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        <section className="flex flex-col w-full space-y-5">
          <CardContainer className={'flex justify-between items-center h-full'}>
            <h1 className="text-gray-900 display-xs-semibold h-full !mb-0">Edit Servis</h1>
            <CustomStep currentStep={currentStep} options={stepList} />
          </CardContainer>
          {currentStep === 0 ? (
            <div className="grid grid-cols-12 w-full gap-6">
              <CardPilihLayananSparepart
                keyword={keyword}
                isAddServisBtnDisabled={isAddServisBtnDisabled}
                handleTampilLebihBanyak={handleTampilLebihBanyak}
                changeKeywordHandler={changeKeywordHandler}
                cartIsLoading={cartIsLoading}
                services={services}
                servicesIsFetching={servicesIsFetching}
                selectedGeneralServiceIds={selectedGeneralServiceIds}
                selectedPacketIds={selectedPacketIds}
                handlePacketService={handlePacketService}
                handleGeneralService={handleGeneralService}
                handleSelectedCategory={handleSelectedCategory}
                selectedCategory={selectedCategory}
                serviceCategories={serviceCategories}
              />
              {/* CART SERVICE */}
              <div className={'col-span-4 flex-col w-full h-full gap-6'}>
                <CardServiceCart
                  handlePacketService={handlePacketService}
                  handleGeneralService={handleGeneralService}
                  unitTypeId={detailServis?.unit_information?.type?.id}
                  cartIsLoading={cartIsLoading}
                  cartList={cartList !== undefined ? cartList : detailServis}
                  removeCartItem={removeCartItem}
                />
                <div className="flex gap-2 justify-end flex-wrap-reverse lg:flex-row lg:items-center">
                  <CustomButton
                    type="plain"
                    onClick={() => {
                      handlePreviousStep(currentStep - 1)
                    }}>
                    Kembali
                  </CustomButton>
                  <CustomButton
                    disabled={
                      (Object.keys(selectedGeneralServiceIds).length === 0 &&
                        Object.keys(selectedPacketIds).length === 0) ||
                      cartIsLoading
                    }
                    onClick={() => {
                      handleNextStep(currentStep + 1)
                    }}>
                    Selanjutnya
                  </CustomButton>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-6">
              <CardReviewServis data={cartList} />
              <div className="flex gap-2 justify-end items-center">
                <CustomButton
                  type="plain"
                  onClick={() => {
                    handlePreviousStep(currentStep - 1)
                  }}>
                  Sebelumnya
                </CustomButton>
                <CustomButton
                  onClick={() => {
                    handleNextStep(currentStep + 1)
                  }}>
                  Konfirmasi
                </CustomButton>
              </div>
            </div>
          )}
        </section>
      </Content>
      {modalConfirm ? (
        <ModalConfirmRecommendationService
          open={modalConfirm}
          isLoading={cartIsLoading}
          onConfirm={submitRecommendationService}
          onCancel={closeModalConfirm}
        />
      ) : null}
    </Layout>
  )
}

export default RekomendasiServisPage
