import { Input, Modal, Select, Spin, Typography } from 'antd'
import { apiGet } from 'api'
import { IcCheck, IcSearch } from 'assets/icons'
import { debounce } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { formatCurrency } from 'utils'

const ModalLayananSparepartList = ({ isOpen, onSubmit, onCancel, data, isSparepart }) => {
  const [selectedData, setSelectedData] = useState(data)
  const [category, setCategory] = useState()
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const [listCategory, setListCategory] = useState([])
  const [listItems, setListItems] = useState([])

  const categoryData = useQuery({
    queryKey: ['category-list'],
    refetchOnWindowFocus: false,
    queryFn: async ({ signal }) => {
      const res = await apiGet({
        token,
        url: isSparepart
          ? 'servis-service/admin/sparepart-categories/all'
          : 'servis-service/admin/service-categories/all',
        onLogout: () => dispatch(logout()),
        signal
      })
      return res
    },
    onSuccess: (res) => {
      // setSelectedData(data ?? [])
      setSearch('')
      setListCategory(res?.resData?.data ?? [])
    },
    enabled: isOpen
  })

  const {
    isLoading: isItemListLoading,
    isFetching,
    isRefetching
  } = useQuery({
    queryKey: ['service-sparepart-type-list', category, search],
    refetchOnWindowFocus: false,
    queryFn: async ({ signal }) => {
      let params = isSparepart
        ? {
            sparepart_category_id: category,
            keywords: search
          }
        : {
            service_category_id: category,
            keywords: search
          }
      const res = await apiGet({
        token,
        url: isSparepart
          ? 'servis-service/admin/spareparts/all'
          : 'servis-service/admin/general-services/all',
        data: params,
        onLogout: () => dispatch(logout()),
        signal
      })
      return res
    },
    onSuccess: (res) => {
      setListItems(res?.resData?.data ?? [])
    },
    enabled: isOpen && !categoryData.isLoading
  })

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 300)
  }, [])
  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })
  useEffect(() => {
    return () => {
      // setSelectedData([])
      setCategory(null)
    }
  }, [])
  function handleSearch(e) {
    setSearch(e.target.value)
  }

  const filterData = useMemo(() => {
    return listItems?.filter((item) => item?.name?.toLowerCase().includes(search.toLowerCase()))
  }, [search, listItems, data])

  const onClickitem = (item) => {
    let newArray = [...selectedData]
    let index = newArray.findIndex((unit) => unit?.id === item.id)
    if (index >= 0) {
      newArray.splice(index, 1)
    } else {
      newArray.push(item)
    }
    setSelectedData(newArray)
  }

  // const onEditSelected = (key, idx, value) => {
  //   let newArray = [...selectedData]
  //   if (idx >= 0) {
  //     newArray[idx] = {
  //       ...newArray[idx],
  //       [key]: key === 'qty' ? (value === '' ? '' : parseInt(value)) : value
  //     }
  //   }
  //   setSelectedData(newArray)
  // }
  return (
    <Modal
      width={400}
      centered
      maskClosable={false}
      title={`Pilih ${isSparepart ? 'Sparepart' : 'Layanan'}`}
      open={isOpen}
      onCancel={() => {
        onCancel()
        setSelectedData([])
        setCategory(null)
      }}
      okText={'Confirm'}
      // confirmLoading={isSubmitLoading}
      onOk={() => {
        onSubmit(selectedData)
      }}
      okButtonProps={{ className: 'bg-primary-500 rounded-md', type: 'danger' }}
      cancelButtonProps={{
        className: 'hover:text-[#000] rounded-md hover:border-[#000]',
        type: 'default'
      }}>
      <div className="bg-gray-50 p-2 rounded-lg">
        <Select
          showSearch
          className="custom-table-limit"
          placeholder="Pilih Kategori"
          style={{ width: '100%' }}
          value={category}
          // onSearch={e => setSearchCategory(e)}
          onChange={(value) => {
            setCategory(value)
          }}
          filterOption={(input, option) =>
            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }>
          {listCategory?.map((item) => (
            <Select.Option key={item?.id?.toString()} value={item?.id}>
              {item?.name}
            </Select.Option>
          ))}
        </Select>
        <Input
          className="rounded-md py-1.5 mt-2"
          placeholder={`Cari ${isSparepart ? 'Sparepart' : 'Layanan'}`}
          prefix={<IcSearch className="text-gray-400" />}
          onChange={debouncedResults}
        />
        <div>
          <div className="bg-gray-50 mt-2 p-2 flex items-center rounded-t-lg border-solid border-gray-200 hover:cursor-pointer">
            <div className="flex-1">
              <Typography.Text className="mx-2 font-semibold">
                {isSparepart ? 'Sparepart' : 'Layanan'}
              </Typography.Text>
            </div>
            {isSparepart ? (
              <Typography.Text className="mx-4 font-semibold">Harga</Typography.Text>
            ) : null}
          </div>
        </div>
        <div className="overflow-y-auto h-40 border-solid border-gray-200">
          {isItemListLoading || isRefetching || isFetching ? (
            <div className="flex h-full w-full justify-center items-center">
              <Spin size="large" />
            </div>
          ) : filterData?.length > 0 ? (
            filterData?.map((item, idx) => (
              <div
                key={`list-data-${idx.toString()}`}
                className="bg-white p-2 flex items-center rounded-lg mb-1 border-solid border-white hover:cursor-pointer"
                onClick={() => onClickitem(item)}>
                {/* <span className='bg-primary-50 rounded-full flex w-fit items-center justify-center p-2'>
                            </span> */}
                {selectedData.some((e) => e.id === item.id) ? (
                  <div className="w-4 h-4 rounded-lg bg-primary-900 flex items-center justify-center">
                    <IcCheck className="text-white" size={13} />
                  </div>
                ) : (
                  <div className="w-4 h-4 rounded-md bg-whites border-solid border-gray-200" />
                )}
                <div className="flex-1">
                  <Typography.Text className="mx-2 block text-gray-400">{item.sku}</Typography.Text>
                  <Typography.Text className="mx-2 block">{item.name}</Typography.Text>
                </div>
                {isSparepart ? (
                  <Typography.Text className="mr-2 ml-4 font-semibold">
                    {formatCurrency(item?.price ?? '0')}
                  </Typography.Text>
                ) : null}
              </div>
            ))
          ) : filterData.length === 0 && !isItemListLoading ? (
            <div className="w-full h-full flex justify-center items-center">
              <span className="text-gray-700">
                Tidak ada {isSparepart ? 'Sparepart' : 'Layanan'}
              </span>
            </div>
          ) : null}
        </div>
      </div>
      <div>
        <span className="font-medium mb-2">
          {isSparepart ? 'Sparepart' : 'Layanan'} yang dipilih :{' '}
        </span>
        <div className="max-h-28 overflow-y-auto">
          {selectedData?.map((data, idx) => (
            <div
              className="flex justify-between items-center py-2 border border-solid border-x-0 border-t-0 border-b border-gray-300"
              key={`list-selected-${idx.toString()}`}>
              <span className="flex-1">{data.name}</span>
              {isSparepart ? (
                <span className="flex-1 text-right font-semibold">
                  {formatCurrency(data?.price ?? '0')}
                </span>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default ModalLayananSparepartList
