import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Input, Select, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Button, Loading } from 'components'
import { IcChevronRight, IcClose, IcPlus, IcSearch } from 'assets/icons'
import { apiGet, apiPost, urls } from 'api'
import { useQuery } from 'react-query'
import {
  formatCurrency,
  showSuccessMessage,
  showWarningMessage,
  sortSparepartRecommendation
} from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import ModalOpsiSparepart from './ModalOptionSparepart'
import { logout, setServisStep2 } from 'store/actions'

const ServisMobilComponentStep2 = ({ data }) => {
  const token = useSelector((state) => state.auth.token)
  const servis = useSelector((state) => state.servis)
  const dispatch = useDispatch()
  const router = useHistory()
  const [dataPaket, setDataPaket] = useState([])
  const [dataGeneralServis, setDataGeneralServis] = useState([])
  const [dataServis2, setDataServis2] = useState([])
  const [selectedServis, setSelectedServis] = useState(null)
  const [service, setService] = useState({
    service_packets: {
      data: {},
      total: 0
    },
    service_generals: {
      data: {},
      total: 0
    },
    total: 0
  })
  const [serviceForBE, serServiceForBE] = useState(null)
  const [curServis, setCurServis] = useState({
    service_packets: {
      data: {},
      total: 0
    },
    service_generals: {
      data: {},
      total: 0
    },
    total: 0
  })
  const [simulateData, setSimulateData] = useState(null)
  const [modal, setModal] = useState({
    show: false,
    type: null,
    data: {},
    selected: {}
  })
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)
  const [searchLayanan, setSearchLayanan] = useState('')
  const [isBtnLoadMoreDisabled, setIsBtnLoadMoreDisabled] = useState(false)

  const { isLoading } = useQuery({
    queryKey: [
      'layanan-service-list'
      // searchLayanan
    ],
    queryFn: async () => {
      const params = {
        // keywords: searchLayanan,
        type_id: data?.type?.id
      }
      const res = await apiGet({
        token,
        data: params,
        url: `${urls?.service?.prefix}/services/all`,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      const generalRepairId = res?.resData?.data
        ? res?.resData?.data?.find((item) => item?.name?.toLowerCase().includes('general repair'))
            ?.id
        : null
      getDetailLayanan({ id: generalRepairId, search: '' })
      setSelectedServis(generalRepairId)
      setDataServis2(res?.resData?.data)
    },
    refetchOnWindowFocus: false
  })

  const getDetailLayanan = async ({
    id = selectedServis,
    refresh = true,
    search = searchLayanan
  }) => {
    setIsLoadingCRUD(true)
    let selectGeneralServiceIds = []
    simulateData?.general_services?.forEach((item) => {
      selectGeneralServiceIds.push(item?.general_service_id)
    })
    simulateData?.packets?.forEach((item) => {
      item?.spareparts?.forEach((spare) => {
        selectGeneralServiceIds.push(spare?.general_service_id)
      })
    })

    let withoutGeneralServiceIds = []
    let withoutPacketIds = []

    dataGeneralServis?.forEach((item) => {
      withoutGeneralServiceIds.push(item?.id)
    })
    dataPaket?.forEach((item) => {
      withoutPacketIds.push(item?.id)
    })

    const params = {
      type_id: data?.type?.id,
      limit: 5,
      search: search,
      selected_general_service_ids: selectGeneralServiceIds,
      without_general_service_ids: refresh ? [] : withoutGeneralServiceIds,
      without_packet_ids: refresh ? [] : withoutPacketIds
    }
    const { resData } = await apiPost({
      token,
      data: params,
      url: `${urls?.service?.prefix}/services/${id}`,
      onLogout: () => dispatch(logout())
    })

    if (resData?.data?.general_services?.length === 0 && resData?.data?.packets?.length === 0) {
      setIsLoadingCRUD(false)
      setIsBtnLoadMoreDisabled(true)
      return showSuccessMessage({ content: 'Semua Data Servis Sudah Ditampilkan' })
    }
    // Sorting Recommendation for General Service & Packet Service
    // Sorting Packet
    resData?.data?.packets?.map((packet) => {
      packet?.general_services?.map((general_service) => {
        if (general_service?.spareparts?.length > 0) {
          const newSparepartArray = sortSparepartRecommendation(general_service?.spareparts)
          general_service.spareparts = newSparepartArray
        }
      })
    })
    // Sorting General Service
    resData?.data?.general_services?.map((general_service) => {
      if (general_service?.spareparts?.length > 0) {
        const newSparepartArray = sortSparepartRecommendation(general_service?.spareparts)
        general_service.spareparts = newSparepartArray
      }
    })

    if (refresh) {
      setDataGeneralServis(resData?.data?.general_services ?? [])
      let newDataPaket = resData?.data?.packets?.map((paket) => {
        let total = 0
        paket?.general_services?.map((general) => {
          total = total + general.price
        })
        return {
          ...paket,
          price: total
        }
      })
      setDataPaket(newDataPaket ?? [])
    } else {
      setDataGeneralServis((prev) => [...prev, ...(resData?.data?.general_services ?? [])])
      let newDataPaket = resData?.data?.packets?.map((paket) => {
        let total = 0
        paket?.general_services?.map((general) => {
          total = total + general.price
        })
        return {
          ...paket,
          price: total
        }
      })
      setDataPaket((prev) => [...prev, ...(newDataPaket ?? [])])
    }
    setIsLoadingCRUD(false)
  }

  const onSimulate = async (servis) => {
    try {
      let packets = []
      let generals = []
      let curPacket = null
      let idx = 0
      Object.keys(servis?.service_packets?.data).map((packet) => {
        if (curPacket !== servis?.service_packets?.data[packet].packet_id) {
          if (packets.length !== 0) {
            idx++
          }
          packets.push({
            service_id: servis?.service_packets?.data[packet].service_id,
            packet_id: servis?.service_packets?.data[packet].packet_id,
            general_services: [
              {
                general_service_id: servis?.service_packets?.data[packet].general_service_id,
                sparepart_id: servis?.service_packets?.data[packet].sparepart_id
              }
            ]
          })
          curPacket = servis?.service_packets?.data[packet].packet_id
        } else {
          packets[idx]?.general_services.push({
            general_service_id: servis?.service_packets?.data[packet].general_service_id,
            sparepart_id: servis?.service_packets?.data[packet].sparepart_id
          })
        }
      })
      Object.keys(servis?.service_generals?.data).map((key) => {
        generals.push({
          service_id: servis?.service_generals?.data[key].service_id,
          general_service_id: servis?.service_generals?.data[key]?.general_service_id,
          sparepart_id: servis?.service_generals?.data[key]?.sparepart_id
        })
      })
      let body = {
        unit_id: data?.id,
        booking_type: 'SERVICE',
        voucher_type: 0,
        service_packets: packets,
        service_generals: generals
      }

      // console.log({
      //   unit_id: data?.id,
      //   booking_type: 'SERVICE',
      //   voucher_type: 0,
      //   service_packets: packets,
      //   service_generals: generals
      // })

      let res = await apiPost({
        url: `${urls?.service?.prefix}/bookings/simulation-create`,
        token,
        data: body,
        onLogout: () => dispatch(logout())
      })
      setSimulateData(res?.resData?.data)
    } catch (error) {
      alert(error)
    }
  }

  return (
    <Content className="h-full">
      <ModalOpsiSparepart
        isOpen={modal.show}
        onSubmit={(servis) => {
          let servicePackets = {
            ...service.service_packets?.data,
            ...servis.service_packets?.data
          }
          let newServis = {
            ...service,
            service_packets: {
              data: {
                ...service.service_packets?.data,
                ...servis.service_packets?.data
              },
              total: service.service_packets?.total
            },
            service_generals: {
              data: {
                ...service.service_generals?.data,
                ...servis.service_generals?.data
              },
              total: service.service_generals?.total
            }
          }
          setService(newServis)
          let newServicePackets = {}
          Object.keys(servicePackets)
            .sort(function (a, b) {
              return servicePackets[a]?.packet_id - servicePackets[b]?.packet_id
            })
            .map((key, idx) => {
              newServicePackets[idx] = {
                ...servicePackets[key]
              }
            })
          newServis = {
            ...newServis,
            service_packets: {
              data: newServicePackets,
              total: newServis.service_packets?.total
            }
          }

          serServiceForBE(newServis)

          setModal({ ...modal, show: false })
          onSimulate(newServis)
        }}
        onCancel={() => setModal({ ...modal, show: false })}
        data={modal.data}
        type={modal.type}
        serviceId={selectedServis}
        selected={modal.selected}
      />
      {/* Steps */}
      <Card className="rounded-lg mb-4">
        <div className="flex">
          <div className="flex-1 flex items-center">
            <div className="w-8 h-8 rounded-full bg-[#D1FADF] flex items-center justify-center mr-2">
              <Typography.Text className="text-[#12B76A] font-medium">1</Typography.Text>
            </div>
            <Typography.Text className="text-[#12B76A] font-medium w-fit">
              Data Unit
            </Typography.Text>
            <div className="flex-1 h-1 bg-[#6CE9A6] rounded-full mx-2" />
          </div>
          <div className="flex-1 flex items-center">
            <div className="w-8 h-8 rounded-full bg-[#FDE2D3] flex items-center justify-center mr-2">
              <Typography.Text className="text-primary-500 font-medium">2</Typography.Text>
            </div>
            <Typography.Text className="text-primary-500 font-medium w-fit">
              Data Servis
            </Typography.Text>
            <div className="flex-1 h-1 bg-gray-300 rounded-full mx-2" />
          </div>
          <div className="flex-1 flex items-center">
            <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center mr-2">
              <Typography.Text className="text-gray-500 font-medium">3</Typography.Text>
            </div>
            <Typography.Text className="text-gray-500 font-medium w-fit">Jadwal</Typography.Text>
            <div className="flex-1 h-1 bg-gray-300 rounded-full mx-2" />
          </div>
          <div className="flex items-center">
            <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center mr-2">
              <Typography.Text className="text-gray-500 font-medium">4</Typography.Text>
            </div>
            <Typography.Text className="text-gray-500 font-medium w-fit">
              Konfirmasi
            </Typography.Text>
            <div className="flex-1 h-1 bg-gray-300 rounded-full mx-2" />
          </div>
        </div>
      </Card>
      <div className="flex mb-4">
        {/* Card Add Service */}
        <Card className="flex-1 mr-1 rounded-lg h-fit relative">
          {isLoading && (
            <Loading visible title="" classNameWidth="w-full h-full" display="absolute" />
          )}
          {/* Title */}
          <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300 ">
            <Typography.Text className="font-semibold text-xl">Layanan Servis</Typography.Text>
          </div>
          {/* Select Layanan */}
          <div className="mb-4">
            <Typography.Text className="font-medium">Layanan</Typography.Text>
            <Select
              // showSearch
              className="custom-table-limit w-full"
              value={selectedServis}
              placeholder="Pilih Layanan"
              // filterOption={false}
              // onSearch={(text) => setSearchLayanan(text)}
              onChange={(e) => {
                if (servis.selectedServis !== e) {
                  setCurServis({
                    service_packets: {
                      data: {},
                      total: 0
                    },
                    service_generals: {
                      data: {},
                      total: 0
                    },
                    total: 0
                  })
                  setIsBtnLoadMoreDisabled(false)
                  // setSimulateData()
                }
                // let idx = dataServis2?.findIndex((item) => item.id === e)
                // let dataIdx = dataServis2[idx]
                // setDataGeneralServis(dataIdx?.general_services)
                // let newDataPaket = dataIdx?.packets?.map((paket) => {
                //   let total = 0
                //   paket?.general_services?.map((general) => {
                //     total = total + general.price
                //   })
                //   return {
                //     ...paket,
                //     price: total
                //   }
                // })
                // setDataPaket(newDataPaket)

                getDetailLayanan({ id: e, search: '' })
                setSearchLayanan('')
                setSelectedServis(e)
                setDataPaket([])
                setDataGeneralServis([])
              }}>
              {dataServis2?.map((item) => (
                <Select.Option
                  key={item?.id}
                  className="custom-table-limit-option"
                  value={item?.id}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="pt-4 bg-gray-50 rounded-lg relative">
            {isLoadingCRUD && <Loading visible display="absolute" title="" />}
            {/* Search Layanan */}
            <div className="px-4 py-2">
              <Input
                className="w-full rounded-md"
                placeholder="Cari Layanan"
                prefix={<IcSearch className="text-gray-400" />}
                value={searchLayanan}
                onChange={(e) => {
                  setSearchLayanan(e.target.value)
                  if (selectedServis) {
                    getDetailLayanan({ search: e.target.value })
                  }
                }}
              />
            </div>
            {/* List Layanan */}
            <div className="overflow-y-scroll h-[490px] min-h-full px-4 py-4">
              {dataPaket?.map((item, idx) => {
                let check = true
                item?.general_services?.map((general) => {
                  check =
                    check &&
                    !Object.keys(service?.service_packets?.data)?.includes(general?.id?.toString())
                  check =
                    check &&
                    !Object.keys(service?.service_generals?.data)?.includes(general?.id?.toString())
                })
                if (check)
                  return (
                    <div
                      key={`packet-${idx.toString()}`}
                      className="bg-white p-2 w-full rounded-lg border border-solid border-gray-300 mb-3">
                      <Typography className="flex justify-between items-center mb-2">
                        <Typography.Text className="text-gray-900 font-medium text-lg">
                          {item?.name}
                        </Typography.Text>
                        <Typography.Text className="text-[#F79009] font-semibold">
                          {/* Mulai dari {formatCurrency(item?.price?.toString())} */}
                        </Typography.Text>
                      </Typography>
                      <Typography.Paragraph className="text-gray-500 text-sm mb-1">
                        {item?.description}
                      </Typography.Paragraph>
                      <div className="flex justify-end items-center mb-1">
                        {/* <Button
                          className="mr-3 p-0 px-4 py-1.5 border-gray-300 bg-white border font-medium text-gray-700 hover:bg-gray-50"
                          onClick={() => router.go(-1)}>
                          Detail
                        </Button> */}
                        <Button
                          className="p-0 px-2.5 py-1.5 bg-primary-500 border font-medium text-white hover:opacity-90 flex items-center border-primary-500"
                          onClick={() =>
                            setModal({
                              show: true,
                              type: 'packet',
                              data: item,
                              selected: curServis
                            })
                          }>
                          <IcPlus className="text-white mr-1" size={18} />
                          Keranjang
                        </Button>
                      </div>
                    </div>
                  )
              })}
              {dataGeneralServis?.map((item, idx) => {
                let check = true
                check =
                  check &&
                  !Object.keys(service?.service_packets?.data)?.includes(item?.id?.toString())
                check =
                  check &&
                  !Object.keys(service?.service_generals?.data)?.includes(item?.id?.toString())
                if (check)
                  return (
                    <div
                      key={`general-${idx.toString()}`}
                      className="bg-white p-2 w-full rounded-lg border border-solid border-gray-300 mb-3">
                      <Typography className="flex justify-between items-center mb-2">
                        <Typography.Text className="text-gray-900 font-medium text-lg">
                          {item?.name}
                        </Typography.Text>
                        <Typography.Text className="text-[#F79009] font-semibold">
                          {/* Mulai dari {formatCurrency(item?.price?.toString())} */}
                        </Typography.Text>
                      </Typography>
                      <Typography.Paragraph className="text-gray-500 text-sm mb-1">
                        {item?.description}
                      </Typography.Paragraph>
                      <div className="flex justify-end items-center mb-1">
                        <Button
                          className="p-0 px-2.5 py-1.5 bg-primary-500 border font-medium text-white hover:opacity-90 flex items-center border-primary-500"
                          onClick={() =>
                            setModal({
                              show: true,
                              type: 'general',
                              data: item,
                              selected: curServis
                            })
                          }>
                          <IcPlus className="text-white mr-1" size={18} />
                          Keranjang
                        </Button>
                      </div>
                    </div>
                  )
              })}
            </div>
            {(dataPaket?.length > 0 || dataGeneralServis?.length > 0) && !isBtnLoadMoreDisabled && (
              <div className="px-4 py-2">
                <Button
                  className="p-0 px-4 py-2 w-full bg-primary-500 border border-primary-500 font-medium text-white hover:opacity-90"
                  onClick={() => getDetailLayanan({ refresh: false })}>
                  Tampilkan Lebih Banyak
                </Button>
              </div>
            )}
          </div>
        </Card>

        {/* Card Service List  */}
        <Card className="flex-1 ml-1 rounded-lg h-fit">
          <div className="flex justify-between pb-2 mb-4 border-0 border-b border-solid border-b-gray-300">
            <Typography.Text className="font-semibold text-xl">Daftar Servis</Typography.Text>
          </div>
          {simulateData?.packets?.map((packet) => (
            <Typography
              className="mb-2"
              key={`simulate-service_packets-${packet?.packet_id?.toString()}`}>
              <div className="mb-[1px] py-1 border-gray-200 bg-gray-50 border-solid rounded-md">
                <div className="flex justify-between items-center px-2 mb-1">
                  <div className="flex-1 flex items-center">
                    <IcChevronRight className={'text-black'} />
                    <Typography.Text className="font-medium text-md">
                      {packet?.service_name}
                    </Typography.Text>
                  </div>
                  <Button
                    className="group w-4 h-4 p-[0px] border-gray-500 border-1.5 border-solid flex items-center rounded-full bg-white justify-center hover:border-primary-500"
                    onClick={() => {
                      let servis = { ...service }
                      Object.keys(servis?.service_packets?.data).map((packet2) => {
                        if (
                          packet?.packet_id === servis?.service_packets?.data[packet2].packet_id
                        ) {
                          delete servis?.service_packets?.data[packet2]
                        }
                      })
                      setService(servis)
                      onSimulate(servis)
                    }}>
                    <IcClose size={12} className="text-gray-500 group-hover:text-primary-500" />
                  </Button>
                </div>
                <div className="mx-6">
                  {packet?.spareparts?.map((sparepart, idx) => (
                    <div
                      className="grid grid-cols-12 mb-1"
                      key={`simulate-packet-sparepart-${idx?.toString()}`}>
                      <div className="col-span-8">
                        <Typography.Text className="font-medium block">
                          {sparepart?.general_service_name}
                        </Typography.Text>
                        <Typography.Text className="block text-gray-500">
                          {sparepart?.sparepart_name}
                        </Typography.Text>
                      </div>
                      <Typography.Text className="col-span-1 text-end flex items-end text-gray-500">
                        {sparepart?.quantity === 0 ? '1' : sparepart?.quantity}x
                      </Typography.Text>
                      <div className="col-span-3 text-end flex justify-end items-end">
                        <Typography.Text className="text-end mr-1 text-gray-500">
                          {formatCurrency(sparepart?.sparepart_price_total?.toString() ?? '0')}
                        </Typography.Text>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Typography>
          ))}
          {simulateData?.general_services?.map((general, idx) => (
            <Typography className="mb-2" key={`simulate-general-${idx?.toString()}`}>
              <div className="mb-[1px] py-1 border-gray-200 bg-gray-50 border-solid rounded-md">
                <div className="grid grid-cols-12 mb-1 px-2">
                  <div className="col-span-8">
                    <Typography.Text className="font-medium block">
                      {general?.general_service_name}
                    </Typography.Text>
                    <Typography.Text className="block text-gray-500">
                      {general?.sparepart_name}
                    </Typography.Text>
                  </div>
                  <Typography.Text className="col-span-1 text-end flex items-center text-gray-500">
                    {general?.quantity}x
                  </Typography.Text>
                  <div className="col-span-3 text-end flex justify-end items-center">
                    <Typography.Text className="text-end mr-1 text-gray-500">
                      {formatCurrency(general?.sparepart_price_total?.toString() ?? '0')}
                    </Typography.Text>
                    <Button
                      className="group w-4 h-4 p-[0px] border-gray-500 border-1.5 border-solid flex items-center rounded-full self-center bg-white justify-center hover:border-primary-500"
                      onClick={() => {
                        let servis = { ...service }
                        delete servis?.service_generals?.data[general?.general_service_id]
                        onSimulate(servis)
                        setService(servis)
                      }}>
                      <IcClose size={12} className="text-gray-500 group-hover:text-primary-500" />
                    </Button>
                  </div>
                </div>
              </div>
            </Typography>
          ))}
          {/* {simulateData && <div className="w-full bg-gray-200 h-[2px] mt-4" />}
          {simulateData && (
            <div className="flex justify-between mt-4">
              <Typography.Text className="text-gray-500">Item Total</Typography.Text>
              <Typography.Text className="text-gray-500">
                {formatCurrency(simulateData?.item_total?.toString() ?? '0')}
              </Typography.Text>
            </div>
          )}
          {simulateData && (
            <div className="flex justify-between mt-1">
              <Typography.Text className="text-gray-500">
                Harga servis per jam (Kelas Middle)
              </Typography.Text>
              <Typography.Text className="text-gray-500">
                {formatCurrency(simulateData?.admin_fee?.toString() ?? '0')}
              </Typography.Text>
            </div>
          )}
          {simulateData && (
            <div className="flex justify-between mt-1">
              <Typography.Text className="text-gray-500">Tax</Typography.Text>
              <Typography.Text className="text-gray-500">
                {formatCurrency(simulateData?.tax?.toString() ?? '0')}
              </Typography.Text>
            </div>
          )}
          {simulateData && (
            <div className="flex justify-between mt-1">
              <Typography.Text className="text-gray-500">Potongan Perusahaan</Typography.Text>
              <Typography.Text className="text-gray-500">
                {formatCurrency(simulateData?.company_discount_total?.toString() ?? '0')}
              </Typography.Text>
            </div>
          )} */}
          {/* {simulateData && <div className="w-full bg-gray-200 h-[2px] mt-4" />}
          {simulateData && (
            <div className="flex justify-between mt-1">
              <Typography.Text className="text-gray-500 font-medium">Total Bayar</Typography.Text>
              <Typography.Text className="text-gray-500 font-medium">
                {formatCurrency(simulateData?.price_total?.toString() ?? '0')}
              </Typography.Text>
            </div>
          )} */}
        </Card>
      </div>
      {/* Button Back & Next */}
      <div className="flex justify-end items-center mb-2">
        <Button
          className="mr-3 p-0 px-4 py-2 border-gray-300 bg-white border font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => router.go(-1)}>
          Kembali
        </Button>
        <Button
          className="p-0 px-4 py-2 bg-primary-500 border font-medium text-white hover:opacity-90"
          onClick={async () => {
            if (simulateData !== null && simulateData !== undefined) {
              await dispatch(setServisStep2(service, selectedServis, serviceForBE, simulateData))
              router.push(`/internal-unit/${data?.id}/servis-mobil`, {
                step: 3,
                data,
                simulateData: simulateData
              })
            } else {
              showWarningMessage({ content: 'Mohon Pilih Layanan Servis', duration: 2000 })
            }
          }}>
          Selanjutnya
        </Button>
      </div>
    </Content>
  )
}

export default ServisMobilComponentStep2
