import React, { useRef } from 'react'
import { PhotoPicker } from 'components'
import { imageCompressor, useHorizontalScroll } from 'utils'

const PhotoMultipleCard = ({
  data,
  onAddPhoto,
  onDeletePhoto,
  isRequired = false,
  isDeleteById,
  onSwapOrder
}) => {
  const photoInputRef = useRef(null)
  const scrollRef = useHorizontalScroll()

  const handleOnClick = (e) => {
    photoInputRef.current.click()
  }

  const handleOnChangePhoto = async (e) => {
    let approvalPictArr = []
    if (e.target.files) {
      for (let index = 0; index < e.target.files.length; index++) {
        const item = e.target.files[index]
        const checkType = item.type.substring(item.type.lastIndexOf('/') + 1)
        if (checkType === 'jpg' || checkType === 'jpeg' || checkType === 'png') {
          const compressImage = await imageCompressor(item)
          if (compressImage.status === 200) {
            approvalPictArr.push({
              file: compressImage.res,
              blob: URL.createObjectURL(compressImage.res)
            })
          }
        }
      }
      return onAddPhoto(approvalPictArr)
    }
  }

  function handleSwapOrder() {}

  return (
    <div className="flex flex-row items-center w-full justify-start space-x-4">
      <PhotoPicker
        isTitle
        isRequired={isRequired}
        isNotes
        isMultiple
        ref={photoInputRef}
        name="photo-picker"
        title="Detail Unit"
        notes="Format file jpg, jpeg, png."
        handleonClick={(e) => handleOnClick(e)}
        handleOnChange={(e) => handleOnChangePhoto(e)}
      />
      <div
        ref={scrollRef}
        className={`flex flex-nowrap overflow-x-scroll w-full space-x-4 ${
          data && data.length > 5 ? 'mt-4' : 'mt-0'
        }`}
        style={{ overflowX: 'auto' }}>
        {data
          ? data.map((item, index) => (
              <PhotoPicker
                key={index}
                isDelete
                isMultiple
                index={index}
                onSwapOrder={onSwapOrder.bind(this, item)}
                photoLength={data.length}
                name={item.name}
                photo={item.blob}
                onDeletePhoto={() => {
                  if (isDeleteById) {
                    onDeletePhoto(index, item.id, item)
                  } else {
                    onDeletePhoto(index)
                  }
                }}
              />
            ))
          : null}
      </div>
    </div>
  )
}

export default PhotoMultipleCard
