import { Modal } from 'antd'
import { IcEdit } from 'assets/icons'
import UploadImage from 'components/UploadImage'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'components'
import 'moment/locale/id'
import moment from 'moment'
const ModalManualPaymentService = ({
  isOpen,
  onClose,
  isLoading,
  onSubmitManualPayment,
  type,
  data,
  setModal
}) => {
  const [manualPaymentImage, setManualPaymentImage] = useState(null)

  let manualPaymentImageRef = useRef()

  useEffect(() => {
    if (isOpen) {
      setManualPaymentImage(data?.image_filename ?? null)
    }
  }, [isOpen])
  const changeManualPaymentImage = (convertManualPaymentImage, rawManualPaymentImage) => {
    setManualPaymentImage(convertManualPaymentImage)
    setModal((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        image_filename: rawManualPaymentImage
      }
    }))
  }

  const onOk = async () => {
    onSubmitManualPayment({
      id: data?.id ?? null,
      image_filename: data?.image_filename
    })
  }

  if (type === 'show') {
    return (
      <Modal
        width={500}
        centered
        title={<span className="text-xl-semibold text-gray-900">Detail Pembayaran</span>}
        open={isOpen}
        onCancel={onClose}
        footer={null}>
        <div>
          {/* Image */}
          <img
            src={`${process.env.REACT_APP_IMAGE_URL_GCS}/${data?.proof_of_payment}`}
            alt="manual payment image"
            className="w-full h-full object-contain rounded max-h-[400px]"
          />
          {/* Detail */}
          <div className="flex flex-col gap-2 mt-5">
            <div className="flex flex-col gap-2">
              <span className="text-lg-medium text-gray-900 ">Confirm By</span>
              <span className="text-md-regular text-gray-700">{data?.user?.fullname}</span>
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-lg-medium text-gray-900 ">Waktu upload</span>
              <span className="text-md-regular text-gray-700">
                {moment(data?.created_at).format('DD MMMM YYYY  HH:mm')}
              </span>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <Modal
      width={450}
      centered
      title={
        <div className="flex flex-col gap-2 ">
          <span className="text-xl-semibold text-gray-900">Konfirmasi Pembayaran</span>
          <span className="text-md-medium text-gray-700">Upload bukti pembayaran</span>
        </div>
      }
      open={isOpen}
      onCancel={onClose}
      maskClosable={false}
      onOk={() => onOk(data)}
      confirmLoading={isLoading}
      okText={'Confirm'}
      cancelText={'Cancel'}
      okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
      cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}>
      <div className="flex flex-col  mb-2 w-full gap-2">
        <div className="h-[206px] w-full relative rounded-md bg-gray-100">
          <UploadImage
            customRef={manualPaymentImageRef}
            onChangeFile={changeManualPaymentImage}
            file={manualPaymentImage}
            oldFile={null}
            sizeClassName="h-full w-full"
            isRawFile={true}
          />
          {manualPaymentImage !== null && (
            <div className="absolute top-2 right-2 ">
              <Button
                className="bg-white border-gray-300 hover:bg-gray-50"
                onClick={
                  manualPaymentImageRef.current
                    ? () => manualPaymentImageRef.current.click()
                    : () => {}
                }>
                <IcEdit className="text-gray-700" size={16} />
              </Button>
            </div>
          )}
        </div>
        <div className="text-gray-500 text-sm text-left">
          Ukuran file maksimal 2mb, dengan format file jpg, jpeg, png.
        </div>
      </div>
    </Modal>
  )
}

export default ModalManualPaymentService
