import { Breadcrumb, Layout } from 'antd'
import {
  CatatanCard,
  InformasiOdooCard,
  InformasiServisCard,
  InformasiUnitCard,
  InformasiUserCard,
  InspeksiCard,
  Loading,
  PaymentReceiptCard
} from 'components'
import InformasiSpekUnitHorizontalCard from 'components/card/informasi-spek-unit-horizontal-card'
import React, { useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { useQuery } from 'react-query'
import { apiGet, urls } from 'api'
import moment from 'moment'
import { formatCurrency, showErrorMessage, statusLayanan } from 'utils'

const DetailTransaksiAnalyticPage = () => {
  const { transactionId, unitId, type = '' } = useParams()

  const { push, goBack } = useHistory()

  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }
  const [detailTransaksi, setDetailTransaksi] = useState()
  const [detailUnit, setDetailUnit] = useState()
  const [catalogUnitId, setCatalogUnitId] = useState()

  const onReturnURL = () => {
    switch (type?.toLowerCase()) {
      // type beli
      case 'beli':
        return `${urls.analytic.prefixJualBeli}/${urls.analytic.transactionBranch.transactionDetailBeli}/${transactionId}`

      // type servis
      case 'servis':
        return `${urls.service.prefix}/${urls.service.booking}/${transactionId}?is_show_relation=true&is_show_progress=true&is_show_recommendation_service=true&is_show_history=true`

      // type inspeksi
      case 'inspeksi':
        return `${urls.analytic.prefixInspeksi}/${urls.analytic.transactionBranch.transactionDetailInspeksi}/${transactionId}`

      default:
        return ``
    }
  }

  const onDetailUnitReturnURL = () => {
    switch (type?.toLowerCase()) {
      // type beli
      case 'beli':
        return `${urls.analytic.prefixMaster}/${urls.analytic.unit.detailUnit}/${catalogUnitId}`
      default:
        return `${urls.analytic.prefixMaster}/${urls.analytic.unit.detailUnit}/${unitId}`
    }
  }

  // Get Api
  // Get Detail Transaksi
  const { isLoading: isLoadingTransaksi, isFetching: isFetchingTransaksi } = useQuery({
    queryKey: ['detail-transaksi', transactionId],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: onReturnURL(),
        onLogout
      })
      if (res?.resStatus !== 200) {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      return res
    },
    onSuccess: (res) => {
      setDetailTransaksi(res?.resData?.data)
      setCatalogUnitId(res?.resData?.data?.catalog?.unit_id)
    }
  })

  // Get Detail unit
  const { isLoading: isLoadingUnit, isFetching: isFetchingUnit } = useQuery({
    queryKey: ['detail-unit', unitId],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: onDetailUnitReturnURL(),
        onLogout
      })
      if (res?.resStatus !== 200) {
        showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
      }
      return res
    },
    onSuccess: (res) => {
      setDetailUnit(res?.resData?.data)
    },
    enabled: !!detailTransaksi
  })

  const sumVoucherDiscount = (voucher) => {
    let sum = 0
    voucher?.map((voc) => {
      sum += voc?.price_total
    })

    return sum
  }

  const listService = useMemo(() => {
    if (type?.toLowerCase() === 'servis') {
      let sparepart = []
      let totalPriceSparepart = 0
      let service = []
      let totalPriceService = 0
      detailTransaksi?.general_services
        ?.filter(
          (f) =>
            f?.status !== 'WAITING_APPROVAL' && f?.status !== 'REJECTED' && f?.status !== 'CANCELED'
        )
        ?.forEach((item) => {
          if (item?.is_free === false) {
            sparepart.push({
              sparepart_name: item?.sparepart_name,
              price: item?.sparepart_price * item?.quantity,
              quantity: item?.quantity,
              discount: sumVoucherDiscount(item?.voucher_sparepart_detail),
              total: item?.sparepart_price_total
            })
            totalPriceSparepart += item?.sparepart_price_total
          }
          service.push({
            general_service_name: item?.general_service_name,
            price: item?.service_price + item?.class_price,
            quantity: '',
            discount: sumVoucherDiscount(item?.voucher_service_detail),
            total: item?.service_price_total
          })
          totalPriceService += item?.service_price_total
        }) ?? []

      detailTransaksi?.packets?.forEach((item) => {
        item?.spareparts
          ?.filter(
            (f) =>
              f?.status !== 'WAITING_APPROVAL' &&
              f?.status !== 'REJECTED' &&
              f?.status !== 'CANCELED'
          )
          ?.forEach((spare) => {
            if (spare?.is_free === false) {
              sparepart.push({
                sparepart_name: spare?.sparepart_name,
                price: spare?.sparepart_price * spare?.quantity,
                quantity: spare?.quantity,
                discount: sumVoucherDiscount(spare?.voucher_sparepart_detail),
                total: spare?.sparepart_price_total
              })
              totalPriceSparepart += spare?.sparepart_price_total
            }
            service.push({
              general_service_name: spare?.general_service_name,
              price: spare?.service_price + spare?.class_price,
              quantity: '',
              discount: sumVoucherDiscount(spare?.voucher_service_detail),
              total: spare?.service_price_total
            })
            totalPriceService += spare?.service_price_total
          }) ?? []
      }) ?? []

      return {
        totalPriceSparepart: totalPriceSparepart,
        sparepart: sparepart,
        totalPriceService: totalPriceService,
        service: service
      }
    } else if (type?.toLowerCase() === 'beli') {
      return [
        {
          serviceName: `Biaya Pembelian Unit`,
          products: [
            {
              itemName: detailTransaksi?.catalog_invoice?.unit_name ?? '-',
              totalItems: '',
              totalPrice: formatCurrency(detailTransaksi?.catalog_invoice?.subtotal_price ?? 0)
            }
          ]
        }
      ]
    } else if (type?.toLowerCase() === 'inspeksi') {
      return [
        {
          serviceName: `Biaya Inspeksi Unit`,
          products: [
            {
              itemName: 'Biaya Inspeksi',
              totalItems: '',
              totalPrice: formatCurrency(detailTransaksi?.inspection_fee ?? 0)
            },
            detailTransaksi?.distance_price && {
              itemName: 'Biaya Jarak',
              totalItems: '',
              totalPrice: formatCurrency(detailTransaksi?.distance_price ?? 0)
            }
          ]
        }
      ]
    }
    return []
  }, [detailTransaksi])

  if (isLoadingTransaksi ?? isFetchingTransaksi ?? isLoadingUnit ?? isFetchingUnit) {
    return <Loading visible />
  }

  return (
    <Layout className="grid grid-cols-1 gap-6 p-8">
      {/* BREADCRUMB */}
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item
          className="font-inter font-semibold text-gray-700 cursor-pointer"
          onClick={() => goBack()}>
          Analytics Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Detail Transaksi
        </Breadcrumb.Item>
      </Breadcrumb>
      {type?.toLowerCase() === 'beli' ? (
        <InformasiUnitCard
          unit={detailTransaksi?.catalog?.unit_name ?? ''}
          status={detailTransaksi?.catalog?.status ?? ''}
          harga={detailTransaksi?.catalog_invoice?.subtotal_price ?? 0}
          tanggal={
            detailTransaksi?.transaction?.done_at
              ? moment(detailTransaksi?.transaction?.done_at).format('DD/MM/YYYY')
              : '-'
          }
          tanggalPelunasan={
            detailTransaksi?.transaction?.paid_at
              ? moment(detailTransaksi?.transaction?.paid_at).format('DD/MM/YYYY')
              : '-'
          }
          isSelling
        />
      ) : null}

      {type?.toLowerCase() === 'servis' ? (
        <InformasiServisCard
          tipe={'Servis'}
          data={{
            tempat: detailTransaksi?.workshop_name,
            tanggal: detailTransaksi?.completion_time
              ? moment(detailTransaksi?.completion_time).format('DD/MM/YYYY')
              : '-',
            tanggalTransaksi: detailTransaksi?.booking_invoice?.transaction_detail?.paid_at
              ? moment(detailTransaksi?.booking_invoice?.transaction_detail?.paid_at).format(
                  'DD/MM/YYYY'
                )
              : '-',
            waktu: `${moment(detailTransaksi?.service_start_time, 'HH:mm').format(
              'HH:mm'
            )} - ${moment(detailTransaksi?.service_end_time, 'HH:mm').format('HH:mm')}`,
            stall: detailTransaksi?.stall_no ? detailTransaksi?.stall_no : '-',
            mekanik: detailTransaksi?.mechanic_name,
            statusInspeksi: detailTransaksi?.status
          }}
        />
      ) : null}
      {type?.toLowerCase() === 'inspeksi' ? (
        <InformasiServisCard
          tipe={'Inspeksi'}
          data={{
            tempat: detailTransaksi?.place ?? '',
            tanggal: detailTransaksi?.done_at
              ? moment(detailTransaksi?.done_at).format('DD/MM/YYYY')
              : '-',
            tanggalTransaksi: detailTransaksi?.paid_at
              ? moment(detailTransaksi?.paid_at).format('DD/MM/YYYY')
              : '-',
            waktu: `${moment(detailTransaksi?.start_time, 'HH:mm').format('HH:mm')} - ${moment(
              detailTransaksi?.end_time,
              'HH:mm'
            ).format('HH:mm')}`,
            stall: detailTransaksi?.stall ?? '-',
            inspektor: detailTransaksi?.inspector_name ?? '',
            statusInspeksi: detailTransaksi?.inspection_status?.toLowerCase() ?? '',
            grade: detailTransaksi?.inspectionResultList?.grade
          }}
        />
      ) : null}

      <InformasiUserCard
        title={'Informasi Pemilik'}
        data={
          detailTransaksi?.company_id > 0 && detailTransaksi?.user_id > 0
            ? {
                nama: detailTransaksi?.full_name ?? '-',
                email: detailTransaksi?.email ?? '-',
                telepon: detailTransaksi?.phone ?? '-',
                alamat: detailTransaksi?.address ?? '-'
              }
            : {
                nama: detailUnit?.unitOwner?.name ?? '-',
                email: detailUnit?.unitOwner?.email ?? '-',
                telepon: detailUnit?.unitOwner?.phone_number ?? '-',
                alamat: detailUnit?.unitOwner?.address ?? '-'
              }
        }
      />

      {type?.toLowerCase() === 'beli' ? (
        <InformasiUserCard
          title={'Informasi Pembeli'}
          data={{
            nama: detailTransaksi?.booking?.buyer_fullname ?? '-',
            email: detailTransaksi?.booking?.buyer_email ?? '-',
            telepon: detailTransaksi?.booking?.buyer_phone_number ?? '-',
            alamat: detailTransaksi?.booking?.buyer_address ?? '-'
          }}
        />
      ) : null}

      {(statusLayanan(detailTransaksi?.inspection_status || detailTransaksi?.status) ===
        'Selesai' ||
        statusLayanan(detailTransaksi?.catalog?.status) === 'Terjual') &&
      (detailTransaksi?.odoo_repair_order?.invoice_name ||
        detailTransaksi?.odoo_repair_order?.repair_order_name ||
        detailTransaksi?.odoo_sale_order?.sale_order_name) ? (
        <InformasiOdooCard
          invoiceNumber={
            detailTransaksi?.odoo_repair_order?.invoice_name ||
            detailTransaksi?.odoo_sale_order?.invoice_name
          }
          roNumber={detailTransaksi?.odoo_repair_order?.repair_order_name}
          soNumber={detailTransaksi?.odoo_sale_order?.sale_order_name}
        />
      ) : null}

      {type?.toLowerCase() !== 'beli' ? (
        <>
          <InformasiSpekUnitHorizontalCard
            data={{
              merek: detailUnit?.brand?.name ?? '-',
              model: detailUnit?.model?.name ?? '-',
              tipe: detailUnit?.type?.name ?? '-',
              transmisi: detailUnit?.transmission?.name ?? '-',
              tahun: detailUnit?.production_year ?? '-',
              owner: detailUnit?.unitOwner?.name ?? '-',
              riwayatService: detailUnit?.latest_service_date
                ? moment(detailUnit?.latest_service_date).format('DD/MM/YYYY')
                : '-',
              warna: detailUnit?.color?.name ?? '-',
              platNomor: detailUnit?.police_number ?? '-',
              bahanBakar: detailUnit?.fuel?.name ?? '-',
              noRangka: detailUnit?.frame_number ?? '-',
              noMesin: detailUnit?.engine_number ?? '-',
              tag:
                detailUnit?.type?.categories?.map(
                  (item, idx) =>
                    `${item?.name}${idx + 1 === detailUnit?.type?.categories?.length ? '' : ', '}`
                ) ?? '-'
            }}
          />

          <CatatanCard desc={detailTransaksi?.notes || detailUnit?.description} />
        </>
      ) : null}

      {type?.toLowerCase() === 'inspeksi' ? (
        <InspeksiCard
          onGoToDetailInspeksi={() =>
            push(
              `/analytics-dashboard/penjualan/detail-inspeksi/${detailTransaksi?.inspectionResultList?.id}/detail`
            )
          }
          data={{
            ...detailTransaksi?.inspectionResultList,
            inspectionCategoryList:
              detailTransaksi?.inspectionResultList?.inspectionCategoryList?.map((item) => ({
                ...item,
                count: `${item?.ok_total}/${item?.part_total}`
              })) ?? []
          }}
        />
      ) : null}

      {/* Servis */}
      {type?.toLowerCase() === 'servis' ? (
        <PaymentReceiptCard
          data={{
            sparepart_price: detailTransaksi?.sparepart_price,
            sparepart_general_voucher_discount_total:
              detailTransaksi?.sparepart_general_voucher_discount_total,
            sparepart_total: detailTransaksi?.sparepart_total,
            service_price: detailTransaksi?.service_price,
            service_general_voucher_discount_total:
              detailTransaksi?.service_general_voucher_discount_total,
            service_price_total: detailTransaksi?.service_price_total,
            adminFee: detailTransaksi?.admin_fee ?? 0,
            total:
              detailTransaksi?.item_total +
              (detailTransaksi?.admin_fee ?? 0) +
              (detailTransaksi?.tax ?? 0) +
              (detailTransaksi?.distance_price ?? 0),
            tax: detailTransaksi?.tax ?? 0,
            discountVoucher: detailTransaksi?.voucher_discount_total ?? 0,
            discountCompany: detailTransaksi?.company_discount_total ?? 0,
            totalPrice: detailTransaksi?.price_total ?? 0,
            listServices: listService,
            subTotal: detailTransaksi?.item_total ?? 0,
            distanceFee: detailTransaksi?.distance_price
          }}
          isTypeServis={true}
        />
      ) : null}

      {/* beli */}
      {type?.toLowerCase() === 'beli' ? (
        <PaymentReceiptCard
          data={{
            adminFee: detailTransaksi?.catalog_invoice?.admin_price ?? 0,
            tax: detailTransaksi?.catalog_invoice?.tax_fee ?? 0,
            total: detailTransaksi?.catalog_invoice?.total_price,
            totalPrice: detailTransaksi?.catalog_invoice?.total_price ?? 0,
            listServices: listService
          }}
        />
      ) : null}

      {/* inspeksi */}
      {type?.toLowerCase() === 'inspeksi' ? (
        <PaymentReceiptCard
          data={{
            adminFee: detailTransaksi?.admin_fee ?? 0,
            tax: detailTransaksi?.tax_fee ?? 0,
            total: detailTransaksi?.price ?? 0,
            totalPrice: detailTransaksi?.price ?? 0,
            listServices: listService
          }}
        />
      ) : null}
    </Layout>
  )
}

export default DetailTransaksiAnalyticPage
