import React from 'react'
import { formatCurrency } from 'utils'
import { IcFacebook, IcInstagram, IcLinkedIn, IcTwitter } from 'assets/icons'
import BirdMobilLogo from 'assets/images/Birdmobil-logo.png'
import moment from 'moment'
import 'moment/locale/en-gb'

const SalesOrderPrintCard = React.forwardRef(({ data }, ref) => {
  const currentDate = new Date()
  const fontSOInformationFieldStyle = 'font-medium text-gray-500 text-left text-sm'
  const fontSOInformationValueStyle = 'font-medium text-gray-700 text-right text-sm'
  const fontItemTitleStyle = 'font-semibold text-gray-900'
  const fontItemValueStyle = 'font-normal text-gray-700 text-sm'
  const adminFee = 50000

  const onSubTotal = () => {
    let subTotal = 0
    data.payments.items.map((item) => {
      subTotal += item.price * item.qty
    })
    return subTotal
  }

  const onTotal = () => {
    let total = 0
    if (data.payments.discount || data.payments.discount !== 0) {
      total = onSubTotal() + adminFee - data.payments.discount
    } else {
      total = onSubTotal() + adminFee
    }
    return total
  }
  return (
    <div className="w-full h-full bg-white p-[8%]">
      <div
        ref={ref}
        className="flex min-h-screen overflow-x-hidden"
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: '#FFFFFF'
        }}>
        <div className="flex flex-col w-full h-full justify-between">
          <div className="flex flex-col">
            <div
              key="header-content"
              className="flex flex-row items-center justify-between border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
              <div className="flex flex-row items-center justify-center space-x-1 bg-white hover:cursor-pointer">
                <img width={30} height={30} src={BirdMobilLogo} alt="" />
                <span className="font-semibold text-gray-900">Birdmobil</span>
              </div>
              <span className="font-normal text-gray-500">{moment(currentDate).format('LL')}</span>
            </div>
            <div key="sales-order-information-content" className="grid grid-flow-col pt-4">
              <div className="flex flex-col space-y-1">
                <span className={fontSOInformationFieldStyle}>Sales Order No.</span>
                {data.penjual ? <span className={fontSOInformationFieldStyle}>Penjual</span> : null}
                {data.pembeli ? <span className={fontSOInformationFieldStyle}>Pembeli</span> : null}
                {data.account ? <span className={fontSOInformationFieldStyle}>Account</span> : null}
                <span className={fontSOInformationFieldStyle}>Alamat</span>
                <span className={fontSOInformationFieldStyle}>Layanan</span>
                {data.tempat ? <span className={fontSOInformationFieldStyle}>Tempat</span> : null}
                {data.waktu ? <span className={fontSOInformationFieldStyle}>Waktu</span> : null}
                {data.mekanik ? <span className={fontSOInformationFieldStyle}>Mekanik</span> : null}
                {data.inspektor ? (
                  <span className={fontSOInformationFieldStyle}>Inspektor</span>
                ) : null}
              </div>
              <div className="flex flex-col space-y-1">
                <span className={fontSOInformationValueStyle}>{data.salesOrderNumber}</span>
                {data.penjual ? (
                  <span className={fontSOInformationValueStyle}>{data.penjual}</span>
                ) : null}
                {data.pembeli ? (
                  <span className={fontSOInformationValueStyle}>{data.pembeli}</span>
                ) : null}
                {data.account ? (
                  <span className={fontSOInformationValueStyle}>{data.account}</span>
                ) : null}
                <span className={fontSOInformationValueStyle}>{data.alamat}</span>
                <span className={fontSOInformationValueStyle}>{data.layanan}</span>
                {data.tempat ? (
                  <span className={fontSOInformationValueStyle}>{data.tempat}</span>
                ) : null}
                {data.waktu ? (
                  <span className={fontSOInformationValueStyle}>{data.waktu}</span>
                ) : null}
                {data.mekanik ? (
                  <span className={fontSOInformationValueStyle}>{data.mekanik}</span>
                ) : null}
                {data.inspektor ? (
                  <span className={fontSOInformationValueStyle}>{data.inspektor}</span>
                ) : null}
              </div>
            </div>
            <div key="items-content" className="flex flex-col py-8">
              <div className="flex flex-row border border-solid border-b-1 border-x-0 border-t-0 pb-3 border-b-gray-300">
                <div className="min-w-[50%] text-left">
                  <span className={fontItemTitleStyle}>Item</span>
                </div>
                <div className="min-w-[20%] text-center">
                  <span className={fontItemTitleStyle}>Price</span>
                </div>
                <div className="min-w-[10%] text-center">
                  <span className={fontItemTitleStyle}>Qty</span>
                </div>
                <div className="min-w-[20%] text-center">
                  <span className={fontItemTitleStyle}>Total</span>
                </div>
              </div>
              {data.payments.items.map((item, index) => (
                <div key={index} className="flex flex-row pt-3">
                  <div className="min-w-[50%] text-left">
                    <span className={fontItemValueStyle}>{item.name}</span>
                  </div>
                  <div className="min-w-[20%] text-center">
                    <span className={fontItemValueStyle}>{formatCurrency(item.price)}</span>
                  </div>
                  <div className="min-w-[10%] text-center">
                    <span className={fontItemValueStyle}>{item.qty}</span>
                  </div>
                  <div className="min-w-[20%] text-right">
                    <span className={fontItemValueStyle}>
                      {formatCurrency(item.price * item.qty)}
                    </span>
                  </div>
                </div>
              ))}
              <div className="grid grid-flow-col items-center justify-between pt-8">
                <div className="flex flex-col text-left space-y-5">
                  <span className={fontItemTitleStyle}>Subtotal:</span>
                  {data.payments.discount || data.payments.discount !== 0 ? (
                    <span className={fontItemTitleStyle}>Discount:</span>
                  ) : null}
                  <span className={fontItemTitleStyle}>Biaya Admin:</span>
                  <span className="font-semibold text-primary-500">Total:</span>
                </div>
                <div className="flex flex-col text-right space-y-5">
                  <span className={fontItemTitleStyle}>{formatCurrency(onSubTotal())}</span>
                  {data.payments.discount || data.payments.discount !== 0 ? (
                    <span className={fontItemTitleStyle}>{`- ${formatCurrency(
                      data.payments.discount
                    )}`}</span>
                  ) : null}
                  <span className={fontItemTitleStyle}>{formatCurrency(adminFee)}</span>
                  <span className="font-semibold text-sm text-primary-500">
                    {formatCurrency(onTotal())}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div key="footer" className="flex flex-col space-y-5">
            <div className="flex flex-col space-y-1">
              <span className="font-normal text-gray-700 text-md">Catatan:</span>
              <span className="font-normal text-gray-700 text-sm">{data.catatan}</span>
            </div>
            <span className="font-medium text-gray-700">Thank you for your business</span>
            <div className="flex flex-col space-y-1 border border-solid border-b-1 border-x-0 border-t-0 pb-8 border-b-gray-300">
              <span className="font-medium text-gray-700">Birdmobil Surabaya</span>
              <span className="font-normal text-gray-500">
                Jln. Raya Darmo Kali No. 2-6, Keputran, Kec. Tegalsari, Kota Surabaya, Jawa Timur
                60211
              </span>
            </div>
            <div className="flex flex-row items-center justify-around pt-5">
              <IcTwitter className="text-gray-400" size={23} />
              <IcLinkedIn className="text-gray-400" size={23} />
              <IcFacebook className="text-gray-400" size={23} />
              <IcInstagram className="text-gray-400" size={23} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default SalesOrderPrintCard
