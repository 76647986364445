import React, { useCallback } from 'react'
import { Card, Input, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { IcEdit, IcSearch, IcTrash } from 'assets/icons'
import { BadgeItem, Table } from 'components'
import { formatDate } from 'helpers'
import { formatCurrency } from 'utils'
import _ from 'lodash'

const VoucherDetailComponent = ({
  columns,
  voucher,
  data,
  isLoading,
  tableState,
  setTableState,
  onEdit,
  onDelete
}) => {
  const handleSearch = useCallback((value) => {
    setTableState((prevState) => ({
      ...prevState,
      keywords: value,
      offset: 0
    }))
  }, [])

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort_by: column.sortField,
      sort_order: sortDirection
    }))
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  const onReturnDiscountFor = () => {
    switch (voucher?.discount_for) {
      case 1:
        return 'Harga Jasa'
      case 2:
        return 'Harga Sparepart'
      case 3:
        return 'Harga Jasa dan Sparepart'
      case 4:
        return 'Tertentu'
      default:
        return '-'
    }
  }

  return (
    <Content className="h-screen">
      <Card className="rounded-lg">
        <div className="border-0 border-solid border-b border-b-gray-300 flex md:flex-row flex-col justify-between pb-4">
          <Typography.Text className="font-semibold text-xl">Informasi Voucher</Typography.Text>
          <div className="flex items-center">
            <div
              className="flex justify-center items-center border border-solid border-gray-300 px-3 py-1 mr-2 rounded-md hover:bg-gray-50 hover:cursor-pointer"
              onClick={onEdit}>
              <IcEdit className="text-gray-700 mr-1" size={16} />
              <span className="mt-[1px] text-gray-700">Edit</span>
            </div>
            <div
              className="flex justify-center items-center border border-solid border-danger-300 px-3 py-1 rounded-md hover:bg-red-50 hover:cursor-pointer"
              onClick={onDelete}>
              <IcTrash className="text-primary-500 mr-1" size={16} />
              <span className="mt-[1px] text-primary-500">Hapus</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-5">
          <div className="flex flex-row w-full flex-wrap items-start justify-between border border-solid border-x-0 border-t-0 border-b-1 border-b-gray-100 mt-2 pb-2">
            <Typography className="flex flex-col my-2">
              <Typography.Text className="font-semibold text-md">Judul Voucher</Typography.Text>
              <Typography.Text className="text-md text-gray-700">{voucher?.name}</Typography.Text>
            </Typography>
            <Typography className="flex flex-col my-2">
              <Typography.Text className="font-semibold text-md">Kode Voucher</Typography.Text>
              <Typography.Text className="text-md text-gray-700">
                {_.toUpper(voucher?.voucher_code)}
              </Typography.Text>
            </Typography>
            <Typography className="flex flex-col my-2">
              <Typography.Text className="font-semibold text-md">Layanan</Typography.Text>
              <Typography.Text className="text-md text-gray-700">
                {voucher?.voucher_type}
              </Typography.Text>
            </Typography>
            <Typography className="flex flex-col my-2">
              <Typography.Text className="font-semibold text-md">Jenis Voucher</Typography.Text>
              <Typography.Text className="text-md text-gray-700">
                {voucher?.is_private !== undefined
                  ? voucher?.is_private
                    ? 'Private'
                    : 'Public'
                  : '-'}
              </Typography.Text>
            </Typography>
            <Typography className="flex flex-col my-2">
              <Typography.Text className="font-semibold text-md">Potongan Untuk</Typography.Text>
              <Typography.Text className="text-md text-gray-700">
                {onReturnDiscountFor()}
              </Typography.Text>
            </Typography>
          </div>
          {voucher?.discount_for === 4 ? (
            <div className="flex flex-col w-full flex-wrap items-start justify-between border border-solid border-x-0 border-t-0 border-b-1 border-b-gray-100 mt-2 pb-2">
              {voucher?.general_services?.length > 0 ? (
                <Typography className="flex flex-col my-2">
                  <Typography.Text className="font-semibold text-md">
                    Layanan Servis
                  </Typography.Text>
                  <div className="my-2">
                    <div className="flex gap-2 flex-wrap whitespace-nowrap">
                      {voucher?.general_services?.map((service) => (
                        <BadgeItem
                          type="plain"
                          className={'w-full h-full flex gap-2 justify-center items-center'}
                          key={service?.id}>
                          {service?.name}
                        </BadgeItem>
                      ))}
                    </div>
                  </div>
                </Typography>
              ) : null}
              {voucher?.spareparts?.length > 0 ? (
                <Typography className="flex flex-col my-2">
                  <Typography.Text className="font-semibold text-md">Sparepart</Typography.Text>
                  <div className="my-2">
                    <div className="flex gap-2 flex-wrap whitespace-nowrap">
                      {voucher?.spareparts?.map((sparepart) => (
                        <BadgeItem
                          type="plain"
                          className={'w-full h-full flex gap-2 justify-center items-center'}
                          key={sparepart?.id}>
                          {sparepart?.name}
                        </BadgeItem>
                      ))}
                    </div>
                  </div>
                </Typography>
              ) : null}
            </div>
          ) : null}
          <div className="flex flex-row flex-wrap w-full items-start justify-around">
            <div className="flex-1 space-y-5">
              <Typography className="flex flex-col my-0">
                <Typography.Text className="font-semibold text-md">Discount</Typography.Text>
                <Typography.Text className="text-md text-gray-700">
                  {voucher?.discount_type === 2
                    ? voucher?.discount
                    : formatCurrency(voucher?.discount)}
                </Typography.Text>
              </Typography>
              <Typography className="flex flex-col my-2">
                <Typography.Text className="font-semibold text-md">Kuota Voucher</Typography.Text>
                <Typography.Text className="text-md text-gray-700">
                  {voucher?.voucher_quota}
                </Typography.Text>
              </Typography>
            </div>
            <div className="flex-1 space-y-5">
              <Typography className="flex flex-col my-0">
                <Typography.Text className="font-semibold text-md">
                  Maksimal Potongan
                </Typography.Text>
                <Typography.Text className="text-md text-gray-700">
                  {voucher?.max_discount === '-' ? '-' : formatCurrency(voucher?.max_discount ?? 0)}
                </Typography.Text>
              </Typography>
              <Typography className="flex flex-col my-2">
                <Typography.Text className="font-semibold text-md">Kuota Per User</Typography.Text>
                <Typography.Text className="text-md text-gray-700">
                  {voucher?.user_quota}
                </Typography.Text>
              </Typography>
            </div>
            <div className="flex-1 space-y-5">
              <Typography className="flex flex-col my-0">
                <Typography.Text className="font-semibold text-md">
                  Minimum Transaksi
                </Typography.Text>
                <Typography.Text className="text-md text-gray-700">
                  {voucher?.min_transaction === '-'
                    ? '-'
                    : formatCurrency(voucher?.min_transaction ?? 0)}
                </Typography.Text>
              </Typography>
              <Typography className="flex flex-col my-2">
                <Typography.Text className="font-semibold text-md">Expired Date</Typography.Text>
                <Typography.Text className="text-md text-gray-700">
                  {formatDate(voucher?.expiry_date ?? new Date())}
                </Typography.Text>
              </Typography>
            </div>
          </div>
          {voucher?.description ? (
            <div className="flex flex-col w-full flex-wrap items-start justify-between border border-solid border-x-0 border-t border-b-0 border-t-gray-100 mt-2 pb-2 gap-5">
              <Typography.Text className="font-semibold text-md mt-3">
                Deskripsi Voucher
              </Typography.Text>
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: voucher?.description }}
              />
            </div>
          ) : null}
        </div>
      </Card>

      <Card className="rounded-lg mt-4">
        <div className="mb-4">
          <Typography.Text className="font-semibold text-xl">
            Riwayat Penggunaan Voucher
          </Typography.Text>
        </div>
        <div className="mb-5 flex justify-between">
          <Input
            className="w-1/4 rounded-md"
            placeholder="Pencarian"
            value={tableState?.keywords}
            prefix={<IcSearch className="text-gray-400" />}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <Table
          columns={columns}
          data={data}
          pending={isLoading}
          onSort={handleSort}
          totalRows={tableState?.totalRows}
          handlePerRowsChange={handlePerRowsChange}
          pageSize={tableState?.limit}
          page={tableState?.offset}
          onChangePrevPage={() => {
            if (tableState?.offset > 0) {
              onChangePage(tableState?.offset - tableState?.limit)
            }
          }}
          onChangeNextPage={() => {
            if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
              onChangePage(tableState?.offset + tableState?.limit)
            }
          }}
        />
      </Card>
    </Content>
  )
}

export default VoucherDetailComponent
