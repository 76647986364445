import { Breadcrumb, Card, Input, Layout, Select, Tooltip, Typography } from 'antd'
import { apiDelete, apiGet, apiPost, apiPut, urls } from 'api'

import { IcEdit, IcPlus, IcSearch, IcTrash } from 'assets/icons'
import { Button, ModalDelete, Table } from 'components'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import { logout } from 'store/actions'
import { showErrorMessage, showSuccessMessage } from 'utils'
import { ModalEditQuantitySparepartGeneral, ModalTambahSparepartGeneral } from './components'
import { debounce } from 'lodash'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  listData: [],
  sparepart_category_id: '',
  keywords: '',
  sort_by: 'name',
  sort_order: 'desc'
}
const initialModalSparepartGeneral = {
  show: false,
  data: null,
  type: 'add'
}
const SparepartGeneralPage = () => {
  const [tableState, setTableState] = useState(initialTableState)
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const [modalSparepartGeneral, setModalSparepartGeneral] = useState(initialModalSparepartGeneral)
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const onLogout = () => dispatch(logout())
  // Get Category
  useQuery({
    queryKey: ['category-list'],
    refetchOnWindowFocus: false,
    queryFn: async ({ signal }) => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.serviceManagement?.sparepartCategories?.all}`,
        onLogout,
        signal
      })
      return res
    },
    onSuccess: (res) => {
      setCategories(res?.resData?.data ?? [])
    }
  })

  // List Spareparts that have been added on sparepart generals
  const {
    isLoading,
    refetch,
    data: sparepartGeneralList
  } = useQuery({
    queryKey: ['sparepart-generals-data', tableState],
    refetchOnWindowFocus: false,
    queryFn: async ({ signal }) => {
      let res = null
      res = await apiGet({
        url: `${urls.sparepart.prefix}/${urls.sparepart.sparepartGenerals.root}?keywords=${tableState?.keywords}&sparepart_category_id=${tableState?.sparepart_category_id}&sort_by=${tableState?.sort_by}&order_by=${tableState?.sort_order}`,
        token,
        onLogout: () => dispatch(logout()),
        signal
      })
      res = res?.resData?.data
      let unit_spareparts = res?.map((unit) => {
        return {
          id: unit?.id,
          sparepart_id: unit?.sparepart_id,
          name: unit?.sparepart.name,
          qty: unit.qty,
          uom: unit.uom,
          category: unit?.sparepart?.sparepart_category?.name,
          sparepart: unit.sparepart
        }
      })
      return unit_spareparts
    }
  })

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearch, 300)
  }, [])
  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  })
  function handleSearch(e) {
    setTableState((prev) => ({ ...prev, keywords: e.target.value, offset: 0 }))
  }

  const columns = [
    {
      sortField: 'sparepart_name',
      name: 'Nama Sparepart',
      selector: (row) => (
        <Tooltip
          title={
            <div className="flex flex-col gap-2">
              <span>{row?.sparepart?.sku}</span>
              <span>{row?.name}</span>
            </div>
          }
          className="flex flex-col gap-2">
          <span className="text-xs-regular text-gray-500">{row?.sparepart?.sku}</span>
          <span className="text-gray-900 text-medium">{row?.name}</span>
        </Tooltip>
      ),
      sortable: true
    },
    {
      sortField: 'sparepart_category',
      name: 'Kategori',
      selector: (row) => row?.category,
      sortable: true
    },
    {
      sortField: 'qty',
      name: 'Jumlah',
      selector: (row) => row?.qty,
      sortable: true
    },
    {
      sortField: 'uom',
      name: 'Satuan',
      selector: (row) => row?.uom,
      sortable: true
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <Button
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50"
            onClick={() => openModalEditSparepartGeneral(row)}>
            <IcEdit className="text-gray-700" size={16} />
          </Button>
          <Button
            className="bg-white border-[#FDA19B] hover:bg-red-50"
            onClick={() => openModalDeleteSparepartGeneral(row)}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  function openModalTambahSparepartGeneral() {
    setModalSparepartGeneral((prev) => ({ ...prev, type: 'add', show: true }))
  }
  function handleSubmitAddSparepartGeneral(selectedData) {
    setLoading(true)
    const mount = setTimeout(async () => {
      const res = await apiPost({
        url: `${urls.sparepart.prefix}/${urls.sparepart.sparepartGenerals.root}`,
        token,
        data: { unit_spareparts: selectedData },
        onLogout: () => dispatch(logout())
      })
      if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
        showSuccessMessage({ content: 'Berhasil menambahkan sparepart' })
        refetch()
        closeModalSparepartGeneral()
      } else {
        if (
          res?.resData?.message?.includes('is greater than maximum value for Int4') ||
          res?.resData?.errors?.[0]?.message?.includes('is greater than maximum value for Int4') ||
          res?.resData?.message?.includes('cannot unmarshal number') ||
          res?.resData?.errors?.[0]?.message?.includes('cannot unmarshal number')
        ) {
          showErrorMessage({ content: 'Input Quantity melebihi batas maksimal' })
        }
        // showErrorMessage({
        //   content:
        //     res.resData.message ?? res.resData.errors[0].message ?? 'Gagal menambahkan sparepart'
        // })
      }
      setLoading(false)
    })
    return () => clearTimeout(mount)
  }

  function openModalEditSparepartGeneral(selectedSparepart) {
    setModalSparepartGeneral((prev) => ({
      ...prev,
      type: 'edit',
      show: true,
      data: selectedSparepart
    }))
  }
  function handleEditSparepartGeneral(editedSparepart) {
    setLoading(true)
    const mount = setTimeout(async () => {
      const res = await apiPut({
        url: `${urls.sparepart.prefix}/${urls.sparepart.sparepartGenerals.root}/${editedSparepart?.id}`,
        data: { uom: editedSparepart?.uom, qty: parseInt(editedSparepart?.qty) },
        token,
        onLogout
      })
      if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
        showSuccessMessage({ content: 'Berhasil memperbarui data sparepart' })
        refetch()
        closeModalSparepartGeneral()
      } else {
        if (
          res?.resData?.message?.includes('QTY must be 1 or greater') ||
          res?.resData?.errors?.[0]?.message?.includes('QTY must be 1 or greater')
        ) {
          showErrorMessage({
            content: 'Quantity harus lebih besar dari 1 dan menggunakan format angka'
          })
        }
        // showErrorMessage({
        //   content:
        //     res.resData.message ??
        //     res.resData.errors[0].message ??
        //     'Gagal memperbarui data sparepart'
        // })
      }
      setLoading(false)
    })
    return () => clearTimeout(mount)
  }
  function openModalDeleteSparepartGeneral(selectedSparepart) {
    setModalSparepartGeneral((prev) => ({
      ...prev,
      type: 'delete',
      show: true,
      data: selectedSparepart
    }))
  }
  function handleDeleteSparepartGeneral() {
    setLoading(true)
    const mount = setTimeout(async () => {
      const res = await apiDelete({
        url: `${urls.sparepart.prefix}/${urls.sparepart.sparepartGenerals.root}/${modalSparepartGeneral?.data?.id}`,
        token,
        onLogout
      })
      if (res.resStatus === 200 && res.resData.meta.http_code === 200) {
        showSuccessMessage({ content: 'Berhasil menghapus sparepart' })
        refetch()
        closeModalSparepartGeneral()
      } else {
        showErrorMessage({
          content:
            res.resData.message ?? res.resData.errors[0].message ?? 'Gagal menghapus sparepart'
        })
      }
      setLoading(false)
    })
    return () => clearTimeout(mount)
  }
  function closeModalSparepartGeneral() {
    setModalSparepartGeneral(() => ({ ...initialModalSparepartGeneral }))
  }

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort_by: column.sortField,
      sort_order: sortDirection
    }))
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Sparepart
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Sparepart General
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card style={{ margin: 8 }} className="rounded-lg !mt-4">
        {modalSparepartGeneral.show && modalSparepartGeneral.type === 'delete' ? (
          <ModalDelete
            minHeight="min-h-[20%]"
            maxWidth="max-w-[25%]"
            title={'Hapus Sparepart'}
            desc={`Apakah Anda yakin ingin menghapus sparepart "${modalSparepartGeneral?.data?.name}" ini ? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
            onClose={closeModalSparepartGeneral}
            onSubmit={handleDeleteSparepartGeneral}
            isLoading={loading}
          />
        ) : null}
        {modalSparepartGeneral?.show && modalSparepartGeneral?.type === 'edit' ? (
          <ModalEditQuantitySparepartGeneral
            data={modalSparepartGeneral?.data}
            isOpen={modalSparepartGeneral?.show}
            onCancel={closeModalSparepartGeneral}
            isLoading={loading}
            onSubmit={handleEditSparepartGeneral}
          />
        ) : null}
        {modalSparepartGeneral.show && modalSparepartGeneral.type === 'add' ? (
          <ModalTambahSparepartGeneral
            isSubmitLoading={loading}
            isOpen={modalSparepartGeneral.show}
            onCancel={closeModalSparepartGeneral}
            onSubmit={handleSubmitAddSparepartGeneral}
            addedSparepartList={sparepartGeneralList}
          />
        ) : null}
        <div className="flex justify-between mb-5 items-center">
          <Typography.Text className="font-semibold text-xl">
            List Sparepart General
          </Typography.Text>
          <Button
            className="flex flex-row items-center justify-around rounded-md space-x-1 px-2 text-md text-white bg-primary-500 border-primary-500"
            onClick={() => openModalTambahSparepartGeneral()}>
            <IcPlus className="text-white" size={19} />
            <span>Tambahkan Sparepart </span>
          </Button>
        </div>
        <div className="mb-5 flex w-full justify-between">
          <div className="flex">
            <Select
              showSearch
              allowClear
              placeholder="Semua Kategori"
              className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center w-fit"
              filterOption={(input, option) => {
                return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
              }}
              style={{ minWidth: '200px' }}
              value={
                tableState?.sparepart_category_id === '' ? null : tableState?.sparepart_category_id
              }
              onChange={(e) =>
                setTableState((prev) => ({
                  ...prev,
                  sparepart_category_id: e ?? '',
                  city_id: '',
                  offset: 0
                }))
              }>
              {categories?.map((category, idx) => (
                <Select.Option key={idx} value={category?.id}>
                  {category?.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Input
            className="w-1/4 rounded-md"
            placeholder="Pencarian"
            prefix={<IcSearch className="text-gray-400" />}
            onChange={debouncedSearch}
          />
        </div>
        <Table
          sortServer
          columns={columns}
          data={sparepartGeneralList}
          pending={isLoading}
          onSort={handleSort}
          useFooter={false}
          className="table-overflow-y"
        />
      </Card>
    </Layout>
  )
}

export default SparepartGeneralPage
