import { Breadcrumb, Card, DatePicker, Input, Layout, Radio, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { apiGet, apiPost, apiPut } from 'api'
import { BadgeItem, CustomButton } from 'components'
import { ADD_VOUCHER, EDIT_VOUCHER, LAYANAN_LIST, SPAREPART_LIST } from 'constant'
import { formatDate, validationInput } from 'helpers'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { logout } from 'store/actions'
import {
  disabledDatePicker,
  formatThousandsINTL,
  onlyNumbers,
  showErrorMessage,
  showSuccessMessage,
  unformatCurrency
} from 'utils'
import ModalLayananSparepartList from './components/ModalLayananSparepartList'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { IcClose } from 'assets/icons'
import moment from 'moment'
const initialForms = {
  name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  },
  kode: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  },
  layanan: {
    value: null,
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  },
  isPrivate: false,
  typeDiscountFor: 1,
  selectedSpareparts: [],
  selectedServices: [],
  minTransaksi: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true,
      isNumeric: true
    }
  },
  typePotongan: 1,
  potongan: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true,
      isNumeric: true
    }
  },
  maxPotongan: '',
  kuotaVoucher: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true,
      isNumeric: true
    }
  },
  kuotaUser: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true,
      isNumeric: true
    }
  },
  expiredDate: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  },
  descriptionVoucher: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  }
}
const initialModalState = {
  category: SPAREPART_LIST,
  type: ADD_VOUCHER,
  show: false
}

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: [] }],
    [{ color: [] }, { background: [] }],
    [
      ({ list: 'ordered' },
      { list: 'bullet' },
      { align: [] },
      { indent: '-1' },
      { indent: '+1' },
      { script: 'sub' },
      { script: 'super' })
    ],
    ['link', 'image', 'clean']
  ]
}

var bold = Quill.import('formats/bold')
bold.tagName = 'b' // Quill uses <strong> by default
Quill.register(bold, true)

var italic = Quill.import('formats/italic')
italic.tagName = 'i' // Quill uses <em> by default
Quill.register(italic, true)

const CreateEditVoucherPage = () => {
  const [forms, setForms] = useState(initialForms)
  const [modalState, setModalState] = useState(initialModalState)
  const router = useHistory()
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const param = useParams()
  useQuery({
    queryKey: ['voucher-data'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `master-service/admin/vouchers/${param?.id}`,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      const data = res?.resData?.data
      if (res.resStatus === 404) {
        showErrorMessage({ content: 'Voucher Tidak Ada' })
        return router.replace('/master/voucher')
      }
      onChangeInput(data?.name, 'name')
      onChangeInput(data?.voucher_code, 'kode')
      // if(data?.voucher_type) {
      //   let layanan = voucherLayananData.find(item => item.name.toLowerCase() === data?.voucher_type?.toLowerCase())
      //   onChangeInput(layanan.id, 'layanan');
      // }
      onChangeInput(data?.is_private, 'isPrivate')
      onChangeInput(data?.discount_for, 'typeDiscountFor')
      onChangeInput(data?.discount_type, 'typePotongan')
      onChangeInput(data?.discount, 'potongan')
      onChangeInput(data?.discount_integer, 'potongan')
      onChangeInput(data?.description, 'descriptionVoucher')
      onChangeInput(data?.spareparts, 'selectedSpareparts')
      onChangeInput(data?.general_services, 'selectedServices')
      onChangeInput(
        data?.max_discount ? (data?.max_discount !== '-' ? data?.max_discount : '') : '',
        'maxPotongan'
      )
      onChangeInput(data?.voucher_quota, 'kuotaVoucher')
      onChangeInput(data?.user_quota, 'kuotaUser')
      onChangeInput(
        data?.expiry_date
          ? moment(new Date(data?.expiry_date?.split('/').join('-')), 'DD/MM/YYYY')
          : '',
        'expiredDate'
      )
      onChangeInput(data?.min_transaction, 'minTransaksi')
    },
    onError: (err) => {},
    enabled: param?.id !== undefined && router.location.pathname.split('/')[4]?.includes('edit')
  })
  const onChangeInput = (value, identifier) => {
    // IF Object has Validation Key
    if (forms[identifier]?.validation) {
      let { valid, messageError } = validationInput(value, forms[identifier]?.validation)

      if (
        ['potongan', 'kuotaVoucher', 'minTransaksi', 'kuotaUser'].includes(identifier) &&
        value !== '' &&
        value == 0
      ) {
        setForms((prevState) => {
          return {
            ...prevState,
            [identifier]: {
              ...prevState[identifier],
              touched: true,
              value: value,
              valid: false,
              messageError: 'Tidak boleh 0'
            }
          }
        })
      } else if (identifier == 'expiredDate' && value == null) {
        setForms((prevState) => {
          return {
            ...prevState,
            [identifier]: {
              ...prevState[identifier],
              touched: true,
              value: value,
              valid: false,
              messageError: 'harus diisi'
            }
          }
        })
      } else
        setForms((prevState) => {
          return {
            ...prevState,
            [identifier]: {
              ...prevState[identifier],
              touched: true,
              value: value,
              valid: valid,
              messageError: messageError
            }
          }
        })
    }
    // IF object doesn't have validation
    else {
      if (identifier === 'typeDiscountFor' && value !== 4) {
        setForms((prevState) => {
          return {
            ...prevState,
            [identifier]: value,
            ['selectedSpareparts']: [],
            ['selectedServices']: []
          }
        })
      } else
        setForms((prevState) => {
          return {
            ...prevState,
            [identifier]: value
          }
        })
    }
  }
  function onChangeInputDescription(value, editor) {
    let { valid, messageError } = validationInput(
      editor.getText().replace('\n', ''),
      forms['descriptionVoucher']?.validation
    )
    setForms((prevState) => {
      return {
        ...prevState,
        ['descriptionVoucher']: {
          ...prevState['descriptionVoucher'],
          touched: true,
          value: value,
          valid: valid,
          messageError: messageError
        }
      }
    })
  }

  function validateForms() {
    // Check if all mandatory fields are filled
    if (
      !forms?.name.valid ||
      !forms?.kode.valid ||
      !forms?.typePotongan ||
      !forms?.typeDiscountFor ||
      !forms?.kuotaUser.valid ||
      !forms?.potongan.valid ||
      !forms?.kuotaVoucher.valid ||
      !forms?.expiredDate.valid ||
      !forms?.minTransaksi.valid ||
      !forms?.descriptionVoucher.valid
    ) {
      return showErrorMessage({ content: 'Harap isi semua field mandatory' })
    }
    // Check if user quota greater than voucher quota
    if (parseInt(forms?.kuotaUser?.value ?? 0) > parseInt(forms?.kuotaVoucher?.value ?? 0)) {
      return showErrorMessage({ content: 'Kuota user tidak boleh lebih dari kuota voucher' })
    }

    // Check if user choose specific voucher but didn't select any services & spareparts
    if (
      forms?.typeDiscountFor === 4 &&
      forms?.selectedServices?.length == 0 &&
      forms?.selectedSpareparts?.length == 0
    ) {
      return showErrorMessage({
        content: 'Wajib pilih salah satu sparepart / layanan untuk discount Tertentu'
      })
    }

    handleSubmitVoucher()
  }

  async function handleSubmitVoucher() {
    let res = undefined
    let sparepartIds = []
    let servicesIds = []

    sparepartIds =
      forms?.selectedSpareparts?.length > 0
        ? forms?.selectedSpareparts?.map((sparepart) => sparepart?.id)
        : []
    servicesIds =
      forms?.selectedServices?.length > 0
        ? forms?.selectedServices?.map((service) => service?.id)
        : []

    if (param?.id !== undefined) {
      res = await apiPut({
        token: token,
        url: `master-service/admin/vouchers/${param?.id}`,
        data: {
          name: forms?.name.value,
          voucher_code: forms?.kode.value,
          // voucher_type: forms?.layanan.value,
          is_private: forms?.isPrivate,
          discount_for: forms?.typeDiscountFor,
          discount_type: forms?.typePotongan,
          expiry_date: formatDate(forms?.expiredDate.value),
          user_quota: parseInt(forms?.kuotaUser.value),
          voucher_quota: parseInt(forms?.kuotaVoucher.value),
          discount: parseInt(forms?.potongan?.value),
          max_discount: parseInt(forms?.maxPotongan ? forms?.maxPotongan : 0),
          min_transaction: parseInt(forms?.minTransaksi.value),
          general_service_ids: servicesIds,
          sparepart_ids: sparepartIds,
          description: forms?.descriptionVoucher?.value?.toString()
        }
      })
    } else {
      res = await apiPost({
        token: token,
        url: 'master-service/admin/vouchers',
        data: {
          name: forms?.name.value,
          voucher_code: forms?.kode.value,
          // voucher_type: forms?.layanan.value,
          is_private: forms?.isPrivate,
          discount_for: forms?.typeDiscountFor,
          discount_type: forms?.typePotongan,
          expiry_date: formatDate(forms?.expiredDate.value),
          user_quota: parseInt(forms?.kuotaUser.value),
          voucher_quota: parseInt(forms?.kuotaVoucher.value),
          discount: parseInt(forms?.potongan?.value),
          max_discount: parseInt(forms?.maxPotongan ? forms?.maxPotongan : 0),
          min_transaction: parseInt(forms?.minTransaksi.value),
          general_service_ids: servicesIds,
          sparepart_ids: sparepartIds,
          description: forms?.descriptionVoucher?.value
        }
      })
    }
    if (res?.resStatus === 200 || res?.resStatus === 201) {
      if (param?.id == undefined) {
        showSuccessMessage({ content: 'Voucher baru berhasil dibuat' })
        return router.replace('/master/voucher')
      } else {
        showSuccessMessage({ content: 'Voucher berhasil diedit' })
        return router.replace(`/master/voucher/${param?.id}`)
      }
    } else if (
      res?.resStatus === 409 &&
      res?.resData?.errors[0]?.message?.includes('Voucher code must be unique')
    ) {
      setForms((prev) => ({
        ...prev,
        kode: {
          ...prev.kode,
          valid: false,
          messageError: 'Kode voucher sudah ada, silahkan mengubah kode voucher'
        }
      }))
      showErrorMessage({
        content: 'Terjadi kesalahan, Kode voucher sudah ada, silahkan mengubah kode voucher'
      })
    }
  }

  function handleSubmitSelectedSparepartLayanan(selectedData) {
    onChangeInput(
      selectedData,
      modalState?.category === SPAREPART_LIST ? 'selectedSpareparts' : 'selectedServices'
    )
    closeModal()
  }

  function closeModal() {
    setModalState(() => ({ ...initialModalState }))
  }
  function openSparepartLayananList(category) {
    setModalState((prev) => ({
      ...prev,
      show: true,
      type: router.location.pathname.split('/')[4]?.includes('edit') ? EDIT_VOUCHER : ADD_VOUCHER,
      category: category
    }))
  }
  function removeSelectedItem(type, id) {
    const newArr =
      type === SPAREPART_LIST
        ? forms.selectedSpareparts?.filter((sparepart) => sparepart?.id !== id)
        : forms.selectedServices?.filter((service) => service?.id !== id)

    if (type === SPAREPART_LIST) {
      onChangeInput(newArr, 'selectedSpareparts')
    } else {
      onChangeInput(newArr, 'selectedServices')
    }
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Master Data</Breadcrumb.Item>
        <Breadcrumb.Item
          className="hover:cursor-pointer hover:underline"
          onClick={() => router.push('/master/voucher')}>
          Voucher
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {router.location.pathname === '/master/voucher/create' ? `Tambah` : 'Edit'} Voucher
        </Breadcrumb.Item>
      </Breadcrumb>
      {modalState?.show ? (
        <ModalLayananSparepartList
          data={
            modalState?.category === SPAREPART_LIST
              ? forms.selectedSpareparts
              : forms.selectedServices
          }
          isOpen={modalState?.show}
          onCancel={closeModal}
          onSubmit={handleSubmitSelectedSparepartLayanan}
          type={modalState?.type}
          isSparepart={modalState?.category === SPAREPART_LIST}
        />
      ) : null}
      <Content className="h-screen">
        <Card className="rounded-lg">
          <div className="flex justify-between mb-5 border border-solid border-x-0 border-t-0 border-b-1 border-gray-200 pb-5">
            <Typography.Text className="font-semibold text-2xl">
              {router.location.pathname === '/master/voucher/create' ? `Tambah` : 'Edit'} Voucher
            </Typography.Text>
          </div>
          <div className="mb-4">
            <Typography.Text className="font-medium">
              Judul
              <span className="text-[red]">*</span>
            </Typography.Text>
            <Input
              className={`rounded py-2 ${
                !forms?.name.valid && forms?.name.touched && 'border-red-500 border border-solid'
              }`}
              placeholder="Masukan Judul Voucher"
              value={forms?.name.value}
              onChange={(e) => onChangeInput(e.target.value, 'name')}
            />
            {!forms?.name.valid && forms?.name.touched && (
              <span className="text-[red]">{forms?.name.messageError}</span>
            )}
          </div>
          <div className="mb-4">
            <Typography.Text className="font-medium">
              Kode Voucher
              <span className="text-[red]">*</span>
            </Typography.Text>
            <Input
              className={`rounded py-2 ${
                !forms?.kode.valid && forms?.kode.touched && 'border-red-500 border border-solid'
              }`}
              placeholder="Masukan Kode Voucher"
              value={forms?.kode.value}
              onChange={(e) => onChangeInput(e.target.value, 'kode')}
            />
            {!forms?.kode.valid && forms?.kode.touched && (
              <span className="text-[red]">{forms?.kode.messageError}</span>
            )}
          </div>
          {/* <div className='mb-4'>
        <Typography.Text className="font-medium">
          Layanan
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Select
          className="custom-table-limit"
          style={{ width: '100%' }} 
          placeholder="Pilih Layanan"
          value={forms?.layanan.value? forms?.layanan.value : null}
          onChange={(value) => onChangeInput(value, 'layanan')}
        >
          {voucherLayananData?.map(voucher => (
            <Select.Option key={voucher?.id?.toString()} value={voucher?.id}>{voucher?.name}</Select.Option>
          ))}
        </Select>
        {!forms?.layanan.valid && forms?.layanan.touched && <span className="text-[red]">{forms?.layanan.messageError}</span>}
      </div> */}
          <div className="mb-4">
            <Typography.Text className="font-medium">
              Jenis Voucher
              <span className="text-[red]">*</span>
            </Typography.Text>
            <div className="my-1">
              <Radio.Group
                name="radiogroup"
                className="flex flex-row space-x-1 items-center justify-start"
                onChange={(e) => {
                  onChangeInput(e.target.value, 'isPrivate')
                }}
                value={forms?.isPrivate}>
                <Radio className="custom-radio-btn" value={false}>
                  Public
                </Radio>
                <Radio className="custom-radio-btn" value={true}>
                  Private
                </Radio>
              </Radio.Group>
            </div>
          </div>
          <div className="mb-4">
            <Typography.Text className="font-medium">
              Potongan Untuk
              <span className="text-[red]">*</span>
            </Typography.Text>
            <div className="my-1">
              <Radio.Group
                name="radiogroup"
                className="flex flex-row space-x-1 items-center justify-start"
                onChange={(e) => {
                  onChangeInput(e.target.value, 'typeDiscountFor')
                }}
                value={forms?.typeDiscountFor}>
                <Radio className="custom-radio-btn" value={1}>
                  Harga Jasa
                </Radio>
                <Radio className="custom-radio-btn" value={2}>
                  Harga Sparepart
                </Radio>
                <Radio className="custom-radio-btn" value={3}>
                  Harga Jasa dan Sparepart
                </Radio>
                <Radio className="custom-radio-btn" value={4}>
                  Tertentu
                </Radio>
              </Radio.Group>
            </div>
          </div>
          {forms?.typeDiscountFor === 4 ? (
            <>
              <div className="mb-4">
                <Typography.Text className="font-medium">
                  Layanan Servis
                  {/* <span className="text-[red]">*</span> */}
                </Typography.Text>
                {/* List */}
                <div className="my-2">
                  {forms?.selectedServices?.length > 0 ? (
                    <div className="flex gap-2 flex-wrap whitespace-nowrap">
                      {forms?.selectedServices?.map((service) => (
                        <BadgeItem
                          type="plain"
                          className={'w-full h-full flex gap-2 justify-center items-center'}
                          key={service?.id}>
                          {service?.name}
                          <span
                            className="flex justify-center items-center cursor-pointer"
                            onClick={removeSelectedItem.bind(this, LAYANAN_LIST, service?.id)}>
                            <IcClose size={15} />
                          </span>
                        </BadgeItem>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="my-1">
                  <CustomButton
                    onClick={openSparepartLayananList.bind(this, LAYANAN_LIST)}
                    type="plain"
                    className="!py-2  !text-sm">
                    Pilih Layanan
                  </CustomButton>
                </div>
              </div>
              <div className="mb-4">
                <Typography.Text className="font-medium">Sparepart</Typography.Text>
                {/* List */}
                <div className="my-2">
                  {forms?.selectedSpareparts?.length > 0 ? (
                    <div className="flex gap-2 flex-wrap whitespace-nowrap">
                      {forms?.selectedSpareparts?.map((sparepart) => (
                        <BadgeItem
                          type="plain"
                          key={sparepart?.id}
                          className={'w-full h-full flex gap-2 justify-center items-center'}>
                          {sparepart?.name}
                          <span
                            className="flex justify-center items-center cursor-pointer"
                            onClick={removeSelectedItem.bind(this, SPAREPART_LIST, sparepart?.id)}>
                            <IcClose size={15} />
                          </span>
                        </BadgeItem>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="my-1">
                  <CustomButton
                    onClick={openSparepartLayananList.bind(this, SPAREPART_LIST)}
                    type="plain"
                    className="!py-2  !text-sm">
                    Pilih Sparepart
                  </CustomButton>
                </div>
              </div>
            </>
          ) : null}
          <div className="mb-4">
            <Typography.Text className="font-medium">
              Minimum Transaksi
              <span className="text-[red]">*</span>
            </Typography.Text>
            <Input
              className={`rounded py-2 ${
                !forms?.minTransaksi.valid &&
                forms?.minTransaksi.touched &&
                'border-red-500 border border-solid'
              }`}
              placeholder="Masukan Minimum Transaksi"
              prefix={<span className="text-gray-400">Rp </span>}
              value={
                forms?.minTransaksi.value === ''
                  ? ''
                  : formatThousandsINTL(forms.minTransaksi.value)
              }
              onChange={(e) => {
                if (onlyNumbers(unformatCurrency(e.target.value))) {
                  if (e.target.value !== '') {
                    onChangeInput(unformatCurrency(e.target.value), 'minTransaksi')
                  } else {
                    onChangeInput(e.target.value, 'minTransaksi')
                  }
                }
                if (e.target.value === '') {
                  onChangeInput(e.target.value, 'minTransaksi')
                }
              }}
            />
            {!forms?.minTransaksi.valid && forms?.minTransaksi.touched && (
              <span className="text-[red]">{forms?.minTransaksi.messageError}</span>
            )}
          </div>
          <div className="mb-4">
            <Typography.Text className="font-medium">
              Potongan Harga
              <span className="text-[red]">*</span>
            </Typography.Text>
            <div className="my-1">
              <Radio.Group
                name="radiogroup"
                onChange={(e) => {
                  onChangeInput(e.target.value, 'typePotongan')
                  if (
                    e.target.value === 2 &&
                    forms?.potongan?.value &&
                    parseInt(forms?.potongan?.value) > 100
                  ) {
                    onChangeInput('100', 'potongan')
                  }
                }}
                value={forms?.typePotongan}>
                <Radio className="custom-radio-btn" value={1}>
                  Rupiah
                </Radio>
                <Radio className="custom-radio-btn" value={2}>
                  Percent
                </Radio>
              </Radio.Group>
            </div>
            <Input
              type={`${forms.typePotongan === 2 ? 'number' : ''}`}
              className={`rounded py-2 ${
                !forms?.potongan.valid &&
                forms?.potongan.touched &&
                'border-red-500 border border-solid'
              }`}
              onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
              placeholder="Masukan Potongan"
              prefix={
                <span
                  className={`text-gray-400 ${
                    forms?.typePotongan && forms?.typePotongan === 1 ? '' : 'hidden'
                  }`}>
                  Rp{' '}
                </span>
              }
              suffix={
                <span
                  className={`text-gray-400 ${
                    forms?.typePotongan && forms?.typePotongan === 2 ? '' : 'hidden'
                  }`}>
                  {' '}
                  %
                </span>
              }
              value={
                forms.typePotongan === 1
                  ? forms.potongan.value === ''
                    ? ''
                    : formatThousandsINTL(forms.potongan.value)
                  : forms.potongan.value
              }
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, '')

                if (onlyNumbers(value)) {
                  if (value !== '') {
                    onChangeInput(value, 'potongan')
                  } else {
                    onChangeInput(value, 'potongan')
                  }
                }
                if (value === '') {
                  onChangeInput(value, 'potongan')
                }
              }}
            />
            {!forms?.potongan.valid && forms?.potongan.touched && (
              <span className="text-[red]">{forms?.potongan.messageError}</span>
            )}
          </div>
          <div className="mb-4">
            <Typography.Text className="font-medium">Maksimal Potongan</Typography.Text>
            <Input
              className="rounded py-2"
              placeholder="Masukan Maksimal Potongan"
              prefix={<span className="text-gray-400">Rp </span>}
              value={forms?.maxPotongan === '' ? '' : formatThousandsINTL(forms.maxPotongan)}
              onChange={(e) => {
                if (onlyNumbers(unformatCurrency(e.target.value))) {
                  if (e.target.value !== '') {
                    onChangeInput(unformatCurrency(e.target.value), 'maxPotongan')
                  } else {
                    onChangeInput(e.target.value, 'maxPotongan')
                  }
                }
                if (e.target.value === '') {
                  onChangeInput(e.target.value, 'maxPotongan')
                }
              }}
            />
          </div>
          <div className="mb-4 flex gap-x-4">
            <div className="flex-1">
              <Typography.Text className="font-medium">
                Kuota Voucher
                <span className="text-[red]">*</span>
              </Typography.Text>
              <Input
                type="number"
                className={`rounded py-2 ${
                  !forms?.kuotaVoucher.valid &&
                  forms?.kuotaVoucher.touched &&
                  'border-red-500 border border-solid'
                }`}
                onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                placeholder="Masukan Kuota Voucher"
                value={forms?.kuotaVoucher.value}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '')
                  onChangeInput(value, 'kuotaVoucher')
                }}
              />
              {!forms?.kuotaVoucher.valid && forms?.kuotaVoucher.touched && (
                <span className="text-[red]">{forms?.kuotaVoucher.messageError}</span>
              )}
            </div>
            <div className="flex-1">
              <Typography.Text className="font-medium">
                Kuota per User
                <span className="text-[red]">*</span>
              </Typography.Text>
              <Input
                type="number"
                className={`rounded py-2 ${
                  !forms?.kuotaUser.valid &&
                  forms?.kuotaUser.touched &&
                  'border-red-500 border border-solid'
                }`}
                onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                placeholder="Masukan Kuota per User"
                value={forms?.kuotaUser.value}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '')
                  onChangeInput(value, 'kuotaUser')
                }}
              />
              {!forms?.kuotaUser.valid && forms?.kuotaUser.touched && (
                <span className="text-[red]">{forms?.kuotaUser.messageError}</span>
              )}
            </div>
          </div>
          <div className="mb-4">
            <Typography.Text className="font-medium">
              Expired Date
              <span className="text-[red]">*</span>
            </Typography.Text>
            <DatePicker
              value={forms?.expiredDate.value}
              onChange={(value) => onChangeInput(value, 'expiredDate')}
              className="custom-date-picker w-full py-2"
              popupClassName="custom-date-picker-dropdown"
              format={['DD/MM/YYYY']}
              placeholder="DD/MM/YYYY"
              disabledDate={disabledDatePicker}
            />
            {!forms?.expiredDate.valid && forms?.expiredDate.touched && (
              <span className="text-[red]">{forms?.expiredDate.messageError}</span>
            )}
          </div>
          <div className="mb-4">
            <Typography.Text className="font-medium">
              Deskripsi Voucher
              <span className="text-[red]">*</span>
            </Typography.Text>
            <div className="h-full overflow-y-auto">
              <ReactQuill
                placeholder="Tulis deskripsi voucher disini"
                modules={modules}
                theme="snow"
                value={forms?.descriptionVoucher?.value}
                onChange={(value, delta, source, editor) => onChangeInputDescription(value, editor)}
              />
            </div>
            {!forms?.descriptionVoucher?.valid && forms?.descriptionVoucher?.touched && (
              <span className="text-[red]">{forms?.descriptionVoucher?.messageError}</span>
            )}
          </div>
        </Card>
        <div className="flex justify-end gap-2 w-full items-center pt-8 pb-40">
          <CustomButton
            type="plain"
            onClick={() => {
              if (param?.id == undefined) {
                return router.push('/master/voucher')
              } else {
                return router.goBack()
              }
            }}>
            Cancel
          </CustomButton>
          <CustomButton
            disabled={
              !forms?.name.valid ||
              !forms?.kode.valid ||
              !forms?.typePotongan ||
              !forms?.typeDiscountFor ||
              !forms?.kuotaUser.valid ||
              !forms?.potongan.valid ||
              !forms?.kuotaVoucher.valid ||
              !forms?.expiredDate.valid ||
              !forms?.minTransaksi.valid ||
              !forms?.descriptionVoucher.valid
            }
            onClick={validateForms}>
            Confirm
          </CustomButton>
        </div>
      </Content>
    </Layout>
  )
}

export default CreateEditVoucherPage
