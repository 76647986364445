import { useState, useEffect, useCallback } from 'react'
import { useMutation, useQuery } from 'react-query'
import { debounce } from 'lodash'
import { apiGet, apiPost } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import useDebounce from 'hooks/useDebounce'
import { showErrorMessage, showSuccessMessage } from 'utils'

const initialFilterPagination = {
  type_id: null,
  limit: 5,
  search: '',
  selected_general_service_ids: [],
  without_general_service_ids: [],
  without_packet_ids: []
}
const useServicePackets = (selectedTypeId, generalServices, packets) => {
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }
  const [servicePackets, setServicePackets] = useState([])
  const [servicesGenerals, setServiceGenerals] = useState([])
  const [selectedGeneralServiceIds, setSelectedGeneralServiceIds] = useState({})
  const [selectedPacketIds, setSelectedPacketIds] = useState({})

  const [services, setServices] = useState([])
  const [serviceCategories, setServiceCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [keyword, setKeyword] = useState('')
  const [filterServicePagination, setFilterServicePagination] = useState({
    ...initialFilterPagination
  })
  const [isAddServisBtnDisabled, setIsAddServisBtnDisabled] = useState(false)

  useEffect(() => {
    let newFilterServicePagination = { ...filterServicePagination }
    let newSelectedGeneralServicesIds = { ...selectedGeneralServiceIds }
    // handle Existing General Service on Detail Service
    if (generalServices?.length > 0) {
      let newSelectedGeneralServiceArr = []
      generalServices?.map((service) => {
        const newObj = {
          sparepart: {
            name: service?.sparepart_name,
            id: service?.sparepart_id,
            service_id: service?.service_id,
            service_price: service?.service_price_total,
            price: service?.sparepart_price,
            quantity: service?.quantity
          },
          general_service: {
            id: service?.general_service_id,
            name: service?.general_service_name
          },
          service_id: service?.service_id,
          general_service_id: service?.general_service_id,
          total_price: service?.price_total,
          ...service
        }

        if (!service.is_free) {
          newSelectedGeneralServicesIds = {
            ...newSelectedGeneralServicesIds,
            [service.general_service_id]: true
          }
        }
        newFilterServicePagination = {
          ...newFilterServicePagination,
          type_id: selectedTypeId,
          selected_general_service_ids: [
            ...new Set([
              ...newFilterServicePagination.selected_general_service_ids,
              service.general_service_id
            ])
          ]
        }
        newSelectedGeneralServiceArr.push(newObj)
      })
      setServiceGenerals([...servicesGenerals, ...newSelectedGeneralServiceArr])
      setSelectedGeneralServiceIds({ ...newSelectedGeneralServicesIds })
    }
    if (packets?.length > 0) {
      const newPacketGeneralArr = []
      let newSelectedPacketIds = {}
      packets?.map((packet) => {
        newSelectedPacketIds = { ...newSelectedPacketIds, [packet.packet_id]: true }
        const newObj = {
          service_id: packet?.service_id,
          packet_id: packet?.packet_id,
          packet_name: packet?.packet_name,
          general_services: packet?.spareparts,
          totalDuration: 0
        }
        packet?.spareparts?.length > 0 &&
          packet?.spareparts.map((service) =>
            service.is_free === true
              ? false
              : (newSelectedGeneralServicesIds[service?.general_service_id] = true)
          )
        newPacketGeneralArr.push(newObj)
      })
      const generalServiceIds = Object.keys(newSelectedGeneralServicesIds).map((id) => parseInt(id))
      newFilterServicePagination = {
        ...newFilterServicePagination,
        type_id: selectedTypeId,
        selected_general_service_ids: [
          ...new Set([
            ...newFilterServicePagination.selected_general_service_ids,
            ...generalServiceIds
          ])
        ]
      }
      setSelectedGeneralServiceIds({
        ...selectedGeneralServiceIds,
        ...newSelectedGeneralServicesIds
      })
      setSelectedPacketIds({ ...selectedPacketIds, ...newSelectedPacketIds })
      setServicePackets([...servicePackets, ...newPacketGeneralArr])
    }
    setFilterServicePagination(() => ({
      ...newFilterServicePagination
    }))
  }, [generalServices, packets])

  useQuery({
    queryKey: 'getServiceCategories',
    queryFn: async () => {
      const res = await apiGet({
        token: token,
        url: `servis-service/services/all`,
        onLogout
      })
      return res
    },
    onSuccess: (data) => {
      setServiceCategories(data?.resData?.data)
      setSelectedCategory(data?.resData?.data?.[0])
    },
    refetchOnWindowFocus: false
  })
  // DONE

  const postServicePagination = useMutation({
    mutationFn: async (dataSend) => {
      const res = apiPost({
        token: token,
        url: `servis-service/services/${selectedCategory?.id}`,
        data: dataSend,
        onLogout: onLogout
      })

      return res
      // postServiceV2(dataSend, selectedCategory?.id)
    },
    onSuccess: (res) => {
      const data = res?.resData?.data
      if (
        data?.general_services?.length === 0 &&
        data?.packets?.length === 0 &&
        (filterServicePagination?.without_general_service_ids?.length !== 0 ||
          filterServicePagination?.without_packet_ids?.length !== 0)
      ) {
        setIsAddServisBtnDisabled(true)
        return showSuccessMessage({ content: 'Semua Data Servis Sudah Ditampilkan' })
      }

      const mergePacketGeneral = []
      const excludePacketsIds = data?.packets?.map((packet) => {
        let lowestPriceByRecSparepart = 0
        packet?.general_services?.map((general_service) => {
          if (general_service?.spareparts?.length > 0) {
            const newSparepartArray = sortSparepartRecommendation(general_service?.spareparts)
            general_service.spareparts = newSparepartArray

            // Check if General Service Has Recommendation Sparepart Or Not
            lowestPriceByRecSparepart += getLowestPriceRecSparepart(newSparepartArray)
          }
        })
        packet.price_total = lowestPriceByRecSparepart
        mergePacketGeneral.push({ type: 'PACKET', ...packet })
        return packet.id
      })
      const excludeGeneralIds = data?.general_services?.map((general_service) => {
        let lowestPriceByRecSparepart = 0
        if (general_service?.spareparts?.length > 0) {
          const newSparepartArray = sortSparepartRecommendation(general_service?.spareparts)
          general_service.spareparts = newSparepartArray
          // Check if General Service Has Recommendation Sparepart Or Not
          lowestPriceByRecSparepart += getLowestPriceRecSparepart(newSparepartArray)
        }
        general_service.price_total = lowestPriceByRecSparepart
        mergePacketGeneral.push({ type: 'GENERAL', ...general_service })
        return general_service.id
      })

      setServices((prev) => [...prev, ...mergePacketGeneral])
      setFilterServicePagination((prev) => ({
        ...prev,
        without_general_service_ids: [...prev.without_general_service_ids, ...excludeGeneralIds],
        without_packet_ids: [...prev.without_packet_ids, ...excludePacketsIds]
      }))
    },
    onError: () => {
      showErrorMessage({
        content: 'Terjadi kesalahan pada Daftar Service, Silahkan coba lagi nanti'
      })
    },
    refetchOnWindowFocus: false
  })

  useDebounce(
    () => {
      if (!selectedTypeId || !selectedCategory?.id) {
        return
      }
      const newFilterServicePagination = {
        type_id: selectedTypeId,
        limit: 5,
        search: keyword,
        selected_general_service_ids: Object.keys(selectedGeneralServiceIds)?.map((id) =>
          parseInt(id)
        ),
        without_general_service_ids: [],
        without_packet_ids: []
      }
      setServices([])
      setIsAddServisBtnDisabled(false)
      setFilterServicePagination(newFilterServicePagination)
      postServicePagination.reset()
      postServicePagination.mutate(newFilterServicePagination)
    },
    [selectedCategory?.id, selectedTypeId],
    300
  )

  const debouncedChangeHandler = useCallback(
    debounce((inputValue, selectedTypeId) => {
      setServices([])
      setIsAddServisBtnDisabled(false)
      postServicePagination.reset()
      postServicePagination.mutate({
        type_id: selectedTypeId,
        limit: 5,
        search: inputValue,
        selected_general_service_ids: Object.keys(selectedGeneralServiceIds)?.map((id) =>
          parseInt(id)
        ),
        without_general_service_ids: [],
        without_packet_ids: []
      })
    }, 1000),
    []
  )

  function sortSparepartRecommendation(spareparts) {
    const newSpareparts = spareparts.sort(
      (a, b) => Number(b.is_recommended) - Number(a.is_recommended)
    )
    return newSpareparts
  }

  function getLowestPriceRecSparepart(newSparepartArray) {
    if (newSparepartArray[0].is_recommended) {
      return newSparepartArray[0].price_total
    } else {
      let lowestPrice = Math.min(...newSparepartArray.map((sparepart) => sparepart.price_total))
      return lowestPrice
    }
  }

  function handleSelectedCategory(id) {
    if (id === selectedCategory) {
      return
    }
    setServices([])
    setSelectedCategory(id)
    resetKeyword()
    setFilterServicePagination({
      ...filterServicePagination,
      search: '',
      without_general_service_ids: [],
      without_packet_ids: []
    })
  }

  function handleTampilLebihBanyak() {
    postServicePagination.reset()
    postServicePagination.mutate(filterServicePagination)
  }

  function resetKeyword() {
    setKeyword('')
    setFilterServicePagination({
      ...filterServicePagination,
      search: '',
      selected_general_service_ids: Object.keys(selectedGeneralServiceIds)?.map((id) =>
        parseInt(id)
      ),
      without_general_service_ids: [],
      without_packet_ids: []
    })
  }

  function changeKeywordHandler(e) {
    setKeyword(e.target.value)
    debouncedChangeHandler(e.target.value, selectedTypeId)
    setFilterServicePagination({
      ...filterServicePagination,
      search: e.target.value,
      selected_general_service_ids: Object.keys(selectedGeneralServiceIds)?.map((id) =>
        parseInt(id)
      ),
      without_general_service_ids: [],
      without_packet_ids: []
    })
  }

  function deleteAllServices() {
    setServicePackets([])
    setServiceGenerals([])
    setSelectedGeneralServiceIds({})
    setSelectedPacketIds({})
    setServices([])
    setFilterServicePagination((prev) => ({
      ...initialFilterPagination,
      type_id: prev.type_id
    }))
  }
  function handleGeneralService(serviceId, generalService) {
    const newObj = {
      service_id: serviceId,
      total_price:
        generalService.sparepart.price * generalService.sparepart.quantity +
        generalService.totalBiayaLayanan,
      ...generalService
    }
    if (!generalService?.is_free) {
      setSelectedGeneralServiceIds({
        ...selectedGeneralServiceIds,
        [generalService.general_service.id]: true
      })
    }
    const newFilterServicePagination = {
      ...filterServicePagination,
      type_id: selectedTypeId,
      selected_general_service_ids: [
        ...new Set([
          ...filterServicePagination.selected_general_service_ids,
          generalService.general_service.id
        ])
      ]
    }
    setFilterServicePagination(() => ({
      ...newFilterServicePagination
    }))
    // CHECK IF USER EDIT EXISTING SELECTED SERVICE
    const checkIfServiceExistIndex = servicesGenerals.findIndex(
      (service) =>
        service?.service_id == newObj?.service_id &&
        service?.general_service?.id == newObj?.general_service?.id
    )
    // IF NEW SELECTED NOT FOUND
    if (checkIfServiceExistIndex !== -1) {
      const newServiceGeneralsArr = [...servicesGenerals]
      newServiceGeneralsArr[checkIfServiceExistIndex] = newObj
      return setServiceGenerals([...newServiceGeneralsArr])
    }
    return setServiceGenerals([...servicesGenerals, newObj])
  }

  function handlePacketService(packetId, serviceId, generalServices, packetName, totalDuration) {
    const newObj = {
      service_id: serviceId,
      packet_id: packetId,
      packet_name: packetName,
      general_services: generalServices,
      totalDuration: totalDuration
    }
    const serviceIds = {}
    generalServices.map((service) =>
      service.general_service.is_free === true
        ? false
        : (serviceIds[service.general_service.id] = true)
    )
    const generalServiceIds = Object.keys(serviceIds).map((id) => parseInt(id))
    const newFilterServicePagination = {
      ...filterServicePagination,
      type_id: selectedTypeId,
      selected_general_service_ids: [
        ...new Set([...filterServicePagination.selected_general_service_ids, ...generalServiceIds])
      ]
    }
    // postServicePagination.mutate(newFilterServicePagination)
    setFilterServicePagination(() => ({
      ...newFilterServicePagination
    }))
    setSelectedGeneralServiceIds({ ...selectedGeneralServiceIds, ...serviceIds })
    setSelectedPacketIds({ ...selectedPacketIds, [packetId]: true })
    // Check if Edited Service is exist
    const checkIfPacketServiceIndex = servicePackets.findIndex(
      (packet) =>
        packet?.service_id === newObj?.service_id && packet?.packet_id == newObj?.packet_id
    )
    if (checkIfPacketServiceIndex !== -1) {
      const newServicePacketsArr = [...servicePackets]
      newServicePacketsArr[checkIfPacketServiceIndex] = newObj
      return setServicePackets([...newServicePacketsArr])
    }
    return setServicePackets([...servicePackets, newObj])
  }

  function removeCartItem(typeService, serviceId) {
    let selectedGeneralIds = { ...selectedGeneralServiceIds }
    if (typeService === 'packetService') {
      let generalServiceIds = []
      // DELETE ID FROM ARRAY & OBJECT IDS
      servicePackets
        .find((packet) => packet.packet_id === serviceId)
        .general_services.map((service) => generalServiceIds.push(service.general_service_id))
      generalServiceIds.map((id) => delete selectedGeneralIds[id])
      const newServicePackets = servicePackets.filter((packet) => packet.packet_id !== serviceId)
      const newSelectedPacketIds = { ...selectedPacketIds }
      delete newSelectedPacketIds[serviceId]

      setSelectedPacketIds(newSelectedPacketIds)
      setServicePackets(newServicePackets)
    } else if (typeService === 'generalService') {
      let newServiceGenerals = servicesGenerals.filter(
        (service) => service?.general_service?.id !== serviceId
      )
      delete selectedGeneralIds[serviceId]
      setServiceGenerals(newServiceGenerals)
      setSelectedGeneralServiceIds({ ...selectedGeneralIds })
    }

    const newFilterServicePagination = {
      ...filterServicePagination,
      type_id: selectedTypeId,
      selected_general_service_ids: Object.keys(selectedGeneralIds)?.map((id) => parseInt(id)),
      without_general_service_ids: [],
      without_packet_ids: []
    }
    setServices([])
    postServicePagination.mutate(newFilterServicePagination)
    setFilterServicePagination(() => ({
      ...newFilterServicePagination
    }))
    setSelectedGeneralServiceIds({ ...selectedGeneralIds })
  }

  function handleValidationPacketsService() {
    if (servicePackets.length === 0 && servicesGenerals.length == 0) {
      return { status: false, errorMessage: 'Mohon pilih salah satu servis terlebih dahulu' }
    }
    return { status: true, errorMessage: '' }
  }

  return {
    removeCartItem,
    handlePacketService,
    handleGeneralService,
    deleteAllServices,
    services,
    servicePackets,
    servicesGenerals,
    selectedGeneralServiceIds,
    selectedPacketIds,
    handleValidationPacketsService,
    changeKeywordHandler,
    resetKeyword,
    keyword,
    serviceCategories,
    handleSelectedCategory,
    selectedCategory,
    handleTampilLebihBanyak,
    servicesIsFetching: postServicePagination.isLoading,
    isAddServisBtnDisabled
  }
}

export default useServicePackets
