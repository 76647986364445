import React, { useCallback, useState } from 'react'
import { Breadcrumb, Input, Layout, Select } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { CardContainer, HeaderContainer, Table } from 'components'
import { useHistory } from 'react-router-dom'
import { IcTrash, IcEye, IcSearch } from 'assets/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { apiDelete, apiGet, apiPost, urls } from 'api'
import { logout } from 'store/actions'
import { ModalUpdatePerusahaan } from '../../components'
import { formatCurrency, regexPassword, showErrorMessage, showSuccessMessage } from 'utils'
import _ from 'lodash'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  city_id: '',
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null
}

const PerusahanPage = () => {
  const router = useHistory()
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const [searchSelectCity, setSearchSelectCity] = useState('')
  const [modal, setModal] = useState({
    show: false,
    type: 'add',
    data: null
  })

  const onLogout = () => dispatch(logout())

  const columns = [
    {
      name: 'Nama Perusahaan',
      selector: (row) => row?.name,
      cell: (row) => <span className="font-medium text-gray-700">{row.name}</span>,
      grow: 2
    },
    {
      name: 'Alamat',
      selector: (row) => row?.address,
      cell: (row) => (
        <span className="font-medium text-gray-700">
          {row?.address && row?.address !== '' ? _.startCase(row?.address) : '-'}
        </span>
      ),
      grow: 2
    },
    {
      name: 'Users',
      selector: (row) => row?.user_count,
      cell: (row) => <span className="font-medium text-gray-700">{row?.user_count}</span>,
      grow: 1
    },
    // {
    //   name: 'Potongan Harga',
    //   selector: (row) => row?.discount,
    //   cell: (row) =>
    //     row?.discount && row?.discount !== '' && row?.discount !== '-'
    //       ? `- ${formatCurrency(parseInt(row?.discount))}`
    //       : '-',
    //   grow: 1
    // },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      grow: 1,
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          <div
            className="hover:cursor-pointer p-1 border-solid border border-gray-300 rounded-md mr-2 hover:bg-gray-100"
            onClick={() => router.push(`/master/perusahaan/perusahaandetail/${row.id}/unit`)}>
            <IcEye className="text-gray-700" size={16} />
          </div>
          <div
            className="hover:cursor-pointer p-1 border-solid border border-[#FDA19B] rounded-md hover:bg-gray-100"
            onClick={() => {
              setModal({
                ...modal,
                show: true,
                data: row,
                type: 'delete'
              })
            }}>
            <IcTrash className="text-primary-500" size={16} />
          </div>
        </div>
      )
    }
  ]

  const { refetch, isLoading } = useQuery({
    queryKey: [
      'perusahaan-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords,
      tableState.city_id
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: tableState.keywords,
        offset: tableState.offset,
        limit: tableState.limit,
        sort_by: tableState.sort_by,
        sort_order: tableState.sort_order,
        city_id: tableState.city_id
      }
      const res = await apiGet({
        token,
        data: params,
        url: 'user-service/admin/companies',
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setTableState({
        ...tableState,
        listData: res?.resData?.data,
        totalRows: res?.resData?.meta?.total
      })
    }
  })

  const selectCityData = useQuery({
    queryKey: ['select-city', searchSelectCity],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: searchSelectCity
      }
      const res = await apiGet({
        token,
        data: params,
        url: `${urls.master.prefix}/${urls.master.location.cities.all}`
      })
      return res
    },
    enabled: !isLoading
  })

  const onCheckFieldEmpty = (data) => {
    return Object.entries(data).every((item) => {
      if (item[1] !== undefined && item[1] !== '' && item[1] !== null && item[1].length !== 0) {
        if (item[0] === 'password') {
          if (regexPassword(item[1])) {
            return true
          } else {
            showErrorMessage({
              content:
                'Password minimal 8 karakter, maksimal 13 karakter, berupa kombinasi huruf & angka.',
              duration: 4000
            })
            return false
          }
        } else {
          return true
        }
      } else {
        if (
          item[0] !== 'id' &&
          item[0] !== 'province_name' &&
          item[0] !== 'city_name' &&
          item[0] !== 'sub_district_name'
        ) {
          if (item[0] === 'province_id') {
            showErrorMessage({ content: `Silahkan Pilih Provinsi` })
          } else if (item[0] === 'city_id') {
            showErrorMessage({ content: `Silahkan Pilih Kota/Kabupaten` })
          } else if (item[0] === 'sub_district_id') {
            showErrorMessage({ content: `Silahkan Pilih Kecamatan` })
          } else {
            showErrorMessage({ content: `Silahkan Lengkapi Field ${_.startCase(item[0])}` })
          }
          return false
        } else {
          return true
        }
      }
    })
  }

  const saveData = async (data) => {
    if (onCheckFieldEmpty(data)) {
      try {
        const res = await apiPost({ url: `user-service/admin/companies`, token, data, onLogout })
        if (res?.resStatus === 200 || res?.resStatus === 201) {
          setModal({
            ...modal,
            show: false
          })
          showSuccessMessage({ content: 'Berhasil Membuat Data Perusahaan' })
        } else {
          showErrorMessage({
            content: `${
              res.resData.message ??
              res.resData.errors[0].message ??
              'Gagal Membuat Data Perusahaan'
            }`
          })
        }
        refetch()
      } catch (error) {
        console.log(error)
        setModal({
          ...modal,
          show: false
        })
      }
    }
  }

  const deleteData = async (data) => {
    try {
      const res = await apiDelete({
        url: `user-service/admin/companies/${data?.id}`,
        token,
        onLogout
      })
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        setModal({
          ...modal,
          show: false
        })
        showSuccessMessage({ content: 'Berhasil Menghapus Data Perusahaan' })
      } else {
        showErrorMessage({
          content: `${
            res.resData.message ??
            res.resData.errors[0].message ??
            'Gagal Menghapus Data Perusahaan'
          }`
        })
      }
      refetch()
    } catch (error) {
      console.log(error)
      setModal({
        ...modal,
        show: false
      })
    }
  }

  const handleSearch = useCallback((value) => {
    setTableState((prevState) => ({
      ...prevState,
      keywords: value
    }))
  }, [])

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort_by: column.sortField,
      sort_order: sortDirection
    }))
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  const onChangeLocation = (value) => {
    setTableState((prevState) => ({
      ...prevState,
      city_id: value
    }))
  }

  return (
    <Layout style={{ padding: 24, background: '#F5F5F5', width: '100%' }}>
      <Breadcrumb style={{ margin: 8 }}>
        <Breadcrumb.Item className="font-inter font-semibold text-gray-700">
          Master Data
        </Breadcrumb.Item>
        <Breadcrumb.Item className="font-inter font-medium text-gray-500">
          Perusahaan
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content style={{ margin: 8 }}>
        <ModalUpdatePerusahaan
          isOpen={modal.show}
          data={modal.data}
          type={modal.type}
          onCancel={() => setModal({ ...modal, show: false })}
          onSubmit={saveData}
          onDelete={deleteData}
        />
        <CardContainer>
          <div className="flex flex-col w-full space-y-5">
            <HeaderContainer
              isPressAdd
              title="Perusahaan"
              titleAdd="Tambah Perusahaan"
              onPressAdd={() => setModal({ show: true, type: 'add', data: null })}
            />
            <div className="mb-5 flex justify-between">
              <div className="flex">
                <Select
                  showSearch
                  allowClear
                  placeholder="Lokasi"
                  className="custom-table-limit mr-3 w-full text-left [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-2 [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:flex-col [&.custom-table-limit>.ant-select-selector>.ant-select-selection-search]:justify-center"
                  style={{ width: '230px' }}
                  value={tableState?.city_id === '' ? null : tableState?.city_id}
                  filterOption={(input, option) => {
                    return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                  }}
                  onChange={(value) => onChangeLocation(value ?? '')}>
                  {selectCityData?.data?.resData?.data?.map((city) => (
                    <Select.Option key={city?.id?.toString()} value={city?.id}>
                      {city?.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <Input
                className="w-1/4 rounded-md"
                placeholder="Pencarian"
                prefix={<IcSearch className="text-gray-400" />}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            <Table
              columns={columns}
              data={tableState.listData}
              pending={isLoading}
              onSort={handleSort}
              totalRows={tableState?.totalRows}
              handlePerRowsChange={handlePerRowsChange}
              pageSize={tableState?.limit}
              page={tableState?.offset}
              onChangePrevPage={() => {
                if (tableState?.offset > 0) {
                  onChangePage(tableState?.offset - tableState?.limit)
                }
              }}
              onChangeNextPage={() => {
                if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                  onChangePage(tableState?.offset + tableState?.limit)
                }
              }}
            />
          </div>
        </CardContainer>
      </Content>
    </Layout>
  )
}

export default PerusahanPage
