import { menuData } from 'assets/data/menu-data'
import * as actionTypes from '../actionTypes'
import { apiGet, apiPost, urls } from 'api'
import { isRoleCompany, isRoleCostumer } from 'utils/isTypeRole'

export const login = (email, password) => {
  return async (dispatch) => {
    const res = await apiPost({
      url: `${urls.auth.user.prefix}/${urls.auth.user.login}`,
      data: {
        email: email,
        password: password
      }
    })
    if (!res) {
      throw {
        message: 'Email/Password salah'
      }
    }
    if (res?.resStatus !== 200) {
      if (res?.resStatus !== 201) {
        throw {
          message: res?.resData?.errors[0]?.message
        }
      }
    }

    // Check is role company / costumers
    const checkRole = await apiGet({
      url: `user-service/admin/users/${res?.resData?.data?.user_id}`,
      token: res?.resData?.data?.access_token
    })
    if (checkRole?.resStatus !== 200) {
      if (checkRole?.resStatus !== 201) {
        throw {
          message: checkRole?.resData?.errors[0]?.message
        }
      }
    } else {
      if (
        isRoleCompany(checkRole?.resData?.data?.role_id) ||
        isRoleCostumer(checkRole?.resData?.data?.role_id)
      ) {
        throw {
          message: "You don't have access."
        }
      }
    }

    if (res?.resData?.data?.is_need_mfa) {
      await apiGet({
        url: `${urls.auth.user.prefix}/auth/mfa/otp/send`,
        token: res?.resData?.data?.access_token
      })
      return res?.resData?.data
    }
    await dispatch(getProfile(res?.resData?.data?.access_token, res?.resData?.data?.user_id))
    await dispatch(authenticate(res?.resData?.data?.access_token))
    await dispatch(setAccessMenu(res?.resData?.data?.access_token))
  }
}

export const authenticate = (token) => {
  return { type: actionTypes.AUTHENTICATE, token: token }
}

export const getProfile = (paramToken, paramId) => {
  return async (dispatch, getState) => {
    const token = paramToken ? paramToken : getState().auth.token
    const userId = paramId ? paramId : getState().auth.profile.id
    const res = await apiGet({ url: `user-service/admin/users/${userId}`, token })
    if (res?.resStatus !== 200) {
      if (res?.resStatus !== 201) {
        throw {
          message: res?.resData?.errors[0]?.message
        }
      }
    }

    let name = ''
    name = res.resData?.data?.fullname ?? name
    name = res.resData?.data?.company_name ?? name
    let profile = {
      id: res.resData?.data?.id,
      email: res.resData?.data?.email,
      name: name,
      phone: res.resData?.data?.phone_number,
      image: null,
      address: res.resData?.data?.address,
      roleId: res.resData?.data?.role_id,
      roleName: res.resData?.data?.role_name,
      session: getState().auth.profile.roleId
        ? getState().auth.profile.roleId === res.resData?.data?.role_id
          ? true
          : false
        : true
    }
    await dispatch({
      type: actionTypes.GET_DATA_PROFILE,
      profile: profile
    })
  }
}

export const logout = () => {
  return async (dispatch, getState) => {
    let data = {
      roleId: getState().auth.profile.roleId,
      session: getState().auth.profile.session,
      pathMenu: getState().auth.profile.pathMenu
    }
    dispatch({ type: actionTypes.LOGOUT, auth: data })

    try {
      await dispatch({ type: actionTypes.LOGOUT, auth: data })
    } catch (error) {
      let message = error?.response?.data?.message ?? 'Internal Server Error'
      if (error?.response?.status === 401) {
        dispatch({ type: actionTypes.LOGOUT, auth: data })
      } else {
        if (Object.isExtensible(error?.response?.data?.message ?? message)) {
          let key = Object.keys(error?.response?.data?.message)[0]
          message = error?.response?.data?.message[key]
        }
      }
      throw new Error(message)
    }
  }
}

export const changeFirstLoggedIn = (isFirstLoggedIn) => {
  return {
    type: actionTypes.CHANGE_FIRST_LOGGEDIN,
    isFirstLoggedIn: isFirstLoggedIn
  }
}

export const setAccessMenu = (paramToken) => {
  return async (dispatch, getState) => {
    const token = paramToken ? paramToken : getState().auth.token
    const res = await apiGet({
      url: `user-service/auth/permission/all`,
      token
    })
    if (res?.resStatus !== 200) {
      if (res?.resStatus !== 201) {
        throw {
          message: res?.resData?.errors[0]?.message
        }
      }
    }
    let accessMenu = res?.resData?.data?.map((menu) => menu?.name)
    await dispatch({
      type: actionTypes.SET_ACCESS_MENU,
      accessMenu: accessMenu
    })

    // get index 0 sidebar menu path
    let accessMenuMatch = menuData.filter((item) => accessMenu.includes(item.name))[0]
    let acccessMenuMatchPath =
      accessMenuMatch?.children && accessMenuMatch?.children.length !== 0
        ? accessMenuMatch.children[0].href
        : accessMenuMatch.href

    // set path to profile state for consuming on route
    await dispatch({
      type: actionTypes.GET_DATA_PROFILE,
      profile: { ...getState().auth.profile, pathMenu: acccessMenuMatchPath }
    })

    // reset pathName to change landing page at MainRoute by Role & Session
    // if session true did not reset pathName, if false will be resetting pathName
    setTimeout(() => {
      if (!getState().auth.profile.session) {
        window.location.replace('/')
      }
    }, 200)
  }
}
