import React, { useEffect, useState } from 'react'
import { DatePicker, Input, Modal, Radio, Select, Typography } from 'antd'
import { validationInput } from 'helpers'
import moment from 'moment'
import { voucherLayananData } from 'assets/data/voucher-layanan'
import { disabledDatePicker, formatThousandsINTL, onlyNumbers, unformatCurrency } from 'utils'

const initialForms = {
  name: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  },
  kode: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  },
  layanan: {
    value: null,
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  },
  isPrivate: false,
  typeDiscountFor: 1,
  selectedSpareparts: [],
  selectedServices: [],
  minTransaksi: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true,
      isNumeric: true
    }
  },
  typePotongan: 1,
  potongan: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true,
      isNumeric: true
    }
  },
  maxPotongan: '',
  kuotaVoucher: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true,
      isNumeric: true
    }
  },
  kuotaUser: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true,
      isNumeric: true
    }
  },
  expiredDate: {
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  }
}

const ModalUpdateVoucher = ({ type, isOpen, onCancel, data, onSubmit, onDelete }) => {
  const [forms, setForms] = useState(initialForms)

  useEffect(() => {
    if (isOpen) {
      if (type === 'edit') {
        onChangeInput(data?.name, 'name')
        onChangeInput(data?.voucher_code, 'kode')
        // if(data?.voucher_type) {
        //   let layanan = voucherLayananData.find(item => item.name.toLowerCase() === data?.voucher_type?.toLowerCase())
        //   onChangeInput(layanan.id, 'layanan');
        // }
        onChangeInput(data?.is_private, 'isPrivate')
        onChangeInput(data?.discount_for, 'typeDiscountFor')
        onChangeInput(data?.discount_type, 'typePotongan')
        // onChangeInput(data?.discount, 'potongan')
        onChangeInput(data?.discount_integer, 'potongan')
        onChangeInput(
          data?.max_discount ? (data?.max_discount !== '-' ? data?.max_discount : '') : '',
          'maxPotongan'
        )
        onChangeInput(data?.voucher_quota, 'kuotaVoucher')
        onChangeInput(data?.user_quota, 'kuotaUser')
        onChangeInput(
          data?.expiry_date
            ? moment(new Date(data?.expiry_date?.split('/').join('-')), 'DD/MM/YYYY')
            : '',
          'expiredDate'
        )
        onChangeInput(data?.min_transaction, 'minTransaksi')
      } else {
        setForms(initialForms)
      }
    }
  }, [isOpen])

  const onOk = () => {
    if (
      !forms?.name.valid ||
      !forms?.kode.valid ||
      !forms?.typePotongan ||
      !forms?.typeDiscountFor ||
      // || !forms?.layanan.valid
      !forms?.kuotaUser.valid ||
      !forms?.potongan.valid ||
      !forms?.kuotaVoucher.valid ||
      !forms?.expiredDate.valid ||
      !forms?.minTransaksi.valid
    ) {
      alert('error')
      return
    }
    onSubmit({
      id: data?.id,
      name: forms?.name.value,
      voucher_code: forms?.kode.value,
      // voucher_type: forms?.layanan.value,
      is_private: forms?.isPrivate,
      discount_for: forms?.typeDiscountFor,
      discount_type: forms?.typePotongan,
      discount: forms?.potongan.value,
      max_discount: forms?.maxPotongan,
      min_transaction: forms?.minTransaksi.value,
      voucher_quota: forms?.kuotaVoucher.value,
      user_quota: forms?.kuotaUser.value,
      expiry_date: forms?.expiredDate.value
    })
  }

  if (type === 'delete') {
    return (
      <Modal
        centered={true}
        title="Hapus Voucher"
        open={isOpen}
        onOk={() => onDelete(data)}
        onCancel={onCancel}
        okText="Confirm"
        okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
        cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}>
        <Typography.Text className="font-medium">
          {`Apakah Anda yakin ingin menghapus voucher ${data?.name} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
        </Typography.Text>
      </Modal>
    )
  }

  const onChangeInput = (value, identifier) => {
    if (forms[identifier]?.validation) {
      let { valid, messageError } = validationInput(value, forms[identifier]?.validation)
      setForms((prevState) => {
        return {
          ...prevState,
          [identifier]: {
            ...prevState[identifier],
            touched: true,
            value: value,
            valid: valid,
            messageError: messageError
          }
        }
      })
    } else {
      setForms((prevState) => {
        return {
          ...prevState,
          [identifier]: value
        }
      })
    }
  }

  return (
    <Modal
      centered={true}
      title={type === 'add' ? 'Tambah Voucher' : 'Edit Voucher'}
      open={isOpen}
      onOk={onOk}
      onCancel={onCancel}
      okText={type === 'add' ? 'Tambah Voucher' : 'Edit Voucher'}
      okButtonProps={{
        className: 'bg-primary-500',
        type: 'danger',
        disabled:
          !forms?.name.valid ||
          !forms?.kode.valid ||
          !forms?.typePotongan ||
          !forms?.typeDiscountFor ||
          !forms?.kuotaUser.valid ||
          !forms?.potongan.valid ||
          !forms?.kuotaVoucher.valid ||
          !forms?.expiredDate.valid ||
          !forms?.minTransaksi.valid
      }}
      cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Judul
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          className={`rounded py-2 ${
            !forms?.name.valid && forms?.name.touched && 'border-red-500 border border-solid'
          }`}
          placeholder="Masukan Judul Voucher"
          value={forms?.name.value}
          onChange={(e) => onChangeInput(e.target.value, 'name')}
        />
        {!forms?.name.valid && forms?.name.touched && (
          <span className="text-[red]">{forms?.name.messageError}</span>
        )}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Kode Voucher
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          className={`rounded py-2 ${
            !forms?.kode.valid && forms?.kode.touched && 'border-red-500 border border-solid'
          }`}
          placeholder="Masukan Kode Voucher"
          value={forms?.kode.value}
          onChange={(e) => onChangeInput(e.target.value, 'kode')}
        />
        {!forms?.kode.valid && forms?.kode.touched && (
          <span className="text-[red]">{forms?.kode.messageError}</span>
        )}
      </div>
      {/* <div className='mb-4'>
        <Typography.Text className="font-medium">
          Layanan
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Select
          className="custom-table-limit"
          style={{ width: '100%' }} 
          placeholder="Pilih Layanan"
          value={forms?.layanan.value? forms?.layanan.value : null}
          onChange={(value) => onChangeInput(value, 'layanan')}
        >
          {voucherLayananData?.map(voucher => (
            <Select.Option key={voucher?.id?.toString()} value={voucher?.id}>{voucher?.name}</Select.Option>
          ))}
        </Select>
        {!forms?.layanan.valid && forms?.layanan.touched && <span className="text-[red]">{forms?.layanan.messageError}</span>}
      </div> */}
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Jenis Voucher
          <span className="text-[red]">*</span>
        </Typography.Text>
        <div className="my-1">
          <Radio.Group
            name="radiogroup"
            className="flex flex-row space-x-1 items-center justify-start"
            onChange={(e) => {
              onChangeInput(e.target.value, 'isPrivate')
            }}
            value={forms?.isPrivate}>
            <Radio className="custom-radio-btn" value={false}>
              Public
            </Radio>
            <Radio className="custom-radio-btn" value={true}>
              Private
            </Radio>
          </Radio.Group>
        </div>
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Potongan Untuk
          <span className="text-[red]">*</span>
        </Typography.Text>
        <div className="my-1">
          <Radio.Group
            name="radiogroup"
            className="flex flex-row space-x-1 items-center justify-start"
            onChange={(e) => {
              onChangeInput(e.target.value, 'typeDiscountFor')
            }}
            value={forms?.typeDiscountFor}>
            <Radio className="custom-radio-btn" value={1}>
              Harga Jasa
            </Radio>
            <Radio className="custom-radio-btn" value={2}>
              Harga Sparepart
            </Radio>
            <Radio className="custom-radio-btn" value={3}>
              Harga Jasa dan Sparepart
            </Radio>
          </Radio.Group>
        </div>
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Minimum Transaksi
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          className={`rounded py-2 ${
            !forms?.minTransaksi.valid &&
            forms?.minTransaksi.touched &&
            'border-red-500 border border-solid'
          }`}
          placeholder="Masukan Minimum Transaksi"
          prefix={<span className="text-gray-400">Rp </span>}
          value={
            forms?.minTransaksi.value === '' ? '' : formatThousandsINTL(forms.minTransaksi.value)
          }
          onChange={(e) => {
            if (onlyNumbers(unformatCurrency(e.target.value))) {
              if (e.target.value !== '') {
                onChangeInput(unformatCurrency(e.target.value), 'minTransaksi')
              } else {
                onChangeInput(e.target.value, 'minTransaksi')
              }
            }
            if (e.target.value === '') {
              onChangeInput(e.target.value, 'minTransaksi')
            }
          }}
        />
        {!forms?.minTransaksi.valid && forms?.minTransaksi.touched && (
          <span className="text-[red]">{forms?.minTransaksi.messageError}</span>
        )}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Potongan Harga
          <span className="text-[red]">*</span>
        </Typography.Text>
        <div className="my-1">
          <Radio.Group
            name="radiogroup"
            onChange={(e) => {
              onChangeInput(e.target.value, 'typePotongan')
              if (
                e.target.value === 2 &&
                forms?.potongan?.value &&
                parseInt(forms?.potongan?.value) > 100
              ) {
                onChangeInput('100', 'potongan')
              }
            }}
            value={forms?.typePotongan}>
            <Radio className="custom-radio-btn" value={1}>
              Rupiah
            </Radio>
            <Radio className="custom-radio-btn" value={2}>
              Percent
            </Radio>
          </Radio.Group>
        </div>
        <Input
          type={`${forms.typePotongan === 2 ? 'number' : ''}`}
          className={`rounded py-2 ${
            !forms?.potongan.valid &&
            forms?.potongan.touched &&
            'border-red-500 border border-solid'
          }`}
          placeholder="Masukan Potongan"
          prefix={
            <span
              className={`text-gray-400 ${
                forms?.typePotongan && forms?.typePotongan === 1 ? '' : 'hidden'
              }`}>
              Rp{' '}
            </span>
          }
          suffix={
            <span
              className={`text-gray-400 ${
                forms?.typePotongan && forms?.typePotongan === 2 ? '' : 'hidden'
              }`}>
              {' '}
              %
            </span>
          }
          value={
            forms.typePotongan === 1
              ? forms.potongan.value === ''
                ? ''
                : formatThousandsINTL(forms.potongan.value)
              : forms.potongan.value
          }
          onChange={(e) => {
            if (forms.typePotongan === 1) {
              if (onlyNumbers(unformatCurrency(e.target.value))) {
                if (e.target.value !== '') {
                  onChangeInput(unformatCurrency(e.target.value), 'potongan')
                } else {
                  onChangeInput(e.target.value, 'potongan')
                }
              }
              if (e.target.value === '') {
                onChangeInput(e.target.value, 'potongan')
              }
            } else {
              onChangeInput(e.target.value, 'potongan')
            }
          }}
        />
        {!forms?.potongan.valid && forms?.potongan.touched && (
          <span className="text-[red]">{forms?.potongan.messageError}</span>
        )}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">Maksimal Potongan</Typography.Text>
        <Input
          className="rounded py-2"
          placeholder="Masukan Maksimal Potongan"
          prefix={<span className="text-gray-400">Rp </span>}
          value={forms?.maxPotongan === '' ? '' : formatThousandsINTL(forms.maxPotongan)}
          onChange={(e) => {
            if (onlyNumbers(unformatCurrency(e.target.value))) {
              if (e.target.value !== '') {
                onChangeInput(unformatCurrency(e.target.value), 'maxPotongan')
              } else {
                onChangeInput(e.target.value, 'maxPotongan')
              }
            }
            if (e.target.value === '') {
              onChangeInput(e.target.value, 'maxPotongan')
            }
          }}
        />
      </div>
      <div className="mb-4 flex gap-x-4">
        <div className="flex-1">
          <Typography.Text className="font-medium">
            Kuota Voucher
            <span className="text-[red]">*</span>
          </Typography.Text>
          <Input
            type="number"
            className={`rounded py-2 ${
              !forms?.kuotaVoucher.valid &&
              forms?.kuotaVoucher.touched &&
              'border-red-500 border border-solid'
            }`}
            placeholder="Masukan Kuota Voucher"
            value={forms?.kuotaVoucher.value}
            onChange={(e) => onChangeInput(e.target.value, 'kuotaVoucher')}
          />
          {!forms?.kuotaVoucher.valid && forms?.kuotaVoucher.touched && (
            <span className="text-[red]">{forms?.kuotaVoucher.messageError}</span>
          )}
        </div>
        <div className="flex-1">
          <Typography.Text className="font-medium">
            Kuota per User
            <span className="text-[red]">*</span>
          </Typography.Text>
          <Input
            type="number"
            className={`rounded py-2 ${
              !forms?.kuotaUser.valid &&
              forms?.kuotaUser.touched &&
              'border-red-500 border border-solid'
            }`}
            placeholder="Masukan Kuota per User"
            value={forms?.kuotaUser.value}
            onChange={(e) => onChangeInput(e.target.value, 'kuotaUser')}
          />
          {!forms?.kuotaUser.valid && forms?.kuotaUser.touched && (
            <span className="text-[red]">{forms?.kuotaUser.messageError}</span>
          )}
        </div>
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Expired Date
          <span className="text-[red]">*</span>
        </Typography.Text>
        <DatePicker
          value={forms?.expiredDate.value}
          onChange={(value) => onChangeInput(value, 'expiredDate')}
          className="custom-date-picker w-full py-2"
          popupClassName="custom-date-picker-dropdown"
          format={['DD/MM/YYYY']}
          disabledDate={disabledDatePicker}
        />
        {!forms?.expiredDate.valid && forms?.expiredDate.touched && (
          <span className="text-[red]">{forms?.expiredDate.messageError}</span>
        )}
      </div>
    </Modal>
  )
}

export default ModalUpdateVoucher
