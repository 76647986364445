import { Modal } from 'antd'
import { useEffect } from 'react'

function ModalLayout({
  headerTitle,
  children,
  onCancel,
  openModal,
  className,
  loading = false,
  closeable = true
}) {
  useEffect(() => {
    openModal ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'unset')
    return () => (document.body.style.overflow = 'unset')
  }, [openModal])
  return (
    <Modal
      title={<span className="text-xl-medium text-gray-900">{headerTitle}</span>}
      open={openModal}
      wrapClassName=""
      footer={null}
      width=""
      closable={closeable}
      className={`overflow-y-hidden ${className || `max-w`}`}
      zIndex={999}
      onCancel={onCancel}
      maskClosable={false}
      centered
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ disabled: loading }}>
      {children}
    </Modal>
  )
}

export default ModalLayout
