import { CustomButton } from 'components'
import ModalLayout from 'layouts/modal-layout'
import React from 'react'

const ModalDeleteRekomendasiServis = ({
  isOpen,
  onClose,
  generalService,
  onDelete,
  serviceType
}) => {
  return (
    <ModalLayout
      className="min-w-[27%] max-w-[27%] rounded-md"
      headerTitle="Batalkan Rekomendasi Servis"
      onCancel={() => {
        onClose()
      }}
      openModal={isOpen}>
      <div className="grid grid-cols-1 gap-2 w-full h-full">
        <p className="text-md-medium text-gray-500">
          Apakah Anda yakin ingin membatalkan rekomendasi servis{' '}
          <span className="font-bold">
            {serviceType?.includes('packet')
              ? generalService?.packet_name
              : generalService?.general_service_name}{' '}
          </span>
          ini? Data yang Anda hapus tidak dapat dipulihkan kembali.
        </p>

        <div className="flex flex-row w-full items-center justify-end space-x-4">
          <CustomButton
            type="plain"
            onClick={() => {
              onClose()
            }}>
            Cancel
          </CustomButton>
          <CustomButton
            onClick={() => {
              onDelete()
              onClose()
            }}>
            Confirm
          </CustomButton>
        </div>
      </div>
    </ModalLayout>
  )
}

export default ModalDeleteRekomendasiServis
