import { Input, Spin, Tooltip } from 'antd'
import { CardContainer, CustomButton } from 'components'
import { CarouselMultipleLayout } from 'layouts/CarouselMultipleLayout'
import React, { useEffect, useRef, useState } from 'react'
import CardPacketService from './card-packet-service'
import CardService from './card-service'
import { MagnifyingIcon } from 'assets/icons'

const CardPilihLayananSparepart = ({
  services,
  handleGeneralService,
  handlePacketService,
  changeKeywordHandler,
  keyword,
  handleSelectedCategory,
  selectedCategory,
  serviceCategories,
  handleTampilLebihBanyak,
  servicesIsFetching = true,
  selectedGeneralServiceIds,
  selectedPacketIds,
  isAddServisBtnDisabled
}) => {
  const refSearchBar = useRef(null)
  const [isTambahBanyak, setIsTambahBanyak] = useState(false)
  useEffect(() => {
    if (!servicesIsFetching && !isTambahBanyak) {
      refSearchBar.current.focus()
      setIsTambahBanyak(false)
    }
  }, [servicesIsFetching, isTambahBanyak])
  return (
    <CardContainer className="flex flex-col gap-6 col-span-8">
      <span className="text-gray-900 display-xs-semibold border border-solid border-x-0 border-t-0 border-b-1 pb-5 border-gray-300">
        Layanan Servis
      </span>
      <CarouselMultipleLayout
        slideToShow={3}
        slidesToScroll={3}
        arrowClassIcon="stroke-gray-500"
        arrowClassContainer="bg-transparent"
        totalItem={serviceCategories?.length}
        className="bg-white py-2 pb-1 rounded-[4px] overflow-hidden px-6 ">
        {serviceCategories?.length > 0
          ? serviceCategories?.map((data) => (
              <Tooltip
                title={data.name}
                onClick={handleSelectedCategory.bind(this, data)}
                key={data.id}
                className={`cursor-pointer text-center h-full text-sm-medium xl:text-md-medium whitespace-nowrap line-clamp-1 px-2 ${
                  selectedCategory?.name === data.name
                    ? 'border-b-2 border border-solid border-x-0 border-y-0 border-primary-500 text-primary-500'
                    : 'text-gray-500'
                }`}>
                {data.name}
              </Tooltip>
            ))
          : null}
      </CarouselMultipleLayout>

      <CardContainer className={'!bg-gray-100'}>
        <div className="grid grid-cols-1 gap-5 ">
          <Input
            ref={refSearchBar}
            disabled={servicesIsFetching && services.length === 0}
            id="searchKeywords"
            name="keywords"
            size="medium"
            value={keyword}
            className="text-md-normal text-gray-500 px-[14px] hover:border-gray-200 focus:border-gray-200"
            prefix={<MagnifyingIcon className={'mr-1'} />}
            placeholder="Search"
            onChange={(e) => {
              setIsTambahBanyak(false)
              changeKeywordHandler(e)
            }}
          />
          {services?.length === 0 ? (
            <div className="flex w-full h-full justify-center items-center">
              {servicesIsFetching ? <Spin size="large" /> : 'No Data'}
            </div>
          ) : (
            <>
              {services?.map((data) => {
                let passed = true
                // CHECKING PACKET IDS
                if (data?.type === 'PACKET') {
                  if (!selectedPacketIds[data.id]) {
                    // CHECKING GENERAL_SERVICE IDS
                    for (let i = 0; i < data.general_services.length; i++) {
                      if (selectedGeneralServiceIds[data.general_services[i].id]) {
                        passed = false
                      }
                    }
                  } else {
                    passed = false
                  }
                  if (passed) {
                    return (
                      <CardPacketService
                        handlePacketService={handlePacketService.bind(
                          this,
                          data.id,
                          selectedCategory.id
                        )}
                        key={`${selectedCategory?.name}-PACKET-${data.id}`}
                        data={data}
                      />
                    )
                  }
                } else if (data?.type === 'GENERAL' && selectedGeneralServiceIds[data.id]) {
                  null
                } else {
                  return (
                    <CardService
                      handleGeneralService={handleGeneralService.bind(this, selectedCategory.id)}
                      key={`${selectedCategory?.name}-GENERAL-${data.id}`}
                      data={data}
                    />
                  )
                }
              })}
              {isAddServisBtnDisabled ? null : (
                <Spin spinning={servicesIsFetching}>
                  <div className="grid grid-cols-1 w-full">
                    <CustomButton
                      onClick={() => {
                        setIsTambahBanyak(true)
                        handleTampilLebihBanyak()
                      }}>
                      Tampilkan Lebih Banyak
                    </CustomButton>
                  </div>
                </Spin>
              )}
            </>
          )}
        </div>
      </CardContainer>
    </CardContainer>
  )
}

export default CardPilihLayananSparepart
