import React from 'react'
import { IcFacebook, IcInstagram, IcLinkedIn, IcTwitter } from 'assets/icons'
import BirdMobilLogo from 'assets/images/Birdmobil-logo.png'
import moment from 'moment'
import 'moment/locale/en-gb'
import { formatCurrency } from 'utils'

const InvoiceSingleCard = React.forwardRef(({ data }, ref) => {
  const fontInvoiceFieldStyle = 'font-medium text-gray-500 text-left'
  const fontInvoiceValueStyle = 'font-medium text-gray-700 text-right'
  const fontItemTitleStyle = 'font-semibold text-gray-900'
  const fontItemValueStyle = 'font-normal text-gray-700 text-sm'

  return (
    <div
      ref={ref}
      className="flex min-h-screen overflow-x-hidden"
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#FFFFFF'
      }}>
      <div className="flex flex-col w-full h-full justify-between ">
        <div className="flex flex-col">
          <div
            key="header-content"
            className="flex flex-row items-center justify-between border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
            <div className="flex flex-row items-center justify-center space-x-1 bg-white hover:cursor-pointer">
              <img width={30} height={30} src={BirdMobilLogo} alt="" />
              <span className="font-semibold text-gray-900">Birdmobil</span>
            </div>
            <span className="font-normal text-gray-500">
              {moment(data.created_at, 'YYYY-MM-DD').format('LL')}
            </span>
          </div>
          <div key="invoice-information-content" className="grid grid-flow-col pt-8">
            <div className="grid grid-rows-4 gap-2">
              <span className={fontInvoiceFieldStyle}>Invoice No.</span>
              <span className={fontInvoiceFieldStyle}>Account</span>
              <span className={fontInvoiceFieldStyle}>Unit</span>
              <span className={fontInvoiceFieldStyle}>Layanan</span>
            </div>
            <div className="grid grid-rows-4 gap-2">
              <span className={fontInvoiceValueStyle}>{data.invoice_number}</span>
              <span className={fontInvoiceValueStyle}>{data.account_name}</span>
              <span className={fontInvoiceValueStyle}>{data.unit_name}</span>
              <span className={fontInvoiceValueStyle}>{data.service_type}</span>
            </div>
          </div>
          <div key="items-content" className="flex flex-col py-10">
            <div className="flex flex-row border border-solid border-b-1 border-x-0 border-t-0 pb-3 border-b-gray-300">
              <div className="min-w-[50%] text-left">
                <span className={fontItemTitleStyle}>Item</span>
              </div>
              <div className="min-w-[20%] text-center">
                <span className={fontItemTitleStyle}>Price</span>
              </div>
              <div className="min-w-[10%] text-center">
                <span className={fontItemTitleStyle}>Qty</span>
              </div>
              <div className="min-w-[20%] text-center">
                <span className={fontItemTitleStyle}>Total</span>
              </div>
            </div>
            <div className="flex flex-row pt-3">
              <div className="min-w-[50%] text-left">
                <span className={fontItemValueStyle}>{data.item_name}</span>
              </div>
              <div className="min-w-[20%] text-center">
                <span className={fontItemValueStyle}>{formatCurrency(data.subtotal_price)}</span>
              </div>
              <div className="min-w-[10%] text-center">
                <span className={fontItemValueStyle}>{1}</span>
              </div>
              <div className="min-w-[20%] text-right">
                <span className={fontItemValueStyle}>{formatCurrency(data.subtotal_price)}</span>
              </div>
            </div>
            <div className="grid grid-flow-col items-center justify-between pt-8">
              <div className="flex flex-col text-left space-y-5">
                <span className={fontItemTitleStyle}>Subtotal:</span>
                <div className="flex flex-col space-y-2">
                  <span className={fontItemTitleStyle}>Biaya Admin:</span>
                  <span className={fontItemTitleStyle}>Pajak:</span>
                </div>
                <span className="font-semibold text-primary-500 text-xl-semibold">Total:</span>
              </div>
              <div className="flex flex-col text-right space-y-5">
                <span className={fontItemTitleStyle}>{formatCurrency(data.subtotal_price)}</span>
                <div className="flex flex-col space-y-2">
                  <span className={fontItemTitleStyle}>{formatCurrency(data.admin_price)}</span>
                  <span className={fontItemTitleStyle}>
                    {data.tax_fee ? formatCurrency(data.tax_fee) : formatCurrency(0)}
                  </span>
                </div>
                <span className="font-semibold text-xl-semibold text-primary-500">
                  {formatCurrency(data.total_price)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div key="footer" className="flex flex-col space-y-5">
          <span className="font-medium text-gray-700">Thank you for your business</span>
          <div className="flex flex-col space-y-1 border border-solid border-b-1 border-x-0 border-t-0 pb-8 border-b-gray-300">
            <span className="font-medium text-gray-700">{`${data.workshop_name ?? ''}`}</span>
            <span className="font-normal text-gray-500">{data?.workshop_address}</span>
          </div>
          <div className="flex flex-row items-center justify-around pt-5">
            <IcTwitter className="text-gray-400" size={23} />
            <IcLinkedIn className="text-gray-400" size={23} />
            <IcFacebook className="text-gray-400" size={23} />
            <IcInstagram className="text-gray-400" size={23} />
          </div>
        </div>
      </div>
    </div>
  )
})

export default InvoiceSingleCard
