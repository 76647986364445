const IcRefreshCcw = ({ className, size = 20 }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      stroke="#344054"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_16029_5654)">
        <path
          d="M0.833984 3.33136V8.33136M0.833984 8.33136H5.83398M0.833984 8.33136L4.70065 4.69803C5.59627 3.80196 6.70429 3.14737 7.92132 2.79534C9.13835 2.44331 10.4247 2.40532 11.6604 2.68491C12.8961 2.9645 14.0408 3.55256 14.9878 4.39421C15.9347 5.23586 16.653 6.30368 17.0756 7.49803M19.1673 16.6647V11.6647M19.1673 11.6647H14.1673M19.1673 11.6647L15.3007 15.298C14.405 16.1941 13.297 16.8487 12.08 17.2007C10.8629 17.5527 9.57657 17.5907 8.34089 17.3111C7.10521 17.0316 5.96048 16.4435 5.01354 15.6018C4.06659 14.7602 3.34829 13.6924 2.92565 12.498"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16029_5654">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IcRefreshCcw
