import { apiPut } from 'api'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { showErrorMessage, showSuccessMessage } from 'utils'

const useCartService = (detailServiceId) => {
  const token = useSelector((state) => state.auth.token)
  const router = useHistory()
  const [cartList, setCartList] = useState(null)
  const [cartIsLoading, setCartIsLoading] = useState(false)
  const simulateCreateService = useMutation({
    mutationFn: async (dataSend) => {
      const res = await apiPut({
        token: token,
        url: `servis-service/admin/bookings/${detailServiceId}/update-service`,
        data: dataSend
      })
      return res
    },
    onSuccess: (res) => {
      if (res.resStatus >= 400) {
        setCartIsLoading(false)
        return showErrorMessage({
          content: res?.resData?.errors[0]?.message
        })
      }
      const data = res?.resData?.data
      setCartList(data)
      setCartIsLoading(false)
    },
    retry: 3,
    retryDelay: 800
  })
  const submitRecommendationService = useMutation({
    mutationFn: async (dataSend) => {
      const res = await apiPut({
        token: token,
        url: `servis-service/admin/bookings/${detailServiceId}/update-service`,
        data: dataSend
      })
      return res
    },
    onSuccess: (res) => {
      if (res.resStatus >= 400) {
        return showErrorMessage({ content: 'Terjadi kesalahan, silahkan coba lagi nanti' })
      }
      showSuccessMessage({ content: 'Submit rekomendasi servis berhasil' })
      router.replace(`/services/jadwal/detail/${detailServiceId}`)
    }
  })

  function handleSimulateCartService(servicePackets, servicesGenerals, unitId, isSubmit = false) {
    const newServicePackets = []
    servicePackets?.length > 0
      ? servicePackets?.map((packet) => {
          const newGeneralServices = []
          packet?.general_services?.map((service) => {
            const newGeneralObj = service?.general_service?.is_free
              ? {
                  general_service_id: service?.general_service?.id ?? service?.general_service_id,
                  sparepart_id: service?.sparepart?.id ?? service?.sparepart_id,
                  quantity: 0
                }
              : {
                  general_service_id: service?.general_service?.id ?? service?.general_service_id,
                  sparepart_id: service?.sparepart?.id ?? service?.sparepart_id,
                  quantity: service?.sparepart?.quantity ?? service?.quantity
                }
            newGeneralServices.push(newGeneralObj)
          })

          const newObj = {
            service_id: packet?.service_id,
            packet_id: packet?.packet_id,
            general_services: newGeneralServices
          }
          newServicePackets.push(newObj)
        })
      : null

    // HANDLE OBJECT SERVICE_GENERALS FOR BODY BOOKING API
    // DONE ADDED QUANTITY TO BODY OBJ
    const newServiceGenerals = []
    servicesGenerals?.length > 0
      ? servicesGenerals?.map((service) => {
          const newGeneralService = service?.is_free
            ? {
                service_id: service?.service_id,
                general_service_id: service?.general_service?.id ?? service?.general_service_id,
                sparepart_id: service?.sparepart.id ?? service?.sparepart_id,
                quantity: 0
              }
            : {
                service_id: service?.service_id,
                general_service_id: service?.general_service?.id ?? service?.general_service_id,
                sparepart_id: service?.sparepart?.id ?? service?.sparepart_id,
                quantity: service?.sparepart?.quantity ?? service?.quantity
              }
          newServiceGenerals.push(newGeneralService)
        })
      : null

    const dataSend = {
      unit_id: unitId,
      service_packets: newServicePackets,
      service_generals: newServiceGenerals,
      is_simulation: isSubmit ? false : true
    }
    setCartIsLoading(true)
    return isSubmit
      ? submitRecommendationService.mutate(dataSend)
      : simulateCreateService.mutate(dataSend)
  }
  function deleteCartList() {
    setCartList(null)
  }
  return {
    cartList,
    handleSimulateCartService,
    deleteCartList,
    cartIsLoading: simulateCreateService?.isLoading || cartIsLoading
  }
}

export default useCartService
