import { Divider } from 'antd'
import { CardContainer } from 'components'
import React from 'react'
import { formatCurrency } from 'utils'
const fontItemTitleStyle = 'font-semibold text-md-semibold text-gray-900'
const fontItemValueStyle = 'font-normal text-gray-700 text-md-regular'
const colorList = [
  {
    id: 0,
    label: 'Tambah',
    color: 'bg-success-500'
  },
  {
    id: 1,
    label: 'Edit',
    color: 'bg-warning-500'
  },
  {
    id: 2,
    label: 'Hapus',
    color: 'bg-danger-500'
  }
]
const colorByStatus = {
  DEFAULT: 'text-gray-700',
  WAITING_APPROVAL_ADD: 'text-success-500',
  APPROVED: 'text-gray-700',
  WAITING_APPROVAL_EDIT: 'text-warning-500',
  WAITING_APPROVAL_DELETE: 'text-danger-500'
}
const sumVoucherDiscount = (voucher) => {
  let sum = 0
  voucher?.map((voc) => {
    sum += voc?.price_total
  })

  return sum
}
const CardReviewServis = ({ data = null }) => {
  let dataSparepart = []
  let dataLayanan = []
  data?.general_services?.forEach((item) => {
    if (item?.sparepart_name !== '') {
      dataSparepart.push({
        sparepart_name: item.sparepart_name,
        status: item.status,
        previous_quantity: item.previous_quantity,
        price: item.sparepart_price * item.quantity,
        quantity: item.quantity,
        total: item.sparepart_price_total,
        discount: sumVoucherDiscount(item?.voucher_sparepart_detail)
      })
    }
    dataLayanan.push({
      general_service_name: item.general_service_name,
      price: item.class_price + item.service_price,
      status: item.status,
      quantity: 0,
      total: item.service_price_total,
      discount: sumVoucherDiscount(item?.voucher_service_detail)
    })
  })
  data?.packets?.forEach((packet) =>
    packet?.spareparts?.forEach((item) => {
      if (item?.sparepart_name !== '') {
        dataSparepart.push({
          sparepart_name: item.sparepart_name,
          status: item?.status,
          previous_quantity: item?.previous_quantity,
          price: item.sparepart_price * item.quantity,
          quantity: item.quantity,
          total: item.sparepart_price_total,
          discounts: item?.voucher_sparepart_detail !== null ? item?.voucher_sparepart_detail : []
        })
      }
      dataLayanan.push({
        general_service_name: item.general_service_name,
        price: item.class_price + item.service_price,
        status: item?.status,
        quantity: 0,
        total: item.service_price_total,
        discounts: item?.voucher_service_detail !== null ? item?.voucher_service_detail : []
      })
    })
  )
  return (
    <CardContainer>
      <div className="flex justify-between items-center border border-solid border-x-0 border-t-0 border-b-1 pb-5 border-b-gray-300">
        <h1 className="text-gray-900 display-xs-semibold h-full !mb-0">Daftar Servis</h1>
        <div className="flex gap-5">
          {colorList.map((item) => (
            <div key={item.id} className="flex gap-1 text-sm-medium">
              <div className={`${item.color} w-4 h-4 rounded-full`}></div>
              <span>{item.label}</span>
            </div>
          ))}
        </div>
      </div>
      <div>
        <React.Fragment>
          <div
            className={`border border-solid border-b-0 border-x-0 border-t-0 pt-3 border-b-gray-300 gap-1 grid grid-cols-11`}>
            <div className={`text-left col-span-4`}>
              <span className={fontItemTitleStyle}>Item</span>
            </div>
            <div className="text-left">
              <span className={fontItemTitleStyle}>Qty</span>
            </div>
            <div className="text-left col-span-2">
              <span className={fontItemTitleStyle}>Harga</span>
            </div>
            <div className="text-left col-span-2">
              <span className={fontItemTitleStyle}>Discount</span>
            </div>
            <div className="text-right col-span-2">
              <span className={fontItemTitleStyle}>Total</span>
            </div>
          </div>
          {dataSparepart?.length > 0 ? (
            <>
              <div className={`pt-3 ${fontItemTitleStyle}`}>Sparepart</div>
              {dataSparepart?.map((sparepart, index) => (
                <div
                  key={index}
                  className={`pt-1 grid grid-cols-11 gap-1 font-normal text-md-regular ${
                    colorByStatus[sparepart?.status]
                  }`}>
                  <div className={`text-left col-span-4`}>
                    <span>{sparepart?.sparepart_name}</span>
                  </div>
                  <div className=" text-left">
                    <span>{sparepart?.quantity ? `x${sparepart?.quantity}` : ''}</span>
                  </div>
                  <div className=" text-left col-span-2">
                    <span>{formatCurrency(sparepart?.price)}</span>
                  </div>
                  <div className=" text-left col-span-2">
                    <span className={' text-primary-500'}>
                      {sparepart?.discount ? `- ${formatCurrency(sparepart?.discount)}` : ''}
                    </span>
                  </div>
                  <div className=" text-right col-span-2">
                    <span>{formatCurrency(sparepart?.total)}</span>
                  </div>
                </div>
              ))}
              <div className="grid grid-cols-5">
                {data?.sparepart_general_voucher_discount_total ? (
                  <>
                    <Divider className="m-0 my-2 col-span-5" />
                    <span className={`${fontItemValueStyle} col-span-2`}>Voucher Sparepart</span>
                    <span
                      className={`${fontItemValueStyle} text-right text-primary-500 col-span-3`}>
                      - {formatCurrency(data?.sparepart_general_voucher_discount_total ?? 0)}
                    </span>
                  </>
                ) : null}
                <>
                  <Divider className="m-0 my-2 col-span-5" />
                  <span className={`${fontItemTitleStyle} col-span-2`} colSpan={4}>
                    Subtotal Sparepart
                  </span>
                  <span className={`${fontItemTitleStyle} text-right col-span-3`}>
                    {formatCurrency(data?.sparepart_total ?? 0)}
                  </span>
                </>
              </div>
            </>
          ) : null}
          {/* LAYANAN LIST */}
          {dataLayanan?.length > 0 ? (
            <>
              <div className={`pt-3 ${fontItemTitleStyle}`}>Layanan</div>
              {dataLayanan?.map((service, index) => (
                <div
                  key={index}
                  className={`pt-1 grid grid-cols-11 gap-1 font-normal text-md-regular ${
                    colorByStatus[service?.status] ?? 'text-gray-700'
                  }`}>
                  <div className={`text-left col-span-4`}>
                    <span className={``}>{service?.general_service_name}</span>
                  </div>
                  <div className=" text-left">
                    <span>{service?.quantity ? `x${service?.quantity}` : ''}</span>
                  </div>
                  <div className=" text-left col-span-2">
                    <span>{formatCurrency(service?.price)}</span>
                  </div>
                  <div className=" text-left col-span-2">
                    <span className={' text-primary-500'}>
                      {service?.discount ? `- ${formatCurrency(service?.discount)}` : ''}
                    </span>
                  </div>
                  <div className="text-right col-span-2">
                    <span>{formatCurrency(service?.total)}</span>
                  </div>
                </div>
              ))}

              <div className="grid grid-cols-5">
                {data?.service_general_voucher_discount_total ? (
                  <>
                    <Divider className="m-0 my-2 col-span-5" />
                    <span className={`${fontItemValueStyle} col-span-2`}>Voucher Layanan</span>
                    <span
                      className={`${fontItemValueStyle} text-right text-primary-500 col-span-3`}>
                      - {formatCurrency(data?.service_general_voucher_discount_total ?? 0)}
                    </span>
                  </>
                ) : null}
                <>
                  <Divider className="m-0 my-2 col-span-5" />
                  <span className={`${fontItemTitleStyle} col-span-2`} colSpan={4}>
                    Subtotal Layanan
                  </span>
                  <span className={`${fontItemTitleStyle} text-right col-span-3`}>
                    {formatCurrency(data?.service_price_total ?? 0)}
                  </span>
                </>
              </div>
            </>
          ) : null}
        </React.Fragment>
        <div className="grid grid-cols-5 gap-y-2 items-center justify-between ">
          <Divider className="m-0 my-2 col-span-5" />
          <span className={`${fontItemTitleStyle} col-span-2`}>Subtotal</span>
          <span className={`${fontItemTitleStyle} col-span-3 text-right`}>
            {formatCurrency(data?.item_total ?? 0)}
          </span>
        </div>
      </div>
    </CardContainer>
  )
}

export default CardReviewServis
