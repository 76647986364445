import { Collapse, Drawer, Input, Spin } from 'antd'
import { IcAlertTriangle, IcClose, IcInfo, MagnifyingIcon } from 'assets/icons'
import ChevronDownIcon from 'assets/icons/chevron-down'
import ChevronUpIcon from 'assets/icons/chevron-up'
import { CustomButton } from 'components'
import React, { useEffect, useState } from 'react'
import { showSuccessMessage, showWarningMessage } from 'utils'
import PartList from '../part-list/part-list'
const { Panel } = Collapse
const DrawerPilihLayanan = ({
  data,
  handleGeneralService,
  onClose,
  open,
  selectedData = null,
  isLoading = false
}) => {
  const [keyword, setKeyword] = useState('')
  const [filteredData, setFilteredData] = useState(data)
  const [selected, setSelected] = useState(selectedData)
  const handleSelected = (obj) => {
    setSelected({ ...obj })
  }
  useEffect(() => {
    if (data) {
      return setFilteredData(data)
    }
  }, [data])
  function handleSearchKeyword(e) {
    const { value } = e.target
    setKeyword(value)
    if (value === '') {
      setFilteredData({
        ...filteredData,
        spareparts: data?.spareparts
      })
    } else {
      // HANDLE ARRAY SPAREPARTS DEPENDS ON KEYWORD
      const newSpareparts = data?.spareparts?.filter((sparepart) =>
        sparepart?.name.toLowerCase().includes(value?.toLowerCase())
      )
      setFilteredData({ ...filteredData, spareparts: newSpareparts })
    }
  }
  function handleAddToCartGeneralService() {
    if (filteredData?.is_free || selected) {
      const newServiceGenerals = {
        general_service: {
          id: filteredData.id,
          name: filteredData.name
        },
        totalDuration: filteredData.duration,
        totalBiayaLayanan: filteredData.price,
        sparepart: selected ? selected : { id: 0 },
        is_free: filteredData?.is_free
      }
      // Parent Func Handle General Service
      handleGeneralService(newServiceGenerals)
      showSuccessMessage({ content: 'Paket berhasil ditambahkan' })
      onClose()
    } else {
      showWarningMessage({ content: 'Silahkan pilih sparepart terlebih dahulu' })
    }
  }
  return (
    <Drawer
      className="h-screen overflow-y-auto"
      closable={false}
      extra={
        <div className="flex justify-center items-center cursor-pointer" onClick={onClose}>
          <IcClose />
        </div>
      }
      maskClosable={false}
      size="large"
      title={
        isLoading ? (
          ''
        ) : (
          <span className="display-xs-semibold text-gray-900">{filteredData?.name}</span>
        )
      }
      placement="left"
      open={open}
      onClose={onClose}>
      {isLoading ? (
        <div className="flex justify-center items-center w-full h-full">
          <Spin size="large" />
        </div>
      ) : (
        <div className="flex flex-col justify-between h-full">
          <div className="grid grid-cols-[30px_1fr] gap-2">
            <div className="flex flex-col items-center justify-start relative">
              <div
                className={`${
                  filteredData?.is_free || selected
                    ? 'bg-primary-500'
                    : 'bg-white border-2 border-solid border-gray-300'
                } rounded-full w-6 h-6 z-10 flex-nowrap flex-shrink-0`}
              />
              {/* h-[calc(100%+10px)] < buat Multiple Layanan */}
              <div className="w-[2px] bg-gray-300 h-full absolute" />
            </div>
            <Collapse
              accordion
              bordered={false}
              className="text-lg-medium flex w-full flex-col gap-2 text-gray-900 !rounded-lg sparepart-collapse"
              expandIconPosition={'end'}
              expandIcon={(props) => {
                return props.isActive ? (
                  <ChevronUpIcon className={'stroke-gray-400 w-3 h-3'} />
                ) : (
                  <ChevronDownIcon className={'stroke-gray-400 w-3 h-3'} />
                )
              }}>
              <Panel
                collapsible={filteredData?.is_free ? 'disabled' : ''}
                header={
                  <div className="flex flex-col gap-2 min-h-full">
                    <span>{filteredData?.name}</span>
                    {filteredData?.is_free ? (
                      <div className="text-sm-regular text-gray-500 flex gap-1">
                        <IcInfo className={'!stroke-gray-500 h-5 w-5'} />
                        <span>Layanan servis bebas biaya</span>
                      </div>
                    ) : selected !== null ? (
                      <div className="flex justify-between items-center text-gray-500 text-sm-medium">
                        <span>{selected?.name}</span>
                        <span>x{selected?.quantity}</span>
                      </div>
                    ) : (
                      <div className="text-sm-regular text-gray-500 flex gap-1">
                        <IcAlertTriangle strokeWidth="1" className={'!stroke-gray-500 '} />
                        <span>Anda belum memilih sparepart / Layanan</span>
                      </div>
                    )}
                  </div>
                }
                // style={{ border: '1px solid #EAECF0' }}
                className="bg-gray-50 !rounded-lg">
                {filteredData?.is_free ? null : (
                  <div className="grid grid-cols-1 gap-4">
                    <Input
                      onChange={(e) => handleSearchKeyword(e)}
                      value={keyword}
                      name={filteredData?.name}
                      size="medium"
                      className="text-md-normal text-gray-500 px-[14px] py-[5px] rounded-lg"
                      prefix={<MagnifyingIcon className={'mr-1'} />}
                      placeholder="Cari Produk"
                    />
                    <PartList
                      handleSelected={handleSelected}
                      spareparts={filteredData?.spareparts}
                      selected={selected?.id}
                      selectedData={selected}
                    />
                  </div>
                )}
              </Panel>
            </Collapse>
          </div>
          <div className="flex justify-end items-center gap-3 mt-2">
            <CustomButton type="plain" onClick={onClose}>
              Batal
            </CustomButton>
            <CustomButton
              disabled={!selected && !filteredData?.is_free}
              onClick={handleAddToCartGeneralService}>
              Simpan
            </CustomButton>
          </div>
        </div>
      )}
    </Drawer>
  )
}

export default DrawerPilihLayanan
