import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Breadcrumb, Card, Input, Layout } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { IcInfo, IcSearch, IcTrash } from 'assets/icons'
import { Button, HeaderContainer, Table } from 'components'
import { apiDelete, apiGet, apiPost, apiPut } from 'api'
import { logout } from 'store/actions'

import ModalUpdateCategory from './components/ModalUpdateCategory'
import { showErrorMessage, showSuccessMessage } from 'utils'
import moment from 'moment'
import { Content } from 'antd/lib/layout/layout'
import IcRefreshCcw from 'assets/icons/IcRefreshCcw'

const initialTableState = {
  totalRows: 0,
  offset: 0,
  limit: 10,
  keywords: '',
  listData: [],
  sort_by: 'id',
  sort_order: null
}
const statusSyncCategoryColor = {
  success: 'stroke-success-500',
  failed: 'stroke-primary-500',
  'non-existent': 'stroke-gray-500',
  'in-progress': 'stroke-warning-500'
}

const SparepartCategory = () => {
  const token = useSelector((state) => state.auth.token)
  const [tableState, setTableState] = useState(initialTableState)
  const [syncCategoryStatus, setSyncCategoryStatus] = useState({
    refetchTime: 0,
    status: '',
    last_sync_at: ''
  })
  const dispatch = useDispatch()
  const [modal, setModal] = useState({
    show: false,
    type: 'add',
    data: null
  })
  const columns = [
    {
      name: 'Nama Kategori Sparepart',
      selector: (row) => row?.name,
      sortable: true
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center">
          {/* <Button
            className="bg-white border-gray-300 mr-2 hover:bg-gray-50"
            onClick={() => setModal({ show: true, data: row, type: 'edit' })}>
            <IcEdit className="text-gray-700" size={16} />
          </Button> */}
          <Button
            className="bg-white border-[#FDA19B] hover:bg-red-50"
            onClick={() => setModal({ show: true, data: row, type: 'delete' })}>
            <IcTrash className="text-primary-500" size={16} />
          </Button>
        </div>
      )
    }
  ]

  const { isLoading, refetch } = useQuery({
    queryKey: [
      'sparepart-unit-list',
      tableState.offset,
      tableState.limit,
      tableState.sort_by,
      tableState.sort_order,
      tableState.keywords
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = {
        keywords: tableState.keywords,
        offset: tableState.offset,
        limit: tableState.limit,
        sort_by: tableState.sort_by,
        sort_order: tableState.sort_order
      }
      const res = await apiGet({
        url: 'servis-service/admin/sparepart-categories',
        data: params,
        token,
        onLogout: () => dispatch(logout())
      })
      return res
    },
    onSuccess: (res) => {
      setTableState((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  const checkStatusSyncCategory = useQuery({
    queryKey: ['checkStatusSyncCategory'],
    refetchOnWindowFocus: false,
    queryFn: async ({ signal }) => {
      const res = await apiGet({
        token,
        url: 'servis-service/admin/odoo/sync-status/sparepart-category',
        onLogout: () => dispatch(logout()),
        signal
      })
      return res
    },
    onSuccess: (res) => {
      setSyncCategoryStatus((prev) => ({
        ...prev,
        refetchTime: (prev.refetchTime += 1),
        ...res?.resData?.data
      }))
      if (res?.resData?.data?.status == 'failed') {
        return showErrorMessage({
          content: 'Sinkronisasi Data Sparepart Category Gagal, Silahkan Coba Lagi'
        })
      }
      if (res?.resData?.data?.status == 'success' && syncCategoryStatus?.refetchTime > 0) {
        refetch()
        return showSuccessMessage({ content: 'Sinkronisasi Data Sparepart Category Berhasil' })
      }
    },
    enabled: syncCategoryStatus?.status === 'in-progress',
    refetchInterval: 10000, //Refetch interval in ms (10sec)
    refetchIntervalInBackground: true
  })
  useEffect(() => {
    checkStatusSyncCategory.refetch()
  }, [])

  const SyncOdooSparepartCategory = useQuery(
    {
      queryKey: 'SyncOdooCategory',
      refetchOnWindowFocus: false,
      queryFn: async () => {
        const res = await apiPost({
          url: 'servis-service/admin/odoo/sync-sparepart-categories',
          token,
          onLogout: () => dispatch(logout())
        })
        return res
      },
      onSuccess: () => {
        checkStatusSyncCategory.refetch()
        showSuccessMessage({
          content:
            'Generate Data Sparepart Category sedang dalam antrian, silahkan tunggu notifikasi ketika proses sinkronisasi selesai'
        })
      },
      onError: () => {
        showErrorMessage({
          content: 'Sinkronisasi Data Sparepart Category Tidak Berhasil, Silahkan Coba Lagi'
        })
      },
      enabled: false
    },
    []
  )

  const syncOdooSparepartCategoryHandler = () => {
    SyncOdooSparepartCategory.refetch()
  }

  const saveData = async (data) => {
    try {
      let res
      let body = {
        ...data
      }

      if (data?.id) {
        const id = data?.id
        res = await apiPut({
          url: `servis-service/admin/sparepart-categories/${id}`,
          data: body,
          token,
          onLogout: () => dispatch(logout())
        })
      } else {
        res = await apiPost({
          url: 'servis-service/admin/sparepart-categories',
          data: body,
          token,
          onLogout: () => dispatch(logout())
        })
      }
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        setModal({
          ...modal,
          show: false
        })
        refetch()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteData = async (data) => {
    try {
      let res
      res = await apiDelete({
        url: `servis-service/admin/sparepart-categories/${data?.id}`,
        token,
        onLogout: () => dispatch(logout())
      })
      if (res?.resStatus === 200 || res?.resStatus === 201) {
        setModal({
          ...modal,
          show: false
        })
        refetch()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearch = useCallback((value) => {
    setTableState((prevState) => ({
      ...prevState,
      keywords: value
    }))
  }, [])

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort_by: column.sortField,
      sort_order: sortDirection
    }))
  }

  const handlePerRowsChange = (newPerPage) => {
    setTableState((prevState) => ({
      ...prevState,
      limit: newPerPage,
      offset: 0
    }))
  }

  const onChangePage = (page) => {
    setTableState((prevState) => ({
      ...prevState,
      offset: page
    }))
  }

  return (
    <Layout style={{ padding: '0 24px 24px', background: '#F5F5F5' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Sparepart</Breadcrumb.Item>
        <Breadcrumb.Item>Kategori</Breadcrumb.Item>
      </Breadcrumb>
      <ModalUpdateCategory
        isOpen={modal.show}
        data={modal.data}
        type={modal.type}
        onSubmit={saveData}
        onDelete={deleteData}
        onCancel={() => setModal({ ...modal, show: false })}
      />
      <Content className="h-screen">
        <Card className="rounded-lg">
          <div className="flex flex-col w-full gap-y-5">
            <HeaderContainer
              title="Sparepart Category"
              titleAdd="Sync"
              iconRightChild={''}
              isInfo
              InfoChild={
                <>
                  <span className="flex lg:justify-end items-center">
                    {statusSyncCategory(syncCategoryStatus)}
                  </span>
                  <IcInfo
                    className={`${
                      statusSyncCategoryColor?.[syncCategoryStatus?.status] ?? 'stroke-gray-700'
                    }`}
                    size={20}
                  />
                </>
              }
              iconRight={
                <IcRefreshCcw
                  className={
                    syncCategoryStatus?.status === 'in-progress'
                      ? 'stroke-gray-300'
                      : 'stroke-white'
                  }
                  size={20}
                />
              }
              isPressAdd
              disabled={syncCategoryStatus?.status === 'in-progress'}
              onPressAdd={() => {
                syncOdooSparepartCategoryHandler()
              }}
              isLoadingSync={SyncOdooSparepartCategory?.isFetching}
            />
            <div className="mb-5 flex justify-between">
              <Input
                className="w-1/4 rounded-md py-1.5"
                placeholder="Pencarian"
                prefix={<IcSearch className="text-gray-400" />}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
          <Table
            columns={columns}
            data={tableState?.listData}
            pending={isLoading}
            onSort={handleSort}
            totalRows={tableState?.totalRows}
            handlePerRowsChange={handlePerRowsChange}
            pageSize={tableState?.limit}
            page={tableState?.offset}
            onChangePrevPage={() => {
              if (tableState?.offset > 0) {
                onChangePage(tableState?.offset - tableState?.limit)
              }
            }}
            onChangeNextPage={() => {
              if (tableState?.totalRows > tableState?.limit + tableState?.offset) {
                onChangePage(tableState?.offset + tableState?.limit)
              }
            }}
          />
        </Card>
      </Content>
    </Layout>
  )
}
function statusSyncCategory(syncCategory) {
  switch (syncCategory?.status) {
    case 'success':
      return `Terakhir di sinkronisasi ${moment(syncCategory?.last_sync_at).format('LLL')}`
    case 'in-progress':
      return 'Menunggu Proses Sinkronisasi Data Sparepart Kategori'
    case 'failed':
      return 'Proses Sinkronisasi Data Sparepart Kategori Gagal, Silahkan Coba Lagi'
    case 'non-existent':
    default:
      return ''
  }
}
export default SparepartCategory
