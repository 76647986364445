import React, { Fragment, useMemo } from 'react'
import { formatCurrency } from 'utils'
import { IcFacebook, IcInstagram, IcLinkedIn, IcTwitter } from 'assets/icons'
import BirdMobilLogo from 'assets/images/Birdmobil-logo.png'
import moment from 'moment'
import 'moment/locale/en-gb'
import { Divider } from 'antd'
import { accountNumberBirdMobil } from 'constant/accountNumber'

const InvoiceCard = React.forwardRef(({ data, isTypeServis = false }, ref) => {
  const fontInvoiceFieldBoldStyle = 'font-medium text-gray-500 text-left font-semibold text-lg'
  const fontInvoiceFieldStyle = 'font-medium text-gray-500 text-left'
  const fontInvoiceValueStyle = 'font-medium text-gray-700 text-right'
  const fontItemTitleStyle = 'font-semibold text-gray-900'
  const fontItemValueStyle = 'font-normal text-gray-700 text-sm'

  const isInternal = useMemo(() => {
    if (data?.ownership?.toLowerCase() === 'mobil internal') {
      return true
    } else {
      return false
    }
  }, [data?.ownership])

  return (
    <div
      ref={ref}
      className="flex min-h-screen overflow-x-hidden"
      style={{
        width: '100%',
        // height: '100%',
        backgroundColor: '#FFFFFF'
      }}>
      <div className="flex flex-col w-full h-full justify-between ">
        <div className="flex flex-col">
          <div
            key="header-content"
            className="flex flex-row items-center justify-between border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
            <div className="flex flex-row items-center justify-center space-x-1 bg-white hover:cursor-pointer">
              <img width={30} height={30} src={BirdMobilLogo} alt="" />
              <span className="font-semibold text-gray-900">Birdmobil</span>
            </div>
            <span className="font-normal text-gray-500">
              {data?.user_id > 0 &&
              data?.company_id == 0 &&
              data?.booking_invoice?.transaction_detail?.is_payment_completed
                ? moment(data?.booking_invoice?.transaction_detail?.paid_at).format('LL')
                : data?.user_id > 0 && data?.company_id > 0 && data?.status === 'SERVICE_FINISHED'
                ? moment(data?.completion_time).format('LL')
                : null}
            </span>
          </div>
          <div key="invoice-information-content" className="pt-8 ">
            <table className="border-separate border-spacing-y-2 w-full">
              <tr>
                <td className={!isInternal ? fontInvoiceFieldStyle : fontInvoiceFieldBoldStyle}>
                  <span className="whitespace-nowrap">
                    {!isInternal ? 'Invoice No.' : 'Servis Internal Unit'}
                  </span>
                </td>
                <td className={fontInvoiceValueStyle}>{!isInternal ? data?.invoiceNumber : ''}</td>
              </tr>
              <tr>
                <td className={fontInvoiceFieldStyle}>Account</td>
                <td className={`${fontInvoiceValueStyle}`}>
                  {/* Account B2C */}
                  {data?.user_id > 0 && data?.company_id == 0
                    ? data?.account
                    : // Admin PT B2B
                    data?.user_id > 0 &&
                      data?.company_id > 0 &&
                      data?.user_id == data?.company_admin_id
                    ? data?.company?.name
                    : // Karyawan PT B2B
                    data?.user_id > 0 &&
                      data?.company_id > 0 &&
                      data?.user_id !== data?.company_admin_id
                    ? `${data?.user?.fullname} - ${data?.company?.name}`
                    : ''}
                </td>
              </tr>

              {data?.user_id > 0 && data?.company_id > 0 ? (
                <tr>
                  <td className={`${fontInvoiceFieldStyle} flex items-start`}>Alamat</td>
                  <td className={fontInvoiceValueStyle}>{data?.company?.address}</td>
                </tr>
              ) : null}
              <tr>
                <td className={fontInvoiceFieldStyle}>Unit</td>
                <td className={fontInvoiceValueStyle}>{data?.unit}</td>
              </tr>
              <tr>
                <td className={fontInvoiceFieldStyle}>Plat No</td>
                <td className={fontInvoiceValueStyle}>{data?.police_number}</td>
              </tr>
              <tr>
                <td className={fontInvoiceFieldStyle}>Kilometer</td>
                <td className={fontInvoiceValueStyle}>{data?.mileage}</td>
              </tr>
              <tr>
                <td className={fontInvoiceFieldStyle}>Layanan</td>
                <td className={fontInvoiceValueStyle}>{data?.layanan}</td>
              </tr>
            </table>
          </div>

          <div key="items-content" className="flex flex-col pt-10 pb-8">
            {isTypeServis ? (
              <Fragment>
                <div
                  className={`border border-solid border-b-1 border-x-0 border-t-0 pb-3 border-b-gray-300 gap-1 grid grid-cols-11`}>
                  <div className={`text-left ${!isInternal ? 'col-span-4 ' : 'col-span-4'}`}>
                    <span className={fontItemTitleStyle}>Item</span>
                  </div>
                  <div className="text-left">
                    <span className={fontItemTitleStyle}>Qty</span>
                  </div>
                  <div className="text-left col-span-2">
                    <span className={fontItemTitleStyle}>Harga</span>
                  </div>
                  <div className="text-left col-span-2">
                    <span className={fontItemTitleStyle}>Discount</span>
                  </div>
                  <div className="text-right col-span-2">
                    <span className={fontItemTitleStyle}>Total</span>
                  </div>
                </div>
                {data?.items?.sparepart?.length > 0 ? (
                  <>
                    <div className={`pt-3 ${fontItemTitleStyle}`}>Sparepart</div>
                    {data?.items?.sparepart?.map((sparepart, index) => (
                      <div key={index} className={`pt-1 grid grid-cols-11 gap-1`}>
                        <div className={`text-left ${!isInternal ? 'col-span-4' : 'col-span-4'}`}>
                          <span className={fontItemValueStyle}>{sparepart?.sparepart_name}</span>
                        </div>
                        <div className=" text-left">
                          <span className={fontItemValueStyle}>
                            {sparepart?.quantity ? `x${sparepart?.quantity}` : ''}
                          </span>
                        </div>
                        <div className=" text-left col-span-2">
                          <span className={fontItemValueStyle}>
                            {formatCurrency(sparepart?.price)}
                          </span>
                        </div>
                        <div className=" text-left col-span-2 ">
                          <span className={fontItemValueStyle + ' text-primary-500'}>
                            {sparepart?.discount ? `- ${formatCurrency(sparepart?.discount)}` : ''}
                          </span>
                        </div>
                        <div className=" text-right col-span-2">
                          <span className={fontItemValueStyle}>
                            {formatCurrency(sparepart?.total)}
                          </span>
                        </div>
                      </div>
                    ))}
                    <div className="grid grid-cols-5">
                      {data?.sparepart_general_voucher_discount_total ? (
                        <>
                          <Divider className="m-0 my-2 col-span-5" />
                          <span className={`${fontItemValueStyle} col-span-2`}>
                            Voucher Sparepart
                          </span>
                          <span
                            className={`${fontItemValueStyle} text-right text-primary-500 col-span-3`}>
                            - {formatCurrency(data?.sparepart_general_voucher_discount_total ?? 0)}
                          </span>
                        </>
                      ) : null}
                      <>
                        <Divider className="m-0 my-2 col-span-5" />
                        <span className={`${fontItemTitleStyle} col-span-2`} colSpan={4}>
                          Subtotal Sparepart
                        </span>
                        <span className={`${fontItemTitleStyle} text-right col-span-3`}>
                          {formatCurrency(data?.sparepart_total ?? 0)}
                        </span>
                      </>
                    </div>
                  </>
                ) : null}
                <div className={`pt-3 ${fontItemTitleStyle}`}>Layanan</div>
                {data?.items?.service?.map((service, index) => (
                  <div key={index} className={`pt-1 grid grid-cols-11 gap-1`}>
                    <div className={`text-left ${!isInternal ? 'col-span-4' : 'col-span-4'}`}>
                      <span className={fontItemValueStyle}>{service?.general_service_name}</span>
                    </div>
                    <div className=" text-left">
                      <span className={fontItemValueStyle}>
                        {service?.quantity ? `x${service?.quantity}` : ''}
                      </span>
                    </div>
                    <div className=" text-left col-span-2">
                      <span className={fontItemValueStyle}>{formatCurrency(service?.price)}</span>
                    </div>
                    <div className=" text-left col-span-2">
                      <span className={fontItemValueStyle + ' text-primary-500'}>
                        {service?.discount ? `- ${formatCurrency(service?.discount)}` : ''}
                      </span>
                    </div>
                    <div className=" text-right col-span-2">
                      <span className={fontItemValueStyle}>{formatCurrency(service?.total)}</span>
                    </div>
                  </div>
                ))}
                <div className="grid grid-cols-5">
                  {data?.service_general_voucher_discount_total ? (
                    <>
                      <Divider className="m-0 my-2 col-span-5" />
                      <span className={`${fontItemValueStyle} col-span-2`}>Voucher Layanan</span>
                      <span
                        className={`${fontItemValueStyle} text-right text-primary-500 col-span-3`}>
                        - {formatCurrency(data?.service_general_voucher_discount_total ?? 0)}
                      </span>
                    </>
                  ) : null}
                  <>
                    <Divider className="m-0 my-2 col-span-5" />
                    <span className={`${fontItemTitleStyle} col-span-2`} colSpan={4}>
                      Subtotal Layanan
                    </span>
                    <span className={`${fontItemTitleStyle} text-right col-span-3`}>
                      {formatCurrency(data?.service_price_total ?? 0)}
                    </span>
                  </>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div
                  className={`border border-solid border-b-1 border-x-0 border-t-0 pb-3 border-b-gray-300 ${
                    !isInternal ? 'grid grid-cols-6' : 'grid grid-cols-5'
                  }`}>
                  <div className={`text-left ${!isInternal ? 'col-span-3 ' : 'col-span-4'}`}>
                    <span className={fontItemTitleStyle}>Item</span>
                  </div>
                  {!isInternal ? (
                    <div className=" text-center">
                      <span className={fontItemTitleStyle}>Price</span>
                    </div>
                  ) : null}
                  <div className=" text-center">
                    <span className={fontItemTitleStyle}>Qty</span>
                  </div>
                  {!isInternal ? (
                    <div className=" text-center">
                      <span className={fontItemTitleStyle}>Total</span>
                    </div>
                  ) : null}
                </div>
                {data?.items?.map((item) =>
                  item?.products?.map((product, index) => (
                    <div
                      key={index}
                      className={`pt-3 ${!isInternal ? 'grid grid-cols-6' : 'grid grid-cols-5'}`}>
                      <div className={`text-left ${!isInternal ? 'col-span-3 ' : 'col-span-4'}`}>
                        <span className={fontItemValueStyle}>{product?.name}</span>
                      </div>
                      {!isInternal ? (
                        <div className=" text-center">
                          <span className={fontItemValueStyle}>
                            {formatCurrency(product?.price)}
                          </span>
                        </div>
                      ) : null}
                      <div className=" text-center">
                        <span className={fontItemValueStyle}>{product?.qty}</span>
                      </div>
                      {!isInternal ? (
                        <div className=" text-right">
                          <span className={fontItemValueStyle}>
                            {formatCurrency(product?.priceTotal)}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  ))
                )}
              </Fragment>
            )}

            {/* {!isInternal ? ( */}
            <div className="grid grid-cols-5 gap-y-2 items-center justify-between pt-8">
              <>
                <span className={`${fontItemTitleStyle} col-span-2`}>Subtotal</span>
                <span className={`${fontItemTitleStyle} col-span-3 text-right`}>
                  {formatCurrency(data?.subTotal)}
                </span>
              </>
              <Divider className="m-0 my-2 col-span-5" />
              {data?.discount && data?.discount !== 0 ? (
                <>
                  <span className={`${fontItemTitleStyle} col-span-2`}>Diskon</span>
                  <span
                    className={`${fontItemTitleStyle} col-span-3 text-right`}>{`- ${formatCurrency(
                    data?.discount
                  )}`}</span>
                </>
              ) : null}
              {data?.discountCompany && data?.discountCompany !== 0 ? (
                <>
                  <span className={`${fontItemTitleStyle} col-span-2`}>Diskon Perusahaan</span>
                  <span
                    className={`${fontItemTitleStyle} col-span-3 text-right`}>{`- ${formatCurrency(
                    data?.discountCompany
                  )}`}</span>
                </>
              ) : null}
              {data?.tax && data?.tax !== 0 ? (
                <>
                  <span className={`${fontItemTitleStyle} col-span-2`}>Pajak</span>
                  <span
                    className={`${fontItemTitleStyle} col-span-3 text-right`}>{`${formatCurrency(
                    data?.tax
                  )}`}</span>
                </>
              ) : null}
              {data?.distanceFee && data?.distanceFee !== 0 ? (
                <>
                  <span className={`${fontItemTitleStyle} col-span-2`}>Biaya Pickup</span>
                  <span
                    className={`${fontItemTitleStyle} col-span-3 text-right`}>{`${formatCurrency(
                    data?.distanceFee
                  )}`}</span>
                </>
              ) : null}
              {data?.servicePrice && data?.servicePrice !== 0 ? (
                <>
                  <span className={`${fontItemTitleStyle} col-span-2`}>Harga Layanan</span>
                  <span
                    className={`${fontItemTitleStyle} col-span-3 text-right`}>{`${formatCurrency(
                    data?.servicePrice
                  )}`}</span>
                </>
              ) : null}
              {data?.adminFee && data?.adminFee !== 0 ? (
                <>
                  <span className={`${fontItemTitleStyle} col-span-2`}>Biaya Admin</span>
                  <span className={`${fontItemTitleStyle} col-span-3 text-right`}>
                    {formatCurrency(data?.adminFee ?? 0)}
                  </span>
                </>
              ) : null}
              <Divider className="m-0 my-2 col-span-5" />
              <div className="col-span-5 grid grid-flow-col">
                <span
                  className={`${fontItemTitleStyle} text-primary-500 text-md md:text-lg xl:text-xl`}>
                  Total Pembayaran
                </span>
                <span
                  className={`${fontItemTitleStyle} text-right text-primary-500 text-md md:text-lg xl:text-xl`}>
                  {formatCurrency(data?.totalPrice)}
                </span>
              </div>
            </div>
            {/* Rekening Pembayaran */}
            {data?.user_id > 0 && data?.company_id > 0 ? (
              <div className="flex flex-col gap-3 mt-7">
                <span className={fontItemTitleStyle}>Rekening Pembayaran</span>
                {accountNumberBirdMobil?.map((bank) => (
                  <div
                    key={`bank-${bank?.id}`}
                    className="bg-gray-100 flex justify-between items-center p-4 rounded-lg">
                    <div className={`${fontItemValueStyle} flex flex-col gap-2`}>
                      <span>Bank {bank?.bankName}</span>
                      <span>{bank?.accountName}</span>
                    </div>
                    <span className={fontItemTitleStyle}>{bank?.accountNumber}</span>
                  </div>
                ))}
              </div>
            ) : null}
            <div className="pt-8 flex flex-col gap-2">
              <span className={fontItemTitleStyle}>Catatan Service</span>
              <p className={fontItemValueStyle}>{data.notes ? data.notes : 'Tidak Ada'}</p>
            </div>

            {/* Signature Head Finance BirdMobil */}
            {data?.user_id > 0 && data?.company_id > 0 ? (
              <div className="mt-8 h-[100px] flex items-end">
                <span className={fontItemValueStyle}>( Head Finance BirdMobil )</span>
              </div>
            ) : null}
          </div>
        </div>
        <div key="footer" className="flex flex-col space-y-5">
          <span className={fontItemTitleStyle}>Thank you for your business</span>
          <div className="flex flex-col space-y-1 border border-solid border-b-1 border-x-0 border-t-0 pb-8 border-b-gray-300">
            <span className="font-medium text-gray-700">{data?.workshop_name}</span>
            <span className="font-normal text-gray-500">{data?.workshop_address}</span>
          </div>
          <div className="flex flex-row items-center justify-around pt-5">
            <IcTwitter className="text-gray-400" size={23} />
            <IcLinkedIn className="text-gray-400" size={23} />
            <IcFacebook className="text-gray-400" size={23} />
            <IcInstagram className="text-gray-400" size={23} />
          </div>
        </div>
      </div>
    </div>
  )
})

export default InvoiceCard
