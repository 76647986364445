import { Collapse, Drawer, Input, Spin } from 'antd'
import { IcAlertTriangle, IcClose, IcInfo, MagnifyingIcon } from 'assets/icons'
import ChevronDownIcon from 'assets/icons/chevron-down'
import ChevronUpIcon from 'assets/icons/chevron-up'
import React, { useEffect, useState } from 'react'
import { showSuccessMessage, showWarningMessage } from 'utils'
import PartList from '../part-list/part-list'
import { CustomButton } from 'components'
const { Panel } = Collapse
const DrawerPilihPacket = ({
  data,
  handlePacketService,
  onClose,
  open,
  newObjKeyword,
  newObjIds = {},
  isLoading = false
}) => {
  const [keyword, setKeyword] = useState(newObjKeyword)
  const [selected, setSelected] = useState(newObjIds)

  useEffect(() => {
    if (data && newObjKeyword && newObjIds) {
      setFilteredData(data)
      setKeyword(newObjKeyword)
      setSelected(newObjIds)
    }
  }, [data, newObjIds, newObjKeyword])

  const [filteredData, setFilteredData] = useState({ ...data })
  function handleSelected(key, obj) {
    setSelected({ ...selected, [key]: obj })
  }
  function handleSearchKeyword(e, id) {
    const { name, value } = e.target
    setKeyword({ ...keyword, [name]: value })
    let index = filteredData.general_services.findIndex((service) => service.id === id)
    if (value === '') {
      setFilteredData({
        ...filteredData,
        general_services: data?.general_services
      })
    } else {
      // HANDLE LIST OF SPAREPARTS DEPENDS ON KEYWORD
      const newGeneralService = [...data.general_services]
      const newSpareparts = data?.general_services[index]?.spareparts?.filter((sparepart) =>
        sparepart?.name.toLowerCase().includes(value?.toLowerCase())
      )
      newGeneralService[index] = {
        ...newGeneralService[index],
        spareparts: newSpareparts
      }
      setFilteredData({ ...filteredData, general_services: newGeneralService })
    }
  }
  function handleTambahKeranjang() {
    let valid = true
    for (const [key] of Object.entries(selected)) {
      if (selected[key] === null) {
        valid = false
        break
      }
    }
    if (valid) {
      // CREATE ARRAY OF OBJECT FOR CART ITEMS & POST SERVICE
      let newArray = []
      let totalDuration = 0
      data?.general_services.map((service) => {
        totalDuration += service.duration
        let newObj = {
          general_service_id: service.id,
          general_service: { id: service.id, name: service.name, is_free: service.is_free },
          sparepart: selected[service.id]
        }
        newArray.push(newObj)
      })
      handlePacketService(newArray, data.name, totalDuration)
      showSuccessMessage({ content: 'Paket berhasil ditambahkan' })
      onClose()
    } else {
      showWarningMessage({ content: 'Pilih sparepart terlebih dahulu' })
    }
  }
  return (
    <Drawer
      className="h-screen overflow-y-auto"
      closable={false}
      extra={
        <div className="flex justify-center items-center cursor-pointer" onClick={onClose}>
          <IcClose />
        </div>
      }
      maskClosable={false}
      size="large"
      title={
        isLoading ? (
          ''
        ) : (
          <span className="display-xs-semibold text-gray-900">{filteredData?.name}</span>
        )
      }
      placement="left"
      open={open}
      onClose={onClose}>
      {isLoading ? (
        <div className="flex justify-center items-center w-full h-full">
          <Spin size="large" />
        </div>
      ) : (
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col gap-2">
            {filteredData?.general_services?.map((service, index) => (
              <div key={`service-item-${service?.id}`} className="grid grid-cols-[30px_1fr] gap-2">
                <div className="flex flex-col items-center justify-start relative">
                  <div
                    className={`${
                      service?.is_free || selected?.[service?.id] !== null
                        ? 'bg-primary-500'
                        : 'bg-white border-2 border-solid border-gray-300'
                    } rounded-full w-6 h-6 z-10 flex-nowrap flex-shrink-0`}
                  />
                  <div
                    className={`w-[2px] bg-gray-300 absolute ${
                      index == filteredData?.general_services?.length - 1
                        ? 'h-full'
                        : 'h-[calc(100%+10px)]'
                    }`}
                  />
                </div>
                <Collapse
                  accordion
                  bordered={false}
                  className="text-lg-medium flex w-full flex-col gap-2 text-gray-900 !rounded-lg sparepart-collapse"
                  expandIconPosition={'end'}
                  expandIcon={(props) => {
                    return props.isActive ? (
                      <ChevronUpIcon className={'stroke-gray-400 w-3 h-3'} />
                    ) : (
                      <ChevronDownIcon className={'stroke-gray-400 w-3 h-3'} />
                    )
                  }}>
                  <Panel
                    collapsible={service?.is_free ? 'disabled' : ''}
                    header={
                      <div className="flex flex-col gap-2 min-h-full">
                        <span>{service?.name}</span>
                        {service?.is_free ? (
                          <div className="text-sm-regular text-gray-500 flex gap-1">
                            <IcInfo className={'!stroke-gray-500 h-5 w-5'} />
                            <span>Layanan servis bebas biaya</span>
                          </div>
                        ) : selected?.[service?.id] !== null ? (
                          <div className="flex justify-between items-center text-gray-500 text-sm-medium">
                            <p>{selected?.[service?.id]?.name}</p>
                            <span>x{selected?.[service?.id]?.quantity}</span>
                          </div>
                        ) : (
                          <div className="text-sm-regular text-gray-500 flex gap-1">
                            <IcAlertTriangle strokeWidth="1" className={'!stroke-gray-500 '} />
                            <span>Anda belum memilih sparepart / Layanan</span>
                          </div>
                        )}
                      </div>
                    }
                    // style={{ border: '1px solid #EAECF0' }}
                    className="bg-gray-50 !rounded-lg">
                    {service?.is_free ? null : (
                      <div className="grid grid-cols-1 gap-4">
                        <Input
                          onChange={(e) => handleSearchKeyword(e, service?.id)}
                          value={keyword[service?.name]}
                          name={service?.name}
                          size="medium"
                          className="text-md-normal text-gray-500 px-[14px] py-[5px] rounded-lg"
                          prefix={<MagnifyingIcon className={'mr-1'} />}
                          placeholder="Cari Produk"
                        />
                        <PartList
                          handleSelected={handleSelected.bind(this, service?.id)}
                          spareparts={service?.spareparts}
                          selected={selected[service?.id]?.id}
                          isPacketService={true}
                          selectedData={selected[service?.id]}
                        />
                      </div>
                    )}
                  </Panel>
                </Collapse>
              </div>
            ))}
          </div>
          <div className="flex justify-end items-center gap-3 mt-2">
            <CustomButton type="plain" onClick={onClose}>
              Batal
            </CustomButton>
            <CustomButton
              disabled={Object?.entries(selected)?.some((value) => value[1] == null)}
              onClick={handleTambahKeranjang}>
              Simpan
            </CustomButton>
          </div>
        </div>
      )}
    </Drawer>
  )
}

export default DrawerPilihPacket
