import React, { useState } from 'react'
import { Input, Modal, Select, Typography } from 'antd'
import { validationInput } from 'helpers'
import { useQuery } from 'react-query'
import { apiGet, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'

let numbers = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  28
]

const ModalUpdatePerusahaan = ({ type, isOpen, onCancel, data, onSubmit, onDelete }) => {
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const onLogout = () => dispatch(logout())

  const [name, setName] = useState({
    value: data?.fullname ?? '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  })
  const [email, setEmail] = useState({
    value: data?.email ?? '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true,
      isEmail: true
    }
  })
  const [password, setPassword] = useState({
    value: '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  })
  const [phone, setPhone] = useState({
    value: data?.phone_number ?? '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true,
      isNumeric: true
    }
  })
  const [npwp, setNpwp] = useState({
    value: data?.npwp ?? '',
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  })
  const [province, setProvince] = useState({
    value: data?.province_id ?? null,
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  })
  const [city, setCity] = useState({
    value: data?.city_id ?? null,
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  })
  const [subDistrict, setSubDistrict] = useState({
    value: data?.sub_district_id ?? null,
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  })
  // const [discountType, setDiscountType] = useState({
  //   value: data?.discount_type ?? 1,
  //   valid: false,
  //   touched: false,
  //   validation: {
  //     isRequired: true
  //   }
  // })
  // const [discount, setDiscount] = useState({
  //   value: data?.discount ?? null,
  //   valid: false,
  //   touched: false,
  //   validation: {
  //     isRequired: true
  //   }
  // })
  // const [maxDiscount, setMaxDiscount] = useState({
  //   value: data?.max_discount ?? '',
  //   valid: false,
  //   touched: false,
  //   validation: {
  //     isRequired: true
  //   }
  // })
  const [dueDate, setDueDate] = useState({
    value: data?.due_date ?? null,
    valid: false,
    touched: false,
    validation: {
      isRequired: true
    }
  })
  const [address, setAddress] = useState(data?.address ?? '')
  const [postalCode, setPostalCode] = useState(data?.postal_code ?? '')
  const [dropDownJatuhTempo, setDropDownJatuhTempo] = useState([
    {
      name: 'Pilih Jatuh Tempo',
      value: -1
    }
  ])

  const { isLoading } = useQuery({
    queryKey: ['perusahaan-data'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (type === 'edit') {
        const res = await apiGet({
          url: `user-service/admin/companies/${data?.id}`,
          token,
          onLogout
        })
        let newData = res?.resData?.data
        let validation = validationInput(newData?.name, name.validation)
        setName({
          ...name,
          value: newData?.name ?? '',
          touched: !!newData?.name,
          valid: validation.valid,
          messageError: validation.messageError
        })
        validation = validationInput(newData?.email, email.validation)
        setEmail({
          ...email,
          value: newData?.email ?? '',
          touched: !!newData?.email,
          valid: validation.valid,
          messageError: validation.messageError
        })
        setPassword({
          ...password,
          value: newData?.password ?? '',
          touched: !!newData?.password,
          valid: validation.valid,
          messageError: validation.messageError
        })
        validation = validationInput(newData?.phone_number, phone.validation)
        setPhone({
          ...phone,
          value: newData?.phone_number ?? '',
          touched: !!newData?.phone_number,
          valid: validation.valid,
          messageError: validation.messageError
        })
        setNpwp({
          ...npwp,
          value: newData?.npwp ?? '',
          touched: !!newData?.npwp,
          valid: validation.valid,
          messageError: validation.messageError
        })
        setProvince({
          ...province,
          value: newData?.province_id ?? '',
          touched: !!newData?.province_id,
          valid: validation.valid,
          messageError: validation.messageError
        })
        setCity({
          ...city,
          value: newData?.city_id ?? '',
          touched: !!newData?.city_id,
          valid: validation.valid,
          messageError: validation.messageError
        })
        setSubDistrict({
          ...subDistrict,
          value: newData?.sub_district_id ?? '',
          touched: !!newData?.sub_district_id,
          valid: validation.valid,
          messageError: validation.messageError
        })
        // setDiscountType({
        //   ...discountType,
        //   value:
        //     newData?.discount_type && newData?.discount_type !== 0 && newData?.discount_type
        //       ? newData?.discount_type
        //       : 1,
        //   touched: !!newData?.discount_type,
        //   valid: validation.valid,
        //   messageError: validation.messageError
        // })
        // setDiscount({
        //   ...discount,
        //   value: newData?.discount ?? '',
        //   touched: !!newData?.discount,
        //   valid: validation.valid,
        //   messageError: validation.messageError
        // })
        // setMaxDiscount({
        //   ...maxDiscount,
        //   value: newData?.max_discount ?? '',
        //   touched: !!newData?.max_discount,
        //   valid: validation.valid,
        //   messageError: validation.messageError
        // })
        setDueDate({
          ...dueDate,
          value: newData?.due_date ?? '',
          touched: !!newData?.due_date,
          valid: validation.valid,
          messageError: validation.messageError
        })
        setAddress(newData?.address)
        setPostalCode(newData?.postal_code)
        for (let x in numbers) {
          setDropDownJatuhTempo((prev) => [
            ...prev,
            { name: numbers[x].toString(), value: numbers[x] }
          ])
        }
      } else {
        setName({
          ...name,
          value: '',
          touched: false,
          valid: false,
          messageError: ''
        })
        setEmail({
          ...email,
          value: '',
          touched: false,
          valid: false,
          messageError: ''
        })
        setPassword({
          ...password,
          value: '',
          touched: false,
          valid: false,
          messageError: ''
        })
        setPhone({
          ...phone,
          value: '',
          touched: false,
          valid: false,
          messageError: ''
        })
        setNpwp({
          ...npwp,
          value: '',
          touched: false,
          valid: false,
          messageError: ''
        })
        setProvince({
          ...province,
          value: null,
          touched: false,
          valid: false,
          messageError: ''
        })
        setCity({
          ...city,
          value: null,
          touched: false,
          valid: false,
          messageError: ''
        })
        setSubDistrict({
          ...subDistrict,
          value: null,
          touched: false,
          valid: false,
          messageError: ''
        })
        setAddress('')
        setPostalCode('')
      }
    },
    enabled: isOpen
  })

  const provinceData = useQuery({
    queryKey: ['province-data'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls.master.prefix}/${urls.master.location.province.all}`,
        onLogout
      })
      return res
    },
    enabled: !isLoading
  })

  const cityData = useQuery({
    queryKey: ['city-data', province.value],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls.master.prefix}/${urls.master.location.cities.filterByProvince}/${province.value}`,
        onLogout
      })
      return res
    },
    enabled: !isLoading && !provinceData.isLoading && province.value !== null
  })

  const subDistrictData = useQuery({
    queryKey: ['sub-district-data', city.value],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls.master.prefix}/${urls.master.location.subDistrict.filterByCity}/${city.value}`,
        onLogout
      })
      return res
    },
    enabled: !isLoading && !cityData.isLoading && city.value !== null
  })

  const onChangeInput = (value, identifier, setIdentifier) => {
    let { valid, messageError } = validationInput(value, identifier?.validation)
    setIdentifier({
      ...identifier,
      touched: true,
      value: value,
      valid: valid,
      messageError: messageError
    })
  }

  const onOk = async () => {
    let provIdx = provinceData?.data?.resData?.data?.findIndex((item) => item.id === province.value)
    let cityIdx = cityData?.data?.resData?.data?.findIndex((item) => item.id === city.value)
    let subDIdx = subDistrictData?.data?.resData?.data?.findIndex(
      (item) => item.id === subDistrict.value
    )
    if (type === 'add') {
      onSubmit({
        id: data?.id,
        name: name.value,
        email: email.value,
        password: password.value,
        phone_number: phone.value,
        npwp: npwp.value,
        address: address,
        province_id: provinceData?.data?.resData?.data[provIdx]?.id,
        province_name: provinceData?.data?.resData?.data[provIdx]?.name,
        city_id: cityData?.data?.resData?.data[cityIdx]?.id,
        city_name: cityData?.data?.resData?.data[cityIdx]?.name,
        sub_district_id: subDistrictData?.data?.resData?.data[subDIdx]?.id,
        sub_district_name: subDistrictData?.data?.resData?.data[subDIdx]?.name,
        postal_code: postalCode
      })
    }
    if (type === 'edit') {
      onSubmit({
        id: data?.id,
        name: name.value,
        email: email.value,
        password: password.value,
        phone_number: phone.value,
        npwp: npwp.value,
        address: address,
        province_id: provinceData?.data?.resData?.data[provIdx]?.id,
        province_name: provinceData?.data?.resData?.data[provIdx]?.name,
        city_id: cityData?.data?.resData?.data[cityIdx]?.id,
        city_name: cityData?.data?.resData?.data[cityIdx]?.name,
        sub_district_id: subDistrictData?.data?.resData?.data[subDIdx]?.id,
        sub_district_name: subDistrictData?.data?.resData?.data[subDIdx]?.name,
        postal_code: postalCode,
        // discount_type: discountType.value,
        // discount: parseInt(discount.value),
        // max_discount: maxDiscount.value,
        discount_type: 0,
        discount: 0,
        max_discount: 0,
        due_date: dueDate.value
      })
    }
  }

  if (type === 'delete') {
    return (
      <Modal
        title="Hapus Perusahaan"
        open={isOpen}
        onOk={() => onDelete(data)}
        onCancel={onCancel}
        zIndex={999}
        centered
        wrapClassName=""
        okText="Confirm"
        okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
        cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
        maskClosable={false}>
        <Typography.Text className="font-medium">
          {`Apakah Anda yakin ingin menghapus perusahaan ${data?.name} ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
        </Typography.Text>
      </Modal>
    )
  }

  return (
    <Modal
      title={type === 'add' ? 'Tambah Perusahaan' : 'Edit Perusahaan'}
      open={isOpen}
      onCancel={onCancel}
      onOk={onOk}
      centered
      width={500}
      zIndex={999}
      wrapClassName=""
      okText={type === 'add' ? 'Tambah Perusahaan' : 'Edit Perusahaan'}
      okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
      cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}
      maskClosable={false}>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Nama Perusahaan
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          className={`rounded ${
            !name.valid && name.touched && 'border-red-500 border border-solid'
          }`}
          placeholder="Masukkan Nama Perusahaan"
          value={name.value}
          onChange={(e) => onChangeInput(e.target.value, name, setName)}
        />
        {!name.valid && name.touched && <span className="text-[red]">{name.messageError}</span>}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Email
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          className={`rounded ${
            !email.valid && email.touched && 'border-red-500 border border-solid'
          }`}
          placeholder="email@gmail.com"
          value={email.value}
          onChange={(e) => onChangeInput(e.target.value, email, setEmail)}
        />
        {!email.valid && email.touched && <span className="text-[red]">{email.messageError}</span>}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Password
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input.Password
          className={`rounded ${
            !password.valid && password.touched && 'border-red-500 border border-solid'
          }`}
          placeholder="Masukkan Password"
          value={password.value}
          onChange={(e) => onChangeInput(e.target.value, password, setPassword)}
        />
        {!password.valid && password.touched && (
          <span className="text-[red]">{password.messageError}</span>
        )}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          Nomor Telepon
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          type="number"
          className={`rounded ${
            !phone.valid && phone.touched && 'border-red-500 border border-solid'
          }`}
          placeholder="Masukan Nomor Telepon"
          value={phone.value}
          onChange={(e) => onChangeInput(e.target.value, phone, setPhone)}
        />
        {!phone.valid && phone.touched && <span className="text-[red]">{phone.messageError}</span>}
      </div>
      <div className="mb-4">
        <Typography.Text className="font-medium">
          NPWP
          <span className="text-[red]">*</span>
        </Typography.Text>
        <Input
          type="number"
          className={`rounded ${
            !npwp.valid && npwp.touched && 'border-red-500 border border-solid'
          }`}
          placeholder="Masukan NPWP"
          value={npwp.value}
          onChange={(e) => onChangeInput(e.target.value, npwp, setNpwp)}
        />
        {!npwp.valid && npwp.touched && <span className="text-[red]">{npwp.messageError}</span>}
      </div>
      <Typography.Text className="font-medium">
        Alamat
        <span className="text-[red]">*</span>
      </Typography.Text>
      <Input.TextArea
        className="rounded mb-4"
        placeholder="Masukkan Alamat"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
      />
      <div className="flex flex-row items-center space-x-2">
        <div className="mb-4 w-1/2">
          <Typography.Text className="font-medium">
            Provinsi
            <span className="text-[red]">*</span>
          </Typography.Text>
          <Select
            className="custom-table-limit"
            style={{ width: '100%' }}
            defaultValue={null}
            value={province.value}
            placeholder="Pilih Provinsi"
            showSearch
            allowClear
            filterOption={(input, option) => {
              return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
            }}
            onChange={(e) => {
              if (province.value !== e) {
                onChangeInput(e ?? null, province, setProvince)
                onChangeInput(null, city, setCity)
                onChangeInput(null, subDistrict, setSubDistrict)
              }
            }}>
            {provinceData?.data?.resData?.data?.map((item) => (
              <Select.Option key={'p-' + item?.id?.toString()} value={item?.id}>
                {item?.name}
              </Select.Option>
            ))}
          </Select>
          {!province.valid && province.touched && (
            <span className="text-[red]">{province.messageError}</span>
          )}
        </div>
        <div className="mb-4 w-1/2">
          <Typography.Text className="font-medium">
            Kota
            <span className="text-[red]">*</span>
          </Typography.Text>
          <Select
            className="custom-table-limit"
            style={{ width: '100%' }}
            defaultValue={null}
            value={city.value}
            placeholder="Pilih Kota/Kabupaten"
            showSearch
            allowClear
            filterOption={(input, option) => {
              return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
            }}
            onChange={(e) => {
              if (city.value !== e) {
                onChangeInput(e ?? null, city, setCity)
                onChangeInput(null, subDistrict, setSubDistrict)
              }
            }}>
            {cityData?.data?.resData?.data?.map((item) => (
              <Select.Option key={'c-' + item?.id?.toString()} value={item?.id}>
                {item?.name}
              </Select.Option>
            ))}
          </Select>
          {!city.valid && city.touched && <span className="text-[red]">{city.messageError}</span>}
        </div>
      </div>
      <div className="flex flex-row items-center space-x-2">
        <div className="mb-4 w-1/2">
          <Typography.Text className="font-medium">
            Kecamatan
            <span className="text-[red]">*</span>
          </Typography.Text>
          <Select
            className="custom-table-limit"
            style={{ width: '100%' }}
            defaultValue={null}
            value={subDistrict.value}
            placeholder="Pilih Kecamatan"
            showSearch
            allowClear
            filterOption={(input, option) => {
              return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
            }}
            onChange={(e) => onChangeInput(e ?? null, subDistrict, setSubDistrict)}>
            {subDistrictData?.data?.resData?.data?.map((item) => (
              <Select.Option key={'s-' + item?.id?.toString()} value={item?.id}>
                {item?.name}
              </Select.Option>
            ))}
          </Select>
          {!subDistrict.valid && subDistrict.touched && (
            <span className="text-[red]">{subDistrict.messageError}</span>
          )}
        </div>
        <div className="w-1/2">
          <Typography.Text className="font-medium">
            Kode Pos
            <span className="text-[red]">*</span>
          </Typography.Text>
          <Input
            className="rounded mb-4"
            placeholder="Masukkan Kode Pos"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />
        </div>
      </div>
      {type === 'edit' ? (
        <>
          {/* DISCOUNT COMPANY FIELDS */}
          {/* <div className="mb-4">
            <Typography.Text className="font-medium">
              Potongan Harga
              <span className="text-[red]">*</span>
            </Typography.Text>
            <div className="flex flex-row items-start space-x-2">
              <div className="w-1/2">
                <div className="my-1">
                  <Radio.Group
                    name="radiogroup"
                    onChange={(e) => {
                      onChangeInput(e.target.value, discountType, setDiscountType)
                      onChangeInput('', discount, setDiscount)
                    }}
                    value={discountType.value}>
                    <Radio className="custom-radio-btn" value={1}>
                      Rupiah
                    </Radio>
                    <Radio className="custom-radio-btn" value={2}>
                      Percent
                    </Radio>
                  </Radio.Group>
                </div>
                <Input
                  type={`${discountType.value === 2 ? 'number' : ''}`}
                  className={`rounded`}
                  placeholder=""
                  prefix={
                    <span
                      className={`text-gray-400 ${
                        discountType.value && discountType.value === 1 ? '' : 'hidden'
                      }`}>
                      Rp.{' '}
                    </span>
                  }
                  suffix={
                    <span
                      className={`text-gray-400 ${
                        discountType.value && discountType.value === 2 ? '' : 'hidden'
                      }`}>
                      {' '}
                      %
                    </span>
                  }
                  value={
                    discountType.value === 1
                      ? discount.value === ''
                        ? ''
                        : formatThousandsINTL(discount.value)
                      : discount.value
                  }
                  onChange={(e) => {
                    if (discountType.value === 1) {
                      if (onlyNumbers(unformatCurrency(e.target.value))) {
                        if (e.target.value !== '') {
                          onChangeInput(unformatCurrency(e.target.value), discount, setDiscount)
                        } else {
                          onChangeInput(e.target.value, discount, setDiscount)
                        }
                      }
                      if (e.target.value === '') {
                        onChangeInput(e.target.value, discount, setDiscount)
                      }
                    } else {
                      onChangeInput(e.target.value, discount, setDiscount)
                    }
                  }}
                />
              </div>
              <div className="w-1/2 mt-2">
                <Typography.Text className="font-medium pb-2">
                  Maksimal Potongan
                  <span className="text-[red]">*</span>
                </Typography.Text>
                <Input
                  className={`rounded`}
                  placeholder=""
                  prefix={<span className="text-gray-400">Rp. </span>}
                  value={maxDiscount.value === '' ? '' : formatThousandsINTL(maxDiscount.value)}
                  onChange={(e) => {
                    if (onlyNumbers(unformatCurrency(e.target.value))) {
                      if (e.target.value !== '') {
                        onChangeInput(unformatCurrency(e.target.value), maxDiscount, setMaxDiscount)
                      } else {
                        onChangeInput(e.target.value, maxDiscount, setMaxDiscount)
                      }
                    }
                    if (e.target.value === '') {
                      onChangeInput(e.target.value, maxDiscount, setMaxDiscount)
                    }
                  }}
                />
              </div>
            </div>
          </div> */}
          <div>
            <Typography.Text className="font-medium">
              Jatuh Tempo
              <span className="text-[red]">*</span>
            </Typography.Text>
            <Select
              className="custom-table-limit"
              style={{ width: '100%' }}
              defaultValue={null}
              value={dueDate.value}
              placeholder="Pilih Jatuh Tempo"
              onChange={(e) => {
                if (dueDate.value !== e) {
                  onChangeInput(e, dueDate, setDueDate)
                }
              }}>
              {dropDownJatuhTempo
                .filter((itemFilter) => itemFilter.value !== -1)
                .map((item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item?.name}
                  </Select.Option>
                ))}
            </Select>
          </div>
        </>
      ) : null}
    </Modal>
  )
}

export default ModalUpdatePerusahaan
