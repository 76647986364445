import React from 'react'

const IcBxReceipt = ({ className, size = 20, currentColor = '#667085' }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill={currentColor}
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9998 9.16667H17.4998C17.9607 9.16667 18.3332 9.53917 18.3332 10V15C18.3332 16.3783 17.2115 17.5 15.8332 17.5H4.1665C2.78817 17.5 1.6665 16.3783 1.6665 15V3.33333C1.6665 2.8725 2.039 2.5 2.49984 2.5H14.1665C14.6273 2.5 14.9998 2.8725 14.9998 3.33333V9.16667ZM3.33317 15C3.33317 15.46 3.7065 15.8333 4.1665 15.8333H13.4757C13.384 15.5733 13.3332 15.2925 13.3332 15V4.16667H3.33317V15ZM15.8332 15.8333C16.2932 15.8333 16.6665 15.46 16.6665 15V10.8333H14.9998V15C14.9998 15.46 15.3732 15.8333 15.8332 15.8333ZM11.6665 5.83333H4.99984V7.5H11.6665V5.83333ZM11.6665 9.16667H4.99984V10.8333H11.6665V9.16667ZM11.6665 12.5H9.1665V14.1667H11.6665V12.5Z"
    />
  </svg>
)

export default IcBxReceipt
