import { Input, Spin, Tooltip } from 'antd'
import { apiPut } from 'api'
import { IcEdit } from 'assets/icons'
import { CardContainer, CustomButton } from 'components'
import ModalLayout from 'layouts/modal-layout'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { showErrorMessage, showSuccessMessage } from 'utils'

const FrameNumberService = ({ frameNumber = '', refetchDetailServis, idBooking, isInProgress }) => {
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }
  const [openModal, setOpenModal] = useState({ data: frameNumber, show: false })
  const [frameNumberInput, setFrameNumberInput] = useState({
    valid: true,
    touched: false,
    messageError: '',
    value: frameNumber
  })
  function handleOpenModal() {
    setOpenModal({
      data: frameNumber,
      show: true
    })
    setFrameNumberInput((prev) => ({ ...prev, value: frameNumber }))
  }
  function handleCloseModal() {
    setOpenModal({ data: frameNumber, show: false })
    setFrameNumberInput({
      valid: false,
      touched: false,
      messageError: '',
      value: frameNumber
    })
  }
  function onChangeInput(e) {
    let isValid = true
    let messageError = ''
    let value = e.target.value.toUpperCase()
    if (value.length == 0) {
      isValid = false
      messageError = 'Wajib diisi'
    } else if (!/^[A-Za-z0-9]+$/.test(value)) {
      messageError = 'Input no rangka tidak dapat menggunakan special character'
      isValid = false
    } else if (value.length < 17 || value.length > 17) {
      isValid = false
      messageError = 'Nomor rangka tidak valid'
    }
    return setFrameNumberInput((prev) => ({
      ...prev,
      valid: isValid,
      touched: true,
      value: value,
      messageError: messageError
    }))
  }
  const updateFrameNumberMutation = useMutation({
    mutationKey: 'updateFrameNumber',
    mutationFn: async () => {
      const res = await apiPut({
        token: token,
        url: `servis-service/admin/bookings/${idBooking}/update-frame-number`,
        data: {
          frame_number: frameNumberInput.value
        },
        onLogout: onLogout
      })
      return res
    },
    onSuccess: (res) => {
      if (res.resStatus >= 400) {
        // console.log(res.resData.errors[0].message)
        return showErrorMessage({ content: 'Terjadi kesalahan, silahkan coba lagi nanti' })
      }
      showSuccessMessage({ content: 'Nomor Rangka berhasil diubah' })
      refetchDetailServis()
      handleCloseModal()
    }
  })

  function handleSubmitFrameNumber() {
    return updateFrameNumberMutation.mutate()
  }
  return (
    <>
      <CardContainer minHeight={'20'} width="90%">
        <div className="flex justify-between items-center gap-1">
          <span className="font-semibold text-2xl text-gray-900">Nomor Rangka</span>
          <div className="flex flex-col xl:flex-row gap-2 items-center">
            <Tooltip title={frameNumber}>
              <span className="line-clamp-1 text-sm-regular">{frameNumber}</span>
            </Tooltip>
            {isInProgress ? (
              <CustomButton type="plain" onClick={handleOpenModal}>
                <div className="flex flex-row items-center justify-center space-x-2">
                  <IcEdit className="text-gray-900" />
                  <span className="xl:block hidden">Edit</span>
                </div>
              </CustomButton>
            ) : null}
          </div>
        </div>
      </CardContainer>
      {openModal?.show ? (
        <ModalLayout
          className={'max-w-md w-full rounded-lg'}
          openModal={openModal?.show}
          headerTitle={'Ubah Nomor Rangka'}
          onCancel={updateFrameNumberMutation?.isLoading ? () => {} : handleCloseModal}>
          <div className="mb-4">
            <p className="font-medium">
              Nomor Rangka
              <span className="text-[red]">*</span>
            </p>
            <Input
              maxLength={17}
              minLength={17}
              className={`rounded ${
                !frameNumberInput.valid &&
                frameNumberInput.touched &&
                'border-red-500 border border-solid'
              }`}
              placeholder="Masukkan Nomor Rangka"
              value={frameNumberInput.value}
              onChange={onChangeInput}
            />
            {!frameNumberInput.valid && frameNumberInput.touched && (
              <span className="text-[red]">{frameNumberInput.messageError}</span>
            )}
          </div>
          <div className="flex flex-row w-full items-center justify-end space-x-4">
            {updateFrameNumberMutation?.isLoading ? (
              <Spin size="large" />
            ) : (
              <>
                <CustomButton type="plain" onClick={() => handleCloseModal()}>
                  Cancel
                </CustomButton>

                <CustomButton
                  disabled={!frameNumberInput.valid || !frameNumberInput.touched}
                  onClick={handleSubmitFrameNumber}>
                  Submit
                </CustomButton>
              </>
            )}
          </div>
        </ModalLayout>
      ) : null}
    </>
  )
}

export default FrameNumberService
