import { mdiPlus } from '@mdi/js'
import { BaseIcon, CustomButton, Loading } from 'components'
import React from 'react'

const HeaderContainer = ({
  title,
  titleAdd,
  onPressAdd,
  isPressAdd,
  iconRight,
  isButtonChild = false,
  iconRightChild,
  titleAddChild,
  onPressAddChild,
  isLoadingSync = false,
  disabled = false,
  isInfo = false,
  InfoChild = null
}) => (
  <div className="flex w-full flex-row items-center justify-between">
    <span className="font-semibold text-2xl text-gray-900">{title}</span>
    {isPressAdd ? (
      <div className="flex flex-row flex-wrap items-center space-x-4">
        {isInfo ? InfoChild : null}
        {isLoadingSync ? (
          <Loading classNameWidth="" visible />
        ) : (
          <CustomButton disabled={disabled} onClick={() => onPressAdd()}>
            <div className="flex justify-center items-center gap-2">
              {iconRight ?? <BaseIcon path={mdiPlus} iconSize={19} color={'#FFFFFF'} />}
              <span>{titleAdd}</span>
            </div>
          </CustomButton>
        )}
        {isButtonChild && (
          <button
            className="flex flex-row items-center justify-around rounded-md border-solid border-primary-500 focus:outline-none space-x-2 px-3 py-2 font-medium text-md text-white bg-primary-500 hover:cursor-pointer"
            onClick={() => onPressAddChild()}>
            {iconRightChild ?? <BaseIcon path={mdiPlus} iconSize={19} color="#FFFFFF" />}
            <span>{titleAddChild}</span>
          </button>
        )}
      </div>
    ) : null}
  </div>
)

export default HeaderContainer
