import React from 'react'
import PartItem from './part-item'

const PartList = ({
  selected,
  handleSelected,
  spareparts,
  selectedData,
  isPacketService = false
}) => {
  return (
    <ol className="max-h-80 bg-gray-50 pl-0 pr-2 rounded-[4px] flex flex-col gap-3 overflow-hidden overflow-y-scroll">
      {spareparts.map((sparepart, index) => (
        <PartItem
          isPacketService={isPacketService}
          sparepart={sparepart}
          key={index}
          handleSelected={handleSelected}
          selected={sparepart?.id === selected}
          selectedData={selectedData}
        />
      ))}
    </ol>
  )
}

export default PartList
