import * as actionTypes from '../actionTypes'

const initialState = {
  tokenType: null,
  token: null,
  isFirstLoggedIn: false,
  profile: {
    id: null,
    email: null,
    name: null,
    phone: null,
    image: null,
    adress: null,
    roleId: null,
    roleName: null,
    session: true,
    pathMenu: null
  },
  accessMenu: []
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTHENTICATE: {
      return {
        ...state,
        tokenType: action.tokenType,
        token: action.token,
        isFirstLoggedIn: true
      }
    }
    case actionTypes.CHANGE_FIRST_LOGGEDIN: {
      return {
        ...state,
        isFirstLoggedIn: action.isFirstLoggedIn
      }
    }
    case actionTypes.GET_DATA_PROFILE: {
      return {
        ...state,
        profile: {
          ...action.profile
        }
      }
    }
    case actionTypes.SET_ACCESS_MENU: {
      return {
        ...state,
        accessMenu: action.accessMenu
      }
    }
    case actionTypes.LOGOUT: {
      return {
        ...initialState,
        profile: {
          ...initialState.profile,
          roleId: action.auth.roleId,
          session: action.auth.session,
          pathMenu: action.auth.pathMenu
        }
      }
    }
    default: {
      return state
    }
  }
}

export default authReducer
