export const urls = {
  auth: {
    user: {
      prefix: 'user-service',
      login: 'auth/login',
      mechanic: 'admin/users/mechanic',
      mechanicByCabang: 'admin/users/mechanic/workshop',
      mechanicByCabangAllSuffix: 'all',
      users: 'admin/users',
      auditTrail: 'admin/audit-trail',
      notificationList: 'website/dashboard/notifications',
      notificationRead: 'website/dashboard/notifications/read',
      carRelease: 'admin/car-release',
      security: 'admin/users/security',
      securityByCabang: 'admin/users/security/workshop'
    }
  },
  testDrive: {
    prefix: 'jual-beli-service',
    root: 'admin/test-drives',
    workshops: {
      root: 'admin/test-drives/schedules',
      all: 'admin/test-drives/schedules/all'
    },
    list: {
      prefix: 'master-service',
      root: 'admin/workshops/service-type/buy-sell'
    },
    additional: {
      prefix: 'master-service',
      workshops: {
        root: 'admin/workshops',
        all: 'admin/workshops/all'
      },
      provinces: {
        all: 'admin/provinces/all'
      },
      cities: {
        root: 'admin/cities/province'
      }
    },
    detail: {
      prefix: 'jual-beli-service',
      list: {
        root: 'admin/test-drives/schedules'
      },
      calendar: {
        root: 'admin/test-drives/calendar-schedule'
      },
      owners: {
        root: 'admin/test-drives/owners'
      },
      testers: {
        root: 'admin/test-drives/testers'
      },
      mechanics: {
        prefix: 'user-service',
        root: 'admin/users/mechanic/workshop'
      }
    },
    confirm: {
      prefix: 'jual-beli-service',
      testDrive: {
        root: 'admin/test-drives/confirm'
      },
      testDriveOngoing: {
        root: 'admin/test-drives/confirm-ongoing'
      },
      testDriveDone: {
        root: 'admin/test-drives/confirm-done'
      },
      mechanic: {
        root: 'admin/test-drives/confirm-mechanic'
      }
    }
  },
  jualUnit: {
    prefix: 'jual-beli-service',
    stock: {
      root: 'admin/stocks',
      owners: 'admin/stocks/owners',
      schedule: {
        prefix: 'inspeksi-service',
        list: 'website/inspection-unit/schedules'
      },
      v2: 'admin/v2/stocks'
    },
    catalog: {
      root: 'admin/catalogs',
      owners: 'admin/catalogs/owners',
      confirmSell: 'admin/catalogs/confirm-sell',
      confirmBooked: 'admin/catalogs/confirm-booking',
      declineSell: 'admin/catalogs/decline-sell',
      declineBooked: 'admin/catalogs/decline-booking',
      exTaxi: {
        root: 'admin/catalogs/taxis',
        confirmSell: 'admin/catalogs/taxis/confirm-sell',
        confirmBooked: 'admin/catalogs/taxis/confirm-booking',
        declineSell: 'admin/catalogs/taxis/decline-sell',
        declineBooked: 'admin/catalogs/taxis/decline-booking'
      },
      confirmBuy: 'admin/catalogs/confirm-buy'
    },
    bookingHistory: {
      root: 'admin/booking-histories',
      owners: 'admin/booking-histories/owners',
      buyers: 'admin/booking-histories/buyers',
      exTaxi: {
        root: 'admin/booking-histories/taxis',
        buyers: 'admin/booking-histories/taxis/buyers'
      }
    },
    contactBirdmobil: 'admin/birdmobil-contacts'
  },
  service: {
    prefix: 'servis-service',
    serviceManagement: {
      serviceCategories: {
        root: 'admin/service-categories',
        all: 'admin/service-categories/all'
      },
      serviceGeneral: {
        root: 'admin/general-services',
        all: 'admin/general-services/all',
        priceList: 'admin/general-services/price-list'
      },
      sparepartCategories: {
        root: 'admin/sparepart-categories',
        all: 'admin/sparepart-categories/all'
      },
      sparepart: {
        root: 'admin/spareparts',
        all: 'admin/spareparts/all'
      },
      servicePackets: {
        root: 'admin/packets',
        all: 'admin/packets/all',
        sparepart: 'admin/packet-service-spareparts'
      },
      service: {
        root: 'admin/services',
        all: 'admin/services/all'
      }
    },
    recommendationService: 'recommendation-service',
    servicePickup: {
      root: 'admin/bookings',
      rootWorkshop: 'admin/bookings/workshop',
      rootService: 'admin/bookings/service',
      rootPickup: 'admin/bookings/pickup',
      serviceSchedules: 'service/schedules',
      pickupSchedules: 'pickup/schedules',
      serviceConfirmed: 'service/confirmed',
      serviceUnconfirmed: 'service/unconfirmed',
      pickupConfirmed: 'pickup/confirmed',
      pickupUnconfirmed: 'pickup/unconfirmed',
      confirmed: 'confirmed',
      assignMechanic: 'assign-mechanic',
      finished: 'finished',
      updateMechanic: 'update-mechanic',
      inProgress: 'in-progress',
      progress: 'progress',
      rootProgressPacket: 'admin/booking-service-packet-spareparts',
      rootProgressGeneral: 'admin/booking-service-generals',
      addService: 'add-service',
      updateNotes: 'update-notes',
      cancel: 'cancel',
      updateMileage: 'update-mileage'
    },
    historiesBooking: 'admin/bookings/histories/unit',
    booking: 'admin/bookings',
    pay: 'pay',
    payment: 'payment'
  },
  sparepart: {
    prefix: 'servis-service',
    sparepartGenerals: {
      root: 'admin/sparepart-generals'
    }
  },
  master: {
    prefix: 'master-service',
    classUnit: {
      root: 'admin/classes',
      all: 'admin/classes/all',
      detailUnit: 'website/dashboard/units'
    },
    location: {
      all: {
        root: 'admin/locations',
        all: 'admin/locations/all',
        filterBySubDistrict: 'admin/locations/subdistrict'
      },
      province: {
        root: 'admin/provinces',
        all: 'admin/provinces/all'
      },
      cities: {
        root: 'admin/cities',
        all: 'admin/cities/all',
        filterByProvince: 'admin/cities/province'
      },
      subDistrict: {
        root: 'admin/subdistricts',
        all: 'admin/subdistricts/all',
        filterByCity: 'admin/subdistricts/city'
      },
      rangePrice: {
        prefix: 'inspeksi-service',
        root: 'admin/inspection-distance-prices',
        all: 'admin/inspection-distance-prices/all'
      }
    },
    perusahaan: {
      detail: {
        unit: {
          prefix: 'master-service',
          root: 'admin/companies',
          list: 'admin/companies/paginate-unit-user'
        },
        user: {
          prefix: 'user-service',
          list: 'admin/companies/users',
          root: 'admin/companies/user',
          all: 'admin/companies/list-users',
          search: 'admin/companies/search-user',
          addExistingUser: 'admin/companies/add-company-user'
        },
        tagihan: {
          prefix: 'user-service',
          list: 'admin/companies/list-transactions',
          root: 'admin/companies/transactions',
          finishManual: 'admin/transactions'
        }
      }
    },
    products: {
      brand: {
        root: 'admin/brands',
        all: 'admin/brands/all'
      },
      category: {
        root: 'admin/categories'
      },
      color: {
        root: 'admin/colors'
      },
      fuel: {
        root: 'admin/fuels',
        all: 'admin/fuels/all'
      },
      model: {
        root: 'admin/models',
        all: 'admin/models/all'
      },
      priceRecommendation: {
        root: 'admin/price-recommendations'
      },
      tipe: {
        root: 'admin/types',
        all: 'admin/types/all'
      },
      transmissions: {
        root: 'admin/transmissions',
        all: 'admin/transmissions/all'
      }
    },
    workshops: {
      root: 'admin/workshops',
      all: 'admin/workshops/all',
      serviceSchedule: 'admin/workshops/service-type/service',
      pickupSchedule: 'admin/workshops/service-type/pickup',
      inspector: {
        prefix: 'user-service',
        root: 'admin/users/inspector',
        listByWorkshop: 'admin/users/inspector/workshop'
      },
      inspectionSchedule: 'admin/workshops/service-type/inspection'
    },
    configuration: {
      biayaLayanan: {
        root: 'admin/configurations/service-prices'
      },
      tax: {
        root: 'admin/configurations/taxes'
      }
    },
    stall: 'admin/workshops/stalls',
    inspection: {
      prefix: 'inspeksi-service',
      grade: {
        root: 'admin/inspection-grades'
      }
    }
  },
  booking: {
    prefix: 'jual-beli-service',
    rootAdmin: 'admin/bookings',
    units: 'admin/bookings/units',
    users: 'users',
    catalog: {
      prefix: 'admin/catalogs'
    },
    exTaxi: {
      root: 'admin/bookings/taxis',
      units: 'admin/bookings/taxis/units'
    }
  },
  inspection: {
    prefix: 'inspeksi-service',
    root: 'inspection-unit',
    rootAdmin: 'admin/inspection-unit',
    history: 'website/inspection-unit/histories',
    calendarSchedule: 'admin/inspection-unit/calendar-schedule',
    customers: 'admin/inspection-unit/customers',
    schedules: 'admin/inspection-unit/schedules',
    schedulesConfirmed: 'inspection_status=1',
    schedulesUnconfirmed: 'inspection_status=0',
    listSchedules: 'admin/inspection-unit/schedules',
    confirmInspectionWorkshop: 'admin/inspection-unit/confirm-workshop',
    confirmInspectionHouse: 'admin/inspection-unit/confirm-house',
    availableStall: 'admin/inspection-unit/available-stalls',
    availableInspector: 'admin/inspection-unit/available-inspectors',
    attendanceConfirmWorkshop: 'admin/inspection-unit/attendance-confirm-workshop',
    attendanceConfirmHouse: 'admin/inspection-unit/attendance-confirm-house',
    confirmInspector: 'admin/inspection-unit/confirm-inspector',
    invoice: 'admin/inspection-unit/invoice'
  },
  analytic: {
    prefixJualBeli: 'jual-beli-service',
    prefixUser: 'user-service',
    prefixMaster: 'master-service',
    prefixService: 'servis-service',
    prefixInspeksi: 'inspeksi-service',
    rootAdmin: 'admin/analytics',
    cabang: {
      testDrive: 'branch/test-drives',
      testDriveTotal: 'branch/test-drives/totals',
      inspectorTotal: 'admin/users/inspector/count',
      inspectorTotalList: 'admin/analytics/branches/inspectors',
      mechanicTotal: 'admin/users/mechanic/count',
      mechanicTotalList: 'admin/users/mechanic',
      workshopTotal: 'admin/workshops/count',
      securityTotal: 'admin/users/security/count',
      security: 'admin/users/security'
    },
    unit: {
      serviceTotal: 'admin/bookings/status/finished',
      jual: 'unit/sells',
      ownerSell: 'unit/sells/owners',
      inspection: 'unit/inspection-unit',
      inspectionCustomers: 'unit/inspection-unit/customers',
      detailUnit: 'admin/analytics/units',
      inspectionCount: 'admin/analytics/unit/inspection-unit/count',
      jualCount: 'admin/analytics/unit/sells/totals',
      serviceCount: 'admin/bookings/count/finished'
    },
    transactionBranch: {
      salesTotalList: 'admin/workshops/sales/transactions',
      salesTotal: 'admin/workshops/sales/totals',
      testDriveTotal: 'admin/workshops/test-drives/totals',
      testDriveTotalList: 'admin/workshops/test-drives',
      testDriveOwner: 'admin/workshops/test-drives/owners',
      testDriveTester: 'admin/workshops/test-drives/testers',
      unitTotal: 'admin/workshops/units/count',
      unitTotalList: 'admin/workshops/units/paginate',
      unitTotalOwner: 'admin/workshops/units/owners',
      unitTotalStatus: 'admin/workshops/units/statuses',
      transactionTypes: 'admin/workshops/sales/transactions/types',
      transactionDetailBeli: 'admin/workshops/transactions/buy',
      transactionDetailInspeksi: 'admin/workshops/inspection-units',
      transactionUnitList: 'admin/transactions/units'
    }
  },
  exTaxi: {
    prefix: 'jual-beli-service',
    createV2: 'admin/v2/taxis',
    createPhotoV2: 'admin/v2/taxis/photos',
    deletePhoto: 'admin/taxis/photos'
  },
  contentManagement: {
    landingPage: {
      prefix: 'master-service',
      root: 'admin/contents/banners'
    }
  }
}
