import React from 'react'
import { CardContainer } from 'components'
import { formatCurrency } from 'utils'
import { Collapse, Divider } from 'antd'
import ChevronDownIcon from 'assets/icons/chevron-down'
import ChevronUpIcon from 'assets/icons/chevron-up'
const { Panel } = Collapse
const PaymentReceiptCard = ({ data, title = 'Rincian Pembayaran', isTypeServis = false }) => {
  const fieldFontStyle = 'text-md-regular text-gray-700'
  const valueFontStyle = 'text-md-regular text-gray-700'

  return (
    <CardContainer>
      <div className="flex flex-row w-full items-center justify-between border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
        <span className="font-semibold text-2xl text-gray-900">{title}</span>
      </div>
      <div className="flex flex-col w-full pt-4 space-y-5">
        <div className="flex flex-col space-y-5 border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
          {/* Service & Product */}

          {isTypeServis ? (
            <div className="flex flex-col gap-3">
              <div className="grid grid-cols-5 gap-2">
                <span className="text-md-semibold text-gray-900">Item</span>
                <span className="text-md-semibold text-center text-gray-900">QTY</span>
                <span className="text-md-semibold text-gray-900">Harga</span>
                <span className="text-md-semibold text-gray-900">Discount</span>
                <span className="text-right text-md-semibold text-gray-900">Total</span>
              </div>
              {data?.listServices?.sparepart?.length > 0 ? (
                <>
                  <Collapse
                    key={'Sparepart Payment'}
                    accordion
                    defaultActiveKey={['Sparepart Payment Panel']}
                    bordered={false}
                    className="text-lg-semibold flex flex-col gap-2 text-gray-900 rounded-[4px] bg-white custom-collapse-layanan"
                    expandIconPosition={'end'}
                    expandIcon={(props) => {
                      return props.isActive ? (
                        <ChevronDownIcon className={'stroke-gray-900 w-3 h-3'} />
                      ) : (
                        <ChevronUpIcon className={'stroke-gray-900 w-3 h-3'} />
                      )
                    }}>
                    <Panel
                      // style={{ border: '1px solid #EAECF0' }}
                      className="bg-white p-0 m-0 "
                      header={
                        <div className="flex justify-between items-center">
                          <span className="text-md-semibold text-gray-900">Sparepart</span>
                          <span className="text-md-semibold text-gray-900">
                            {formatCurrency(data?.sparepart_total ?? 0)}
                          </span>
                        </div>
                      }
                      key={'Sparepart Payment Panel'}>
                      <div className="grid grid-cols-1 gap-2">
                        {data?.listServices?.sparepart?.map((item, idx) => (
                          <div className="grid grid-cols-5 gap-2" key={idx}>
                            <span className="text-md-regular text-gray-700">
                              {item?.sparepart_name}
                            </span>
                            <span className="text-md-regular text-center text-gray-700">
                              {item?.quantity ? `x${item?.quantity}` : ''}
                            </span>
                            <span className="text-md-regular text-gray-700">
                              {formatCurrency(item?.price ?? 0)}
                            </span>
                            <span className="text-md-regular text-primary-500">
                              {item?.discount ? `- ${formatCurrency(item?.discount)}` : ''}
                            </span>
                            <span className="text-right text-md-regular text-gray-700">
                              {formatCurrency(item?.total ?? 0)}
                            </span>
                          </div>
                        ))}
                        <div className="grid grid-cols-5">
                          {data?.sparepart_general_voucher_discount_total ? (
                            <>
                              <Divider className="m-0 my-2 col-span-5" />
                              <span className="text-md-regular text-gray-700 col-span-4">
                                Voucher Sparepart
                              </span>
                              <span className="text-right text-primary-500 text-md-regular">
                                -{' '}
                                {formatCurrency(
                                  data?.sparepart_general_voucher_discount_total ?? 0
                                )}
                              </span>
                            </>
                          ) : null}
                          <>
                            <Divider className="m-0 my-2 col-span-5" />
                            <span className="text-md-medium text-gray-900 col-span-4" colSpan={4}>
                              Subtotal Sparepart
                            </span>
                            <span className="text-right text-md-medium">
                              {formatCurrency(data?.sparepart_total ?? 0)}
                            </span>
                          </>
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                </>
              ) : null}
              <Collapse
                key={'Layanan Payment'}
                accordion
                defaultActiveKey={['Layanan Payment Panel']}
                bordered={false}
                className="text-lg-semibold  flex flex-col gap-2 text-gray-900 rounded-[4px] bg-white custom-collapse-layanan"
                expandIconPosition={'end'}
                expandIcon={(props) => {
                  return props.isActive ? (
                    <ChevronDownIcon className={'stroke-gray-900 w-3 h-3'} />
                  ) : (
                    <ChevronUpIcon className={'stroke-gray-900 w-3 h-3'} />
                  )
                }}>
                <Panel
                  // style={{ border: '1px solid #EAECF0' }}
                  className="bg-white p-0 m-0"
                  header={
                    <div className="flex justify-between items-center">
                      <span className="text-md-semibold text-gray-900">Layanan</span>
                      <span className="text-md-semibold text-gray-900">
                        {formatCurrency(data?.service_price_total ?? 0)}
                      </span>
                    </div>
                  }
                  key={'Layanan Payment Panel'}>
                  <div className="grid grid-cols-1 gap-2">
                    {data?.listServices?.service?.map((item, idx) => (
                      <div className="grid grid-cols-5 gap-2" key={idx}>
                        <span className="text-md-regular text-gray-700">
                          {item?.general_service_name}
                        </span>
                        <span className="text-md-regular text-center text-gray-700">
                          {item?.quantity ? `x${item?.quantity}` : ''}
                        </span>
                        <span className="text-md-regular text-gray-700">
                          {formatCurrency(item?.price ?? 0)}
                        </span>
                        <span className="text-md-regular text-primary-500">
                          {item?.discount ? `- ${formatCurrency(item?.discount)}` : ''}
                        </span>
                        <span className="text-right text-md-regular text-gray-700">
                          {formatCurrency(item?.total ?? 0)}
                        </span>
                      </div>
                    ))}
                    <div className="grid grid-cols-5 ">
                      {data?.service_general_voucher_discount_total ? (
                        <>
                          <Divider className="m-0 my-2 col-span-5" />
                          <span className="text-md-regular text-gray-700 col-span-4">
                            Voucher Layanan
                          </span>
                          <span className="text-right text-primary-500 text-md-regular">
                            - {formatCurrency(data?.service_general_voucher_discount_total ?? 0)}
                          </span>
                        </>
                      ) : null}
                      <>
                        <Divider className="m-0 my-2 col-span-5" />
                        <span className="text-md-medium text-gray-900 col-span-4" colSpan={4}>
                          Subtotal Layanan
                        </span>
                        <span className="text-right text-md-medium">
                          {formatCurrency(data?.service_price_total ?? 0)}
                        </span>
                      </>
                    </div>
                  </div>
                </Panel>
              </Collapse>
              <div className="flex flex-col">
                <Divider className="m-0 my-2 col-span-5" />
                <div className="flex flex-row justify-between gap-2">
                  <span className=" text-md-regular text-gray-700">Subtotal</span>
                  <span className=" text-md-regular text-gray-700">
                    {formatCurrency(data?.subTotal)}
                  </span>
                </div>
              </div>
            </div>
          ) : data?.listServices ? (
            <div className="grid grid-cols-1 gap-5">
              {data?.listServices?.map((data, index) => (
                <div key={index} className="flex flex-col gap-1">
                  <span className="text-lg-medium text-gray-900">{data.serviceName}</span>
                  {/* Mapping Products each Services */}
                  {data?.products?.map(({ itemName, totalItems, totalPrice }, index) => (
                    <div key={index} className="grid grid-cols-5">
                      <span className="col-span-2 text-md-regular text-gray-700">{itemName}</span>
                      <span className="col-span-1 text-right text-md-regular text-gray-700">
                        {totalItems ? `${totalItems}x` : ''}
                      </span>
                      <span className="col-span-2 text-right text-md-regular text-gray-700">
                        {totalPrice}
                      </span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ) : null}
          {data?.tax !== 0 && data?.tax !== '' && (
            <div className="flex flex-row items-start justify-between">
              <span className={fieldFontStyle}>Pajak</span>
              <span className={valueFontStyle}>{formatCurrency(data?.tax ?? 0)}</span>
            </div>
          )}
          {data?.distanceFee !== 0 && data?.distanceFee !== '' && (
            <div className="flex flex-row items-start justify-between">
              <span className={fieldFontStyle}>Biaya Pickup</span>
              <span className={valueFontStyle}>{formatCurrency(data?.distanceFee ?? 0)}</span>
            </div>
          )}
          {data?.adminFee !== 0 && (
            <div className="flex flex-row items-start justify-between">
              <span className={fieldFontStyle}>Biaya Admin</span>
              <span className={valueFontStyle}>{formatCurrency(data?.adminFee ?? 0)}</span>
            </div>
          )}
          {data?.servicePrice && (
            <div className="flex flex-row items-start justify-between">
              <span className={fieldFontStyle}>Harga Layanan</span>
              <span className={valueFontStyle}>{formatCurrency(data?.servicePrice ?? 0)}</span>
            </div>
          )}
        </div>
        <div className="flex flex-col space-y-5 border border-solid border-b-1 border-x-0 border-t-0 pb-4 border-b-gray-300">
          <div className="flex flex-row items-start justify-between">
            <span className={fieldFontStyle}>Total</span>
            <span className={valueFontStyle}>{formatCurrency(data.total ?? 0)}</span>
          </div>
          {data.discountVoucher ? (
            <div className="flex flex-row items-start justify-between">
              <span className={fieldFontStyle}>Diskon Voucher</span>
              <span className={valueFontStyle}>{`- ${formatCurrency(
                data?.discountVoucher ?? 0
              )}`}</span>
            </div>
          ) : null}
          {data.discountCompany ? (
            <div className="flex flex-row items-start justify-between">
              <span className={fieldFontStyle}>Diskon Perusahaan</span>
              <span className={valueFontStyle}>{`- ${formatCurrency(
                data?.discountCompany ?? 0
              )}`}</span>
            </div>
          ) : null}
        </div>
        <div className="flex flex-row items-start justify-between">
          <span className={fieldFontStyle}>Total Pembayaran</span>
          <span className="font-medium text-gray-900">{formatCurrency(data.totalPrice ?? 0)}</span>
        </div>
      </div>
    </CardContainer>
  )
}

export default PaymentReceiptCard
