import React from 'react'

const IcBxMoney = ({ className, size = 20, currentColor = '#667085' }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill={currentColor}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M17.4998 3.33203H2.49984C2.039 3.33203 1.6665 3.70453 1.6665 4.16536V15.832C1.6665 16.2929 2.039 16.6654 2.49984 16.6654H17.4998C17.9607 16.6654 18.3332 16.2929 18.3332 15.832V4.16536C18.3332 3.70453 17.9607 3.33203 17.4998 3.33203ZM16.6665 12.4987C15.2857 12.4987 14.1665 13.6179 14.1665 14.9987H5.83317C5.83317 13.6179 4.714 12.4987 3.33317 12.4987V7.4987C4.714 7.4987 5.83317 6.37953 5.83317 4.9987H14.1665C14.1665 6.37953 15.2857 7.4987 16.6665 7.4987V12.4987Z" />
    <path d="M9.99984 6.66797C8.1615 6.66797 6.6665 8.16297 6.6665 10.0013C6.6665 11.8396 8.1615 13.3346 9.99984 13.3346C11.8382 13.3346 13.3332 11.8396 13.3332 10.0013C13.3332 8.16297 11.8382 6.66797 9.99984 6.66797ZM9.99984 11.668C9.08067 11.668 8.33317 10.9205 8.33317 10.0013C8.33317 9.08214 9.08067 8.33464 9.99984 8.33464C10.919 8.33464 11.6665 9.08214 11.6665 10.0013C11.6665 10.9205 10.919 11.668 9.99984 11.668Z" />
  </svg>
)

export default IcBxMoney
