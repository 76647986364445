import React, { useState } from 'react'
import { formatCurrency, toHoursAndMinutes } from 'utils'
import { CardContainer, CustomButton } from 'components'
import { PlusIcon } from 'assets/icons'
import DrawerPilihLayanan from '../drawer/drawer-pilih-layanan'
const CardService = ({ data, handleGeneralService }) => {
  let lowestPrice = Infinity
  data?.spareparts.map((sparepart) => {
    if (sparepart.price < lowestPrice) lowestPrice = sparepart.price
  })
  const [openModal, setOpenModal] = useState(false)
  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const handleOpenModal = () => {
    setOpenModal(true)
  }
  return (
    <>
      <CardContainer heightMax>
        <div className="grid grid-cols-1 gap-6">
          {/* title & Recommended Tag */}
          <div className="relative">
            {/* Title */}
            <span className="text-lg-semibold text-gray-900">{data?.name}</span>
          </div>
          {/* Estimate & Recommendation Speedometer */}
          <div className="flex gap-6">
            <div className="flex flex-col">
              <span className="text-xs-medium text-gray-500">Estimasi</span>
              <span className="text-sm-medium text-gray-600">
                {toHoursAndMinutes(data?.duration)}
              </span>
            </div>
          </div>
          {/* Price & AddToCard Button */}
          <div className="flex md:flex-row flex-col gap-2 md:justify-between md:items-center">
            <div className="flex flex-col gap-1">
              <span className="text-sm-medium text-gray-500">Mulai dari</span>
              <span className="text-warning-500 text-lg-semibold">
                {formatCurrency(data?.price_total ?? 0)}
              </span>
            </div>
            <CustomButton onClick={handleOpenModal} type="primary">
              <div className="flex items-center gap-3">
                <PlusIcon className={'stroke-white'} />
                Tambah
              </div>
            </CustomButton>
          </div>
        </div>
      </CardContainer>
      {openModal ? (
        <DrawerPilihLayanan
          open={openModal}
          data={data}
          handleGeneralService={handleGeneralService}
          onClose={handleCloseModal}
        />
      ) : null}
    </>
  )
}

export default CardService
