import { Button, DatePicker, Input, Select, Tooltip } from 'antd'
import { apiGet, apiPut, urls } from 'api'
import { IcCheck, IcCheckRound, IcClose, IcLoadMore, IcSearch, IcTrash } from 'assets/icons'
import {
  CardContainer,
  ModalDeleteJadwalUnitService,
  ModalKonfirmasiMekanikJadwalService,
  ModalLoadMoreJadwalService,
  ModalManualPaymentService,
  ModalPerluDikonfirmasiJadwalService,
  ModalServiceSelesaiJadwalService,
  Table,
  TabsActive
} from 'components'
import moment from 'moment'
import 'moment/locale/en-gb'
import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  onReturnStatusTelahDikonfirmasiJadwalService,
  onReturnWaktuPerluDikonfirmasi,
  showErrorMessage,
  showSuccessMessage
} from 'utils'
import { openLinkWA } from 'utils/openLinkWA'
const initialModalManualPayment = {
  show: false,
  type: 'add',
  data: null,
  isLoading: false
}
const ServiceJadwalPerluTelahDikonfirmasiCard = ({
  idCabang,
  stall = 0,
  refetchSchedule,
  onLogout,
  isWorkshop = false
}) => {
  const router = useHistory()
  const roleName = useSelector((state) => state.auth.profile.roleName)
  const token = useSelector((state) => state.auth.token)
  const tabRef = useRef(null)
  const location = useLocation()
  const hashUrl = location?.hash?.split('-')
  const [isLoadingCRUD, setIsLoadingCRUD] = useState(false)

  const [tabs, setTabs] = useState([
    {
      name: 'Perlu Dikonfirmasi',
      active:
        hashUrl?.[hashUrl?.length - 1] === 'confirmed' ||
        hashUrl?.[hashUrl?.length - 1] === '#confirmed'
          ? false
          : true,
      hash: 'unconfirmed'
    },
    {
      name: 'Telah Dikonfirmasi',
      active:
        hashUrl?.[hashUrl?.length - 1] === 'confirmed' ||
        hashUrl?.[hashUrl?.length - 1] === '#confirmed'
          ? true
          : false,
      hash: 'confirmed'
    }
  ])

  useEffect(() => {
    const hashUrl = location?.hash?.split('-')
    if (
      hashUrl?.[hashUrl?.length - 1] === '#confirmed' ||
      hashUrl?.[hashUrl?.length - 1] === 'confirmed' ||
      hashUrl?.[hashUrl?.length - 1] === '#unconfirmed' ||
      hashUrl?.[hashUrl?.length - 1] === 'unconfirmed'
    ) {
      tabRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [location.hash])

  const [filterConfirmed, setFilterConfirmed] = useState({
    totalRows: 0,
    offset: 0,
    limit: 10,
    listData: [],
    stall: '',
    booking_start_date: '',
    booking_end_date: '',
    keywords: '',
    dateRangeValue: []
  })

  const [filterUnconfirmed, setFilterUnconfirmed] = useState({
    totalRows: 0,
    offset: 0,
    limit: 10,
    listData: [],
    stall: '',
    booking_start_date: '',
    booking_end_date: '',
    keywords: '',
    dateRangeValue: []
  })

  const [dropDownMekanik, setDropDownMekanik] = useState([])

  const [dropDownStall, setDropDownStall] = useState([])

  const { RangePicker } = DatePicker
  const [isShowDatePicker, setIsShowDatePicker] = useState(false)

  const handleChangeRangePicker = (dates, dateStrings) => {
    if (tabs[0]?.active) {
      if (dates) {
        setFilterUnconfirmed((prev) => ({
          ...prev,
          dateRangeValue: dates,
          booking_start_date: moment(dateStrings[0], 'DD/MM/YYYY').format('YYYY-MM-DD'),
          booking_end_date: moment(dateStrings[1], 'DD/MM/YYYY').format('YYYY-MM-DD'),
          offset: 0
        }))
      } else {
        setFilterUnconfirmed((prev) => ({
          ...prev,
          dateRangeValue: [],
          booking_start_date: '',
          booking_end_date: '',
          offset: 0
        }))
      }
    } else {
      if (dates) {
        setFilterConfirmed((prev) => ({
          ...prev,
          dateRangeValue: dates,
          booking_start_date: moment(dateStrings[0], 'DD/MM/YYYY').format('YYYY-MM-DD'),
          booking_end_date: moment(dateStrings[1], 'DD/MM/YYYY').format('YYYY-MM-DD'),
          offset: 0
        }))
      } else {
        setFilterConfirmed((prev) => ({
          ...prev,
          dateRangeValue: [],
          booking_start_date: '',
          booking_end_date: '',
          offset: 0
        }))
      }
    }
  }

  //* all modal state //
  const [isModalPerluDikonfirmasi, setIsModalPerluDikonfirmasi] = useState({
    show: false,
    data: {}
  })

  const [isModalServisSelesai, setIsModalServisSelesai] = useState({
    show: false,
    desc: '',
    status: false,
    data: {}
  })

  const [isModalKonfirmasiMekanikKehadiran, setIsModalMekanikkehadiran] = useState({
    show: false,
    typeConfirmation: '',
    stall: '',
    mekanik: ''
  })
  const [isModalDeleteJadwalUnit, setIsModalDeleteJadwalUnit] = useState({
    show: false,
    data: {}
  })
  const [modalManualPayment, setModalManualPayment] = useState({ ...initialModalManualPayment })
  //* END all modal state //

  // Set Value dropdown stall
  useEffect(() => {
    setDropDownStall([
      ...[...Array(stall)].map((arr, idx) => ({ name: `Stall ${idx + 1}`, value: idx + 1 }))
    ])
  }, [stall])

  const columnsUnitPerluDikonfirmasi = [
    {
      name: 'Tanggal',
      selector: (row) => row?.service_date,
      cell: (row) => moment(row?.service_date).format('DD/MM/YYYY'),
      grow: 1.5
    },
    {
      name: 'Unit',
      selector: (row) => row?.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-2 overflow-hidden py-2.5">
          <span className="font-medium text-gray-700">{row?.unit_name}</span>
          <span className="font-normal text-gray-500">{`${row?.police_number ?? ''}`}</span>
        </div>
      ),
      grow: 4
    },
    {
      name: 'Pemilik',
      selector: (row) => row?.full_name,
      cell: (row) => <span className="text-gray-500">{row?.full_name}</span>,
      grow: 2
    },
    {
      name: 'Waktu',
      selector: (row) => row?.service_start_time,
      cell: (row) =>
        onReturnWaktuPerluDikonfirmasi(
          row?.kuota_layanan,
          `${moment(row?.service_start_time, 'HH:mm').format('HH:mm')} - ${moment(
            row?.service_end_time,
            'HH:mm'
          ).format('HH:mm')}`
        ),
      grow: 2
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '200px',
      cell: (row) => (
        <div className="flex items-center justify-center self-center space-x-2">
          <Button
            className="flex flex-row items-center justify-center space-x-2 px-3 border-solid border border-[#12B76A] bg-[#12B76A] focus:bg-[#12B76A] focus:border-[#12B76A] hover:text-white hover:bg-[#12B76A] hover:border-[#12B76A] rounded-md"
            onClick={() =>
              setIsModalPerluDikonfirmasi({
                show: true,
                data: row
              })
            }>
            <IcCheckRound className="text-[#FFFFFF]" size={16} />
            <span className="font-medium text-[#FFFFFF]">Konfirmasi</span>
          </Button>
          <div
            className="hover:cursor-pointer p-1 border-solid border border-[#FDA19B] rounded-md hover:bg-gray-100"
            onClick={() => {
              setIsModalDeleteJadwalUnit((prev) => ({
                ...prev,
                show: true,
                data: row
              }))
            }}>
            <IcTrash className="text-primary-500" size={16} />
          </div>
        </div>
      )
    }
  ]

  const onReturnDescServisSelesai = (status, data) => {
    switch (status) {
      case false:
        return 'Progres servis belum dilengkapi. Mohon cek progress sebelum konfirmasi servis selesai.'
      case true:
        return `Anda Yakin konfirmasi servis selesai untuk unit ${
          data?.unit_name ?? ''
        } dengan pemilik ${data?.full_name ?? ''}.`
      default:
        break
    }
  }

  const onReturnCompleteProgress = (data) => {
    if (data?.progress_finished_total === data?.progress_total) {
      return true
    } else {
      return false
    }
  }

  const handleDateActual = (row) => {
    const start = row?.booking_histories?.find(
      (f) => f?.status === 'SERVICE_IN_PROGRESS'
    )?.completion_time
    const end = row?.booking_histories?.find(
      (f) => f?.status === 'SERVICE_FINISHED'
    )?.completion_time
    const startParse = start ? moment(start).format('DD/MM/YYYY') : ''
    const endParse = end ? moment(end).format('DD/MM/YYYY') : ''

    return startParse
      ? startParse === endParse
        ? `${startParse}`
        : `${startParse} ${endParse ? `- ${endParse}` : ''}`
      : ''
  }

  const handleTimeActual = (row) => {
    const start = row?.booking_histories?.find(
      (f) => f?.status === 'SERVICE_IN_PROGRESS'
    )?.completion_time
    const end = row?.booking_histories?.find(
      (f) => f?.status === 'SERVICE_FINISHED'
    )?.completion_time
    const startParse = start ? moment(start).format('HH:mm') : ''
    const endParse = end ? moment(end).format('HH:mm') : ''

    return startParse ? `${startParse} - ${endParse ?? '-'}` : '-'
  }
  const columnsUnitTelahDikonfirmasi = [
    {
      name: 'Waktu Estimasi',
      selector: (row) => [row?.service_date, row?.service_start_time, row?.service_end_time],
      cell: (row) => (
        <div className="flex flex-col space-y-1 overflow-hidden py-2">
          <span className="font-normal text-gray-500">
            {moment(row?.service_date).format('DD/MM/YYYY')}
          </span>
          <span className="font-medium text-gray-700">
            {onReturnWaktuPerluDikonfirmasi(
              row?.kuota_layanan,
              `${moment(row?.service_start_time, 'HH:mm').format('HH:mm')} - ${moment(
                row?.service_end_time,
                'HH:mm'
              ).format('HH:mm')}`
            )}
          </span>
        </div>
      ),
      grow: 1.5
    },
    {
      name: 'Unit',
      selector: (row) => row?.unit_name,
      cell: (row) => (
        <div className="flex flex-col space-y-1 overflow-hidden">
          <span className="font-medium text-gray-700 ">{row?.unit_name}</span>
          <span className="font-normal text-gray-500">{`${row?.police_number ?? ''}`}</span>
        </div>
      ),
      grow: 2
    },
    {
      name: 'Pemilik',
      selector: (row) => row?.full_name,
      cell: (row) => <span className="font-normal text-gray-500">{row?.full_name}</span>,
      grow: 2
    },
    // {
    //   name: 'Stall',
    //   selector: (row) => row?.stall_no,
    //   cell: (row) => row?.stall_no ?? '-',
    //   grow: 1
    // },
    // {
    //   name: 'Waktu',
    //   selector: (row) => row?.service_start_time,
    //   cell: (row) =>
    //     onReturnWaktuPerluDikonfirmasi(
    //       row?.kuota_layanan,
    //       `${moment(row?.service_start_time, 'HH:mm').format('HH:mm')} - ${moment(
    //         row?.service_end_time,
    //         'HH:mm'
    //       ).format('HH:mm')}`
    //     ),
    //   grow: 1
    // },
    {
      name: 'Status',
      selector: (row) => row?.status,
      cell: (row) => onReturnStatusTelahDikonfirmasiJadwalService(row?.status),
      grow: 1.5
    },
    {
      name: 'Waktu Aktual',
      cell: (row) => (
        <div className="flex flex-col space-y-1 py-1">
          <span className="font-normal text-gray-500">{handleDateActual(row)}</span>
          <span className="font-medium text-gray-700">{handleTimeActual(row)}</span>
        </div>
      ),
      grow: 1.5
    },
    {
      name: 'Paid',
      selector: (row) => row?.booking_invoice?.transaction_detail?.is_payment_completed,
      cell: (row) => (
        <div className="overflow-hidden cursor-pointer">
          <Tooltip
            placement="topRight"
            title={
              row?.booking_invoice?.transaction_detail?.is_payment_completed
                ? 'User sudah melakukan pembayaran'
                : 'User belum melakukan pembayaran'
            }>
            <div
              className={`flex items-center rounded-full p-1 ${
                row?.booking_invoice?.transaction_detail?.is_payment_completed
                  ? 'bg-success-50'
                  : 'bg-gray-50'
              }`}>
              {row?.booking_invoice?.transaction_detail?.is_payment_completed ? (
                <IcCheck size={16} className="text-success-700" />
              ) : (
                <IcClose size={16} className="text-gray-500" />
              )}
            </div>
          </Tooltip>
        </div>
      ),
      grow: 1
    },
    {
      name: 'Action',
      button: true,
      ignoreRowClick: true,
      sortable: false,
      right: true,
      center: true,
      width: '100px',
      cell: (row) => (
        <ModalLoadMoreJadwalService
          onShowModalManualPayment={
            roleName?.toLowerCase()?.includes('finance')
              ? row?.company_id > 0 &&
                row?.user_id > 0 &&
                row?.status === 'SERVICE_FINISHED' &&
                !row?.booking_invoice?.transaction_detail?.is_payment_completed
                ? showModalManualPayment.bind(this, row)
                : undefined
              : undefined
          }
          onShowModalDetailManualPayment={
            row?.company_id > 0 &&
            row?.user_id > 0 &&
            row?.booking_invoice?.transaction_detail?.payment_method == 'manual' &&
            row?.booking_invoice?.transaction_detail?.is_payment_completed
              ? showDetailModalManualPayment.bind(this, row)
              : undefined
          }
          status={row?.status}
          onClickServisSelesai={() => {
            setIsModalDeleteJadwalUnit({ show: false, data: {} })
            setIsModalMekanikkehadiran({ ...isModalKonfirmasiMekanikKehadiran, show: false })
            setIsModalServisSelesai({
              desc: onReturnDescServisSelesai(onReturnCompleteProgress(row), row),
              show: true,
              status: onReturnCompleteProgress(row),
              data: row
            })
          }}
          onClickKonfirmasiKehadiran={() => {
            setIsModalDeleteJadwalUnit({ show: false, data: {} })
            setIsModalServisSelesai({
              ...isModalServisSelesai,
              show: false,
              status: false,
              data: {}
            })
            setIsModalMekanikkehadiran((prev) => ({
              ...prev,
              show: true,
              typeConfirmation: 'kehadiran',
              mekanik: row?.mechanic_id,
              stall: row?.stall_no,
              data: row
            }))
          }}
          onClickKonfirmasiMekanik={() => {
            setIsModalDeleteJadwalUnit({ show: false, data: {} })
            setIsModalServisSelesai({
              ...isModalServisSelesai,
              show: false,
              status: false,
              data: {}
            })
            setIsModalMekanikkehadiran((prev) => ({
              ...prev,
              show: true,
              typeConfirmation: 'mekanik',
              mekanik: row?.mechanic_id,
              data: row
            }))
          }}
          onClickDetailServis={() => router.push(`/services/jadwal/detail/${row.id}`)}
          onClickLihatSO={() => router.push(`/services/jadwal/so/${row.id}`)}
          onClickInvoice={
            row.ownership !== 'Mobil Internal' &&
            ((row?.user_id > 0 &&
              row?.company_id == 0 &&
              row?.booking_invoice?.transaction_detail?.is_payment_completed) ||
              (row?.user_id > 0 && row?.company_id > 0 && row?.status === 'SERVICE_FINISHED'))
              ? () => router.push(`/services/jadwal/${row.id}/invoice`)
              : null
          }
          onClickWhatsApp={() => {
            openLinkWA(row?.phone)
          }}
          onClickDeleteServis={() => {
            {
              setIsModalMekanikkehadiran({ ...isModalKonfirmasiMekanikKehadiran, show: false })
              setIsModalServisSelesai({
                ...isModalServisSelesai,
                show: false,
                status: false,
                data: {}
              })
              setIsModalDeleteJadwalUnit((prev) => ({
                ...prev,
                show: true,
                data: row
              }))
            }
          }}>
          <button
            onClick={() => {}}
            type="button"
            className="flex items-center justify-center border-0 bg-transparent self-center hover:cursor-pointer">
            <IcLoadMore className="text-gray-500" />
          </button>
        </ModalLoadMoreJadwalService>
      )
    }
  ]

  // *API
  // Get Unconfirmed
  const {
    isLoading: isLoadingUnconfirmed,
    refetch: refetchUnconfirmed,
    isFetching: isFetchingUnconfirmed
  } = useQuery({
    queryKey: [
      'unconfirmed',
      idCabang,
      filterUnconfirmed?.keywords,
      filterUnconfirmed?.stall,
      filterUnconfirmed?.booking_start_date,
      filterUnconfirmed?.booking_end_date,
      filterUnconfirmed?.limit,
      filterUnconfirmed?.offset
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.servicePickup.rootWorkshop}/${idCabang}/${urls?.service?.servicePickup.serviceUnconfirmed}?keywords=${filterUnconfirmed?.keywords}&stall_no=${filterUnconfirmed?.stall}&start_date=${filterUnconfirmed?.booking_start_date}&end_date=${filterUnconfirmed?.booking_end_date}&limit=${filterUnconfirmed?.limit}&offset=${filterUnconfirmed?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setFilterUnconfirmed((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get Confirmed
  const {
    isLoading: isLoadingConfirmed,
    refetch: refetchConfirmed,
    isFetching: isFetchingConfirmed
  } = useQuery({
    queryKey: [
      'confirmed',
      idCabang,
      filterConfirmed?.keywords,
      filterConfirmed?.stall,
      filterConfirmed?.booking_start_date,
      filterConfirmed?.booking_end_date,
      filterConfirmed?.limit,
      filterConfirmed?.offset
    ],
    refetchOnWindowFocus: false,
    enabled: !!tabs[1]?.active,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.service?.prefix}/${urls?.service?.servicePickup.rootWorkshop}/${idCabang}/${urls?.service?.servicePickup.serviceConfirmed}?is_show_history=true&keywords=${filterConfirmed?.keywords}&stall_no=${filterConfirmed?.stall}&start_date=${filterConfirmed?.booking_start_date}&end_date=${filterConfirmed?.booking_end_date}&limit=${filterConfirmed?.limit}&offset=${filterConfirmed?.offset}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setFilterConfirmed((prev) => ({
        ...prev,
        listData: res?.resData?.data ?? [],
        totalRows: res?.resData?.meta?.total
      }))
    }
  })

  // Get Mechanic
  useQuery({
    queryKey: ['mechanic'],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls?.auth.user.prefix}/${urls?.auth.user.mechanicByCabang}/${idCabang}/${urls.auth.user.mechanicByCabangAllSuffix}`,
        onLogout
      })
      return res
    },
    onSuccess: (res) => {
      setDropDownMekanik(res?.resData?.data ?? [])
    }
  })

  // Get Manual Payment
  useQuery({
    queryKey: 'getManualPayment',
    queryFn: async () => {
      const res = await apiGet({
        token,
        url: `${urls.service.prefix}/${urls.service.booking}/${modalManualPayment?.data?.id}/${urls?.service?.payment}`,
        onLogout
      })
      return res
    },
    onSuccess: (data) => {
      showDetailModalManualPayment(data?.resData?.data)
    },
    enabled: modalManualPayment.type === 'show' && modalManualPayment?.data?.id !== undefined,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
  })

  async function sendConfirmationManualPayment(data) {
    const formDataManualPayment = new FormData()
    formDataManualPayment.append('proof_of_payment', data.image_filename)
    try {
      const res = await apiPut({
        url: `${urls.service.prefix}/${urls.service.booking}/${data?.id}/${urls.service.pay}`,
        token: token,
        data: formDataManualPayment,
        isFormData: true,
        onLogout
      })
      if (res?.resStatus === 200) {
        showSuccessMessage({ content: 'Sukses Update Pembayaran Servis' })
        closeModalManualPayment()
        refetchConfirmed()
      } else if (res?.resStatus >= 400) {
        showErrorMessage({
          content: `Gagal Update Pembayaran Servis, ${
            res?.resData?.errors?.[0]?.message || res?.resData
          }`
        })
        setModalManualPayment((prev) => ({ ...prev, isLoading: false }))
      }
    } catch (error) {
      console.log('error', error)
      setModalManualPayment((prev) => ({ ...prev, isLoading: false }))
    }
  }

  // Konfirmasi Servis Selesai
  const handleKonfirmasiServiceSelesai = async () => {
    try {
      if (parseInt(isModalServisSelesai?.data?.mileage ?? 0) > 0) {
        setIsLoadingCRUD(true)
        const res = await apiPut({
          url: `${urls.service.prefix}/${urls.service.servicePickup.rootService}/${isModalServisSelesai?.data?.id}/${urls.service.servicePickup.finished}`,
          token: token,
          onLogout
        })
        if (res?.resStatus === 200) {
          setIsModalServisSelesai({ ...isModalServisSelesai, show: false, status: false, data: {} })
          refetchConfirmed()
        } else {
          showErrorMessage({ content: res?.resData?.errors?.[0]?.message })
        }
        setIsLoadingCRUD(false)
      } else {
        showErrorMessage({
          content: 'Kilometer saat ini tidak boleh kosong'
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  // *END API

  function showModalManualPayment(data) {
    setModalManualPayment((prev) => ({ ...prev, show: true, type: 'add', data: data }))
  }
  function showDetailModalManualPayment(data) {
    setModalManualPayment((prev) => ({ ...prev, show: true, type: 'show', data: data }))
  }
  function closeModalManualPayment() {
    setModalManualPayment({ ...initialModalManualPayment })
  }
  async function validationImage(data) {
    if (data?.image_filename === undefined) {
      showErrorMessage({ content: `File belum di upload` })
      setModalManualPayment((prev) => ({ ...prev, isLoading: false }))
    } else if (!data?.image_filename?.type?.includes('image')) {
      showErrorMessage({ content: `File harus berupa gambar` })
      setModalManualPayment((prev) => ({ ...prev, isLoading: false }))
    } else if (data?.image_filename?.size / 1048576 > 2) {
      showErrorMessage({ content: `File harus kurang dari 2 mb` })
      setModalManualPayment((prev) => ({ ...prev, isLoading: false }))
    } else {
      sendConfirmationManualPayment(data)
    }
  }
  function handleSubmitManualPayment(data) {
    setModalManualPayment((prev) => ({ ...prev, isLoading: true }))
    validationImage(data)
  }

  // *Table state
  const handlePerRowsChange = (newPerPage) => {
    if (tabs[0]?.active) {
      setFilterUnconfirmed((prev) => ({
        ...prev,
        limit: newPerPage,
        offset: 0
      }))
    } else {
      setFilterConfirmed((prev) => ({
        ...prev,
        limit: newPerPage,
        offset: 0
      }))
    }
  }

  const onChangePage = (page) => {
    if (tabs[0]?.active) {
      setFilterUnconfirmed((prev) => ({
        ...prev,
        offset: page
      }))
    } else {
      setFilterConfirmed((prev) => ({
        ...prev,
        offset: page
      }))
    }
  }
  // END TABLE STATE

  return (
    <div className="flex flex-col w-full space-y-5">
      <ModalDeleteJadwalUnitService
        isOpen={isModalDeleteJadwalUnit.show}
        data={isModalDeleteJadwalUnit.data}
        onClose={() => setIsModalDeleteJadwalUnit({ show: false, data: {} })}
        refetch={() => {
          if (tabs[0]?.active) {
            refetchUnconfirmed()
          } else {
            refetchConfirmed()
            refetchSchedule()
          }
        }}
        token={token}
        onLogout={onLogout}
      />
      <ModalPerluDikonfirmasiJadwalService
        isOpen={isModalPerluDikonfirmasi.show}
        data={isModalPerluDikonfirmasi?.data}
        onClose={() => setIsModalPerluDikonfirmasi({ show: false })}
        refetch={() => {
          refetchUnconfirmed()
          refetchConfirmed()
          refetchSchedule()
        }}
        token={token}
        onLogout={onLogout}
      />
      <ModalServiceSelesaiJadwalService
        openModal={isModalServisSelesai.show}
        desc={isModalServisSelesai?.desc}
        onClose={() =>
          setIsModalServisSelesai({ ...isModalServisSelesai, show: false, status: false, data: {} })
        }
        status={isModalServisSelesai?.status}
        onSubmit={handleKonfirmasiServiceSelesai}
        loading={isLoadingCRUD}
      />
      <ModalManualPaymentService
        isOpen={modalManualPayment?.show}
        onClose={closeModalManualPayment}
        setModal={setModalManualPayment}
        onSubmitManualPayment={handleSubmitManualPayment}
        data={modalManualPayment?.data}
        type={modalManualPayment?.type}
        isLoading={modalManualPayment?.isLoading}
      />
      <ModalKonfirmasiMekanikJadwalService
        isOpen={isModalKonfirmasiMekanikKehadiran.show}
        state={isModalKonfirmasiMekanikKehadiran}
        dropDownMekanik={dropDownMekanik}
        dropDownStall={dropDownStall}
        onClose={() =>
          setIsModalMekanikkehadiran({ ...isModalKonfirmasiMekanikKehadiran, show: false })
        }
        token={token}
        refetch={refetchConfirmed}
        onLogout={onLogout}
      />
      <CardContainer minHeight="60%">
        <div className="flex flex-col space-y-5">
          <div className="flex-1 w-full">
            <TabsActive
              data={tabs}
              onPress={(index) => {
                const tempArr = [...tabs]
                tempArr.map((itemCh, indexCh) => {
                  if (index === indexCh) {
                    if (itemCh.active) {
                      return false
                    } else {
                      itemCh.active = true
                      if (itemCh?.hash === 'confirmed') {
                        refetchConfirmed()
                      } else {
                        setFilterConfirmed((prev) => ({ ...prev, listData: [] }))
                        refetchUnconfirmed()
                      }
                      router?.push({
                        hash: isWorkshop ? `service-jadwal-${itemCh?.hash}` : itemCh?.hash
                      })
                    }
                  } else {
                    itemCh.active = false
                  }
                })
                setTabs(tempArr)
              }}
            />
          </div>
          <span className="font-semibold text-2xl text-gray-900">
            {`${
              tabs[0].active ? 'Daftar Unit Perlu Dikonfirmasi' : 'Daftar Unit Telah Dikonfirmasi'
            }`}
          </span>
          <div className="flex flex-row w-full items-center justify-between">
            <div className="flex flex-row w-full items-center space-x-5">
              {tabs[1]?.active ? (
                <Select
                  className="custom-table-limit [&.custom-table-limit>.ant-select-selector]:!h-auto [&.custom-table-limit>.ant-select-selector]:!py-1 mr-3"
                  style={{ minWidth: '150px' }}
                  value={tabs[0]?.active ? filterUnconfirmed?.stall : filterConfirmed?.stall}
                  onChange={(e) =>
                    tabs[0]?.active
                      ? setFilterUnconfirmed((prev) => ({ ...prev, stall: e ?? '', offset: 0 }))
                      : setFilterConfirmed((prev) => ({ ...prev, stall: e ?? '', offset: 0 }))
                  }
                  allowClear
                  showSearch>
                  <Select.Option value={''}>Semua Stall</Select.Option>
                  {dropDownStall?.map((stall, idx) => (
                    <Select.Option key={idx} value={stall?.value}>
                      {stall?.name}
                    </Select.Option>
                  ))}
                </Select>
              ) : null}

              <RangePicker
                onOpenChange={(open) => setIsShowDatePicker(open)}
                onChange={handleChangeRangePicker}
                format="DD/MM/YYYY"
                open={isShowDatePicker}
                value={
                  tabs[0]?.active
                    ? filterUnconfirmed?.dateRangeValue
                    : filterConfirmed?.dateRangeValue
                }
                placeholder={['Tanggal Mulai', 'Tanggal Akhir']}
                className="min-w-1/4 rounded-md hover:cursor-pointer font-medium text-gray-700 focus:border-primary-500 active:border-primary-500 py-2"
                renderExtraFooter={() => {
                  return (
                    <div className="flex flex-row justify-end items-center space-x-4 my-3">
                      <button
                        type="button"
                        className="flex flex-row items-center justify-around hover:cursor-pointer rounded-md border border-solid border-gray-300 focus:outline-none space-x-1 px-3 w-1/10 font-medium text-md text-gray-700 bg-white"
                        onClick={() => {
                          setIsShowDatePicker(false)
                        }}>
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="flex flex-row items-center justify-around hover:cursor-pointer rounded-md border border-solid border-primary-500 focus:outline-none space-x-1 px-3 w-1/10 font-medium text-md text-white bg-primary-500"
                        onClick={() => {
                          setIsShowDatePicker(false)
                        }}>
                        Apply
                      </button>
                    </div>
                  )
                }}
              />
            </div>
            <Input
              className="w-1/4 rounded-md py-2"
              placeholder="Pencarian"
              prefix={<IcSearch className="text-gray-400" />}
              value={tabs[0]?.active ? filterUnconfirmed?.keywords : filterConfirmed?.keywords}
              onChange={(e) =>
                tabs[0]?.active
                  ? setFilterUnconfirmed((prev) => ({
                      ...prev,
                      keywords: e?.target?.value,
                      offset: 0
                    }))
                  : setFilterConfirmed((prev) => ({
                      ...prev,
                      keywords: e?.target?.value,
                      offset: 0
                    }))
              }
            />
          </div>
          <div ref={tabRef}>
            <Table
              columns={tabs[0].active ? columnsUnitPerluDikonfirmasi : columnsUnitTelahDikonfirmasi}
              data={tabs[0].active ? filterUnconfirmed?.listData : filterConfirmed?.listData}
              pending={
                tabs[0]?.active
                  ? isLoadingUnconfirmed ?? isFetchingUnconfirmed
                  : isLoadingConfirmed ?? isFetchingConfirmed
              }
              totalRows={
                tabs[0]?.active ? filterUnconfirmed?.totalRows : filterConfirmed?.totalRows
              }
              handlePerRowsChange={handlePerRowsChange}
              pageSize={tabs[0]?.active ? filterUnconfirmed?.limit : filterConfirmed?.limit}
              page={tabs[0]?.active ? filterUnconfirmed?.offset : filterConfirmed?.offset}
              onChangePrevPage={() => {
                if (tabs[0]?.active) {
                  if (filterUnconfirmed?.offset > 0) {
                    onChangePage(filterUnconfirmed?.offset - filterUnconfirmed?.limit)
                  }
                } else {
                  if (filterConfirmed?.offset > 0) {
                    onChangePage(filterConfirmed?.offset - filterConfirmed?.limit)
                  }
                }
              }}
              onChangeNextPage={() => {
                if (tabs[0]?.active) {
                  if (
                    filterUnconfirmed?.totalRows >
                    filterUnconfirmed?.limit + filterUnconfirmed?.offset
                  ) {
                    onChangePage(filterUnconfirmed?.offset + filterUnconfirmed?.limit)
                  }
                } else {
                  if (
                    filterConfirmed?.totalRows >
                    filterConfirmed?.limit + filterConfirmed?.offset
                  ) {
                    onChangePage(filterConfirmed?.offset + filterConfirmed?.limit)
                  }
                }
              }}
            />
          </div>
        </div>
      </CardContainer>
    </div>
  )
}

export default ServiceJadwalPerluTelahDikonfirmasiCard
