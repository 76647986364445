const PlusIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      stroke=""
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99935 1.16797V12.8346M1.16602 7.0013H12.8327"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PlusIcon
