import React, { useRef, useState } from 'react'
import { Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useReactToPrint } from 'react-to-print'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { apiGet, urls } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import {
  HeaderPrint,
  InvoiceCard,
  InvoiceNullifyCard,
  InvoiceSingleCard,
  Loading
} from 'components'
import {
  invoiceKatalogDataDummies,
  invoiceJadwalServiceDataDummies,
  invoiceBookingMobilDataDummies
} from 'assets/dummies'

const InvoicePage = () => {
  const { id, type } = useParams()
  const rootMenu = window.location.pathname.split('/')[1]
  const router = useHistory()
  const componentRef = useRef()
  const user = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [payments, setPayments] = useState()
  const handlePrint = useReactToPrint({
    pageStyle: `@media print {
      @page {
        margin: 2cm 2cm 2cm 2cm !important;
      }
    }`,
    content: () => componentRef.current,
    documentTitle: `${window.location.pathname.split('/')[1]}-${
      window.location.pathname.split('/')[2]
    }-payment-invoice`
  })

  const sumVoucherDiscount = (voucher) => {
    let sum = 0
    voucher?.map((voc) => {
      sum += voc?.price_total
    })

    return sum
  }

  const { isLoading } = useQuery({
    queryKey: ['invoice-dynamic'],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      await apiGet({
        url: onReturnURL(),
        token: user.token,
        onLogout: () => dispatch(logout())
      }),
    onSuccess: (data) => {
      if (data.resStatus === 200) {
        switch (rootMenu) {
          case 'inspeksi':
            return setPayments({
              ...data.resData.data,
              account_name: data?.resData?.data?.owner_name,
              service_type: 'Inspeksi',
              item_name: 'Biaya Inspeksi',
              subtotal_price:
                (data?.resData?.data?.price ?? 0) -
                (data?.resData?.data?.admin_fee ?? 0) -
                (data?.resData?.data?.tax_fee ?? 0),
              admin_price: data?.resData?.data?.admin_fee ?? 0,
              total_price: data?.resData?.data?.price,
              workshop_name: data?.resData?.data?.workshop_name,
              workshop_address: `
              ${data?.resData?.data?.location_name ? data?.resData?.data?.location_name + ', ' : ''}
              ${
                data?.resData?.data?.subdistrict_name
                  ? 'Kec. ' + data?.resData?.data?.subdistrict_name + ', '
                  : ''
              }
              ${
                data?.resData?.data?.city_name
                  ? 'Kota ' + data?.resData?.data?.city_name + ', '
                  : ''
              }
              ${data?.resData?.data?.province_name ? data?.resData?.data?.province_name : ''}
              ${data?.resData?.data?.postal_code ? data?.resData?.data?.postal_code : ''}
              `
            })
          case 'services': {
            let sparepart = []
            let service = []
            data?.resData?.data?.general_services
              ?.filter(
                (f) =>
                  f?.status !== 'WAITING_APPROVAL' &&
                  f?.status !== 'REJECTED' &&
                  f?.status !== 'CANCELED'
              )
              ?.forEach((item) => {
                if (item?.is_free === false) {
                  sparepart.push({
                    sparepart_name: item?.sparepart_name,
                    price: item?.sparepart_price * item?.quantity,
                    quantity: item?.quantity,
                    discount: sumVoucherDiscount(item?.voucher_sparepart_detail),
                    total: item?.sparepart_price_total
                  })
                }
                service.push({
                  general_service_name: item?.general_service_name,
                  price: item?.service_price + item?.class_price,
                  quantity: '',
                  discount: sumVoucherDiscount(item?.voucher_service_detail),
                  total: item?.service_price_total
                })
              }) ?? []

            data?.resData?.data?.packets?.forEach((item) => {
              item?.spareparts
                ?.filter(
                  (f) =>
                    f?.status !== 'WAITING_APPROVAL' &&
                    f?.status !== 'REJECTED' &&
                    f?.status !== 'CANCELED'
                )
                ?.forEach((spare) => {
                  if (spare?.is_free === false) {
                    sparepart.push({
                      sparepart_name: spare?.sparepart_name,
                      price: spare?.sparepart_price * spare?.quantity,
                      quantity: spare?.quantity,
                      discount: sumVoucherDiscount(spare?.voucher_sparepart_detail),
                      total: spare?.sparepart_price_total
                    })
                  }
                  service.push({
                    general_service_name: spare?.general_service_name,
                    price: spare?.service_price + spare?.class_price,
                    quantity: '',
                    discount: sumVoucherDiscount(spare?.voucher_service_detail),
                    total: spare?.service_price_total
                  })
                }) ?? []
            }) ?? []

            return setPayments({
              ...data.resData.data,
              created_at: data.resData.data?.booking_invoice?.created_at,
              invoiceNumber: data.resData.data?.booking_invoice?.invoice_no ?? '-',
              account: data?.resData?.data?.full_name,
              unit: data?.resData?.data?.unit_name,
              layanan: 'Servis',
              items: { sparepart: sparepart, service: service },
              adminFee: data?.resData?.data?.admin_fee ?? 0,
              discount: data?.resData?.data?.voucher_discount_total ?? 0,
              tax: data?.resData?.data?.tax ?? 0,
              distanceFee: data?.resData?.data?.distance_price ?? 0,
              // servicePrice: data?.resData?.data?.class_price_total ?? 0,
              discountCompany: data?.resData?.data?.company_discount_total ?? 0,
              subTotal: data?.resData?.data?.item_total,
              totalPrice: data?.resData?.data?.price_total,
              workshop_name: data?.resData?.data?.workshop_name,
              workshop_address: `${
                data?.resData?.data?.workshop_address
                  ? data?.resData?.data?.workshop_address + ', '
                  : ''
              }
            `
            })
          }
          case 'jual-unit':
            if (type === 'ex-Taxi') {
              return setPayments({
                ...data.resData.data,
                unit_name: data?.resData?.data?.taxi.name,
                item_name: data?.resData?.data?.taxi.name
              })
            }
            return setPayments({
              ...data.resData.data,
              item_name: data?.resData?.data?.unit_name
            })
          default:
            return setPayments({
              ...data.resData.data,
              item_name: data?.resData?.data?.unit_name
            })
        }
      }
    },
    onError: () => {
      //* temporary handle setPayments with dummy data
      switch (rootMenu) {
        // case 'services':
        // return setPayments(invoiceJadwalServiceDataDummies)
        case 'test-drive':
          return setPayments(invoiceKatalogDataDummies)
        case 'booking-mobil':
          return setPayments(invoiceBookingMobilDataDummies)
        default:
          return setPayments(invoiceJadwalServiceDataDummies)
      }
    }
  })

  const onReturnURL = () => {
    switch (rootMenu) {
      case 'jual-unit':
        if (type === 'Mobil Bekas') {
          return `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.root}/${id}/invoice`
        } else {
          return `${urls.jualUnit.prefix}/${urls.jualUnit.catalog.exTaxi.root}/${id}/invoice`
        }
      case 'services':
        return `${urls.service.prefix}/${urls.service.booking}/${id}?is_show_relation=true&is_show_progress=true&is_show_recommendation_service=true&is_show_history=true&is_show_user=true&is_show_company=true`
      case 'inspeksi':
        return `${urls?.inspection.prefix}/${urls?.inspection.invoice}/${id}`
      case 'test-drive':
        return '/'
      case 'booking-mobil':
        return '/'
      default:
        return '/'
    }
  }

  const onReturnContent = () => {
    switch (rootMenu) {
      case 'jual-unit':
      case 'inspeksi':
        return (
          <InvoiceSingleCard
            key="invoice-single-content-print"
            ref={componentRef}
            data={payments}
          />
        )
      case 'services':
        return (
          <InvoiceCard
            key="invoice-content-print"
            ref={componentRef}
            data={payments}
            isTypeServis={true}
          />
        )
      default:
        return <InvoiceCard key="invoice-content-print" ref={componentRef} data={payments} />
    }
  }

  return (
    <Layout>
      <Content className="flex justify-center py-[2%] px-[30%] bg-[#F5F5F5]">
        {isLoading ? (
          <div
            className="flex min-h-screen overflow-x-hidden items-center justify-center"
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: '#FFFFFF'
            }}>
            <Loading visible={true} />
          </div>
        ) : (
          <div className="flex flex-col w-full space-y-5">
            <HeaderPrint
              titleDownload="Download Invoice"
              onPressDownload={payments ? handlePrint : () => {}}
              onPressBack={() => router.goBack()}
            />
            <div className="w-full h-full p-[8%] bg-white">
              {payments ? onReturnContent() : <InvoiceNullifyCard />}
            </div>
          </div>
        )}
      </Content>
    </Layout>
  )
}

export default InvoicePage
