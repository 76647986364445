import React from 'react'

const IcAlertTriangle = ({ className, size = 20, strokeWidth = '1.5' }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.9994 3.99668V5.99668M5.9994 7.99668H6.0044M5.1444 1.42668L0.9094 8.49668C0.822084 8.6479 0.775883 8.81933 0.775395 8.99394C0.774906 9.16855 0.820145 9.34025 0.906613 9.49195C0.993081 9.64365 1.11776 9.77006 1.26825 9.85861C1.41875 9.94716 1.5898 9.99477 1.7644 9.99669H10.2344C10.409 9.99477 10.5801 9.94716 10.7305 9.85861C10.881 9.77006 11.0057 9.64365 11.0922 9.49195C11.1787 9.34025 11.2239 9.16855 11.2234 8.99394C11.2229 8.81933 11.1767 8.6479 11.0894 8.49668L6.8544 1.42668C6.76526 1.27974 6.63976 1.15824 6.49 1.07393C6.34023 0.989608 6.17127 0.945312 5.9994 0.945312C5.82753 0.945313 5.65857 0.989608 5.5088 1.07393C5.35904 1.15824 5.23354 1.27974 5.1444 1.42668Z"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default IcAlertTriangle
