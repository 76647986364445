import { Collapse, Divider, Spin } from 'antd'
import { IcEdit } from 'assets/icons'
import ChevronDownIcon from 'assets/icons/chevron-down'
import ChevronRightIcon from 'assets/icons/chevron-right'
import XCircleIcon from 'assets/icons/x-circle'
import { CardContainer } from 'components'
import { useState } from 'react'
import DrawerPilihLayanan from '../drawer/drawer-pilih-layanan'
import DrawerPilihPacket from '../drawer/drawer-pilih-packet'
import { useMutation } from 'react-query'
import { apiPost } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/actions'
import { showErrorMessage, sortSparepartRecommendation } from 'utils'
import ModalDeleteCartItem from '../modal/modal-delete-cart-item'

const { Panel } = Collapse
const SERVICE_PACKETS = 'SERVICE_PACKETS'
const GENERAL_SERVICE = 'GENERAL_SERVICE'
const initialDrawer = {
  show: false,
  type: GENERAL_SERVICE,
  data: null,
  selectedData: null,
  isLoading: true
}
function CardServiceCart({
  cartList,
  removeCartItem,
  cartIsLoading,
  unitTypeId,
  handlePacketService,
  handleGeneralService
}) {
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }
  const [openDrawer, setOpenDrawer] = useState(initialDrawer)
  const [deleteCartItemModal, setDeleteCartItemModal] = useState({
    show: false,
    data: null,
    serviceType: ''
  })

  const handleCloseDrawer = () => {
    setOpenDrawer({ ...initialDrawer })
  }
  const handleOpenDrawer = (type, data) => {
    if (type === SERVICE_PACKETS) {
      setOpenDrawer({ show: true, type: type, isLoading: true, selectedData: data })
      return postPacketByIdMutation.mutate(data)
    } else {
      const newSelectedData = {
        name: data?.sparepart_name,
        id: data?.sparepart_id,
        quantity: data?.quantity,
        service_price: parseInt(data?.service_price_total ?? 0),
        price: parseInt(data?.sparepart_price ?? 0),
        price_total: parseInt(data?.price_total ?? 0),
        service_id: data?.service_id
      }
      setOpenDrawer({ show: true, type: type, isLoading: true, selectedData: newSelectedData })
      return postGeneralServiceByIdMutation.mutate(data)
    }
  }
  const postPacketByIdMutation = useMutation({
    mutationKey: 'postPacketByIdMutation',
    mutationFn: async (data) => {
      const res = apiPost({
        token: token,
        url: `servis-service/services/packets/${data?.packet_id}`,
        data: { type_id: unitTypeId },
        onLogout: onLogout
      })

      return res
    },
    onSuccess: (res) => {
      // console.log(res)
      if (res?.resStatus > 400) {
        showErrorMessage({ content: 'Terjadi kesalahan, silahkan coba lagi nanti' })
        return console.log(res?.resData?.errors?.[0]?.message)
      }

      setOpenDrawer((prev) => {
        const data = res?.resData?.data
        const general_services = []
        const newObjKeyword = {}
        const newObjIds = {}
        // MAPPING TO GET TOTAL DURATION,GENERAL_SERVICE NAME, LOWEST PRICE
        data?.general_services.map((generalService) => {
          // MASIH PAKE
          if (generalService.is_free === true) {
            newObjIds[generalService.id] = {
              id: 0
            }
          } else if (generalService.is_free === false) {
            const newSparepartArray = sortSparepartRecommendation(generalService?.spareparts)
            generalService.spareparts = newSparepartArray
            let selectedService = null
            selectedService = prev?.selectedData?.spareparts?.find(
              (service) => service?.general_service_id == generalService?.id
            )
            newObjIds[generalService.id] = selectedService
              ? {
                  ...selectedService,
                  name: selectedService?.sparepart_name,
                  id: selectedService?.sparepart_id,
                  duration: selectedService?.service_duration,
                  price: selectedService?.sparepart_price,
                  total_price: selectedService?.sparepart_price_total,
                  service_price: selectedService?.service_price_total
                }
              : null
          }
          general_services.push(generalService.name)
        })
        general_services.forEach((service) => {
          newObjKeyword[service] = null
        })

        return {
          ...prev,
          show: true,
          isLoading: false,
          type: SERVICE_PACKETS,
          data: data,
          newObjIds: newObjIds,
          newObjKeyword: newObjKeyword
        }
      })
    }
  })
  const postGeneralServiceByIdMutation = useMutation({
    mutationKey: 'postGeneralServiceByIdMutation',
    mutationFn: async (data) => {
      const res = apiPost({
        token: token,
        url: `servis-service/services/general-services/${data?.general_service_id}`,
        data: { type_id: unitTypeId },
        onLogout: onLogout
      })

      return res
    },
    onSuccess: (res) => {
      // console.log(res)
      const data = res?.resData?.data
      if (res?.resStatus > 400) {
        showErrorMessage({ content: 'Terjadi kesalahan, silahkan coba lagi nanti' })
        handleCloseDrawer()
        return console.log(res?.resData?.errors?.[0]?.message)
      }
      const newSparepartArray = sortSparepartRecommendation(data?.spareparts)
      data.spareparts = newSparepartArray

      setOpenDrawer((prev) => ({
        ...prev,
        show: true,
        data: data,
        type: GENERAL_SERVICE,
        isLoading: false
      }))
    }
  })
  function handleOpenDeleteItemModal(data, serviceType) {
    setDeleteCartItemModal({ show: true, data: data, serviceType: serviceType })
  }
  function handleCloseDeleteItemModal() {
    setDeleteCartItemModal({
      show: false,
      data: null,
      serviceType: ''
    })
  }
  function handleSubmitDeleteItem() {
    removeCartItem(
      deleteCartItemModal?.serviceType,
      deleteCartItemModal?.serviceType?.includes('packetService')
        ? deleteCartItemModal?.data?.packet_id
        : deleteCartItemModal?.data?.general_service_id
    )
    handleCloseDeleteItemModal()
  }
  return (
    <>
      <CardContainer className={'w-full'} hideDivider>
        <div className="flex w-full flex-col gap-6">
          <span className="text-gray-900 display-xs-semibold border border-solid border-x-0 border-t-0 border-b-1 pb-5 border-gray-300">
            Daftar Servis
          </span>
        </div>
        <div className="mt-5">
          {cartIsLoading ? (
            <div className="flex w-full h-full justify-center items-center min-h-[100px]">
              <Spin size="large" />
            </div>
          ) : !cartList || Object?.entries(cartList)?.length === 0 ? (
            <div className="flex items-center text-center justify-center text-sm-medium text-gray-500 h-[167px]">
              Anda belum menambahkan Servis
            </div>
          ) : (
            <div className="grid grid-cols-1 gap-4">
              {/* servis */}
              {cartList?.packets?.map((packet, key) => {
                return packet?.status === 'WAITING_APPROVAL_DELETE' ? null : (
                  <Collapse
                    key={packet.packet_id}
                    accordion
                    bordered={false}
                    className="text-lg-medium flex flex-col gap-2 text-gray-900 rounded-[4px] bg-white"
                    expandIconPosition={'start'}
                    expandIcon={(props) => {
                      return props.isActive ? (
                        <ChevronDownIcon className={'stroke-gray-400 w-3 h-3'} />
                      ) : (
                        <ChevronRightIcon className={'stroke-gray-400 w-3 h-3'} />
                      )
                    }}>
                    <Panel
                      style={{ border: '1px solid #EAECF0' }}
                      className="bg-gray-50"
                      header={
                        <div
                          key={packet.packet_id}
                          className="flex items-start justify-between xl:gap-4 p-1 bg-gray-50">
                          {/* content */}
                          <span className="text-xs-medium xl:text-sm-medium text-gray-700">
                            {packet?.packet_name}
                          </span>

                          {/* remove button */}
                          <div className="flex justify-center items-start gap-2">
                            <div
                              onClick={(e) => {
                                e.stopPropagation()
                                handleOpenDrawer(SERVICE_PACKETS, packet)
                              }}
                              className="flex flex-row cursor-pointer items-center justify-center space-x-2">
                              <IcEdit className="text-primary-500 w-4 h-4" />
                            </div>
                            <div
                              className="flex items-start cursor-pointer text-center"
                              onClick={(e) => {
                                e.stopPropagation()
                                // console.log('generalService', service.general_service_id)
                                // removeCartItem('packetService', packet.packet_id)
                                handleOpenDeleteItemModal(packet, 'packetService')
                              }}>
                              <XCircleIcon className="stroke-gray-400" />
                            </div>
                          </div>
                        </div>
                      }
                      key={key}>
                      {packet?.spareparts?.length > 0 && (
                        <div>{renderPacketServiceCart(packet?.spareparts)}</div>
                      )}
                    </Panel>
                  </Collapse>
                )
              })}
              {cartList?.general_services?.map((service, key) => {
                return service?.status === 'WAITING_APPROVAL_DELETE' ? null : (
                  <Collapse
                    key={service?.general_service_id}
                    accordion
                    bordered={false}
                    className="text-lg-medium flex flex-col gap-2 text-gray-900 rounded-[4px] bg-white"
                    expandIconPosition={'start'}
                    expandIcon={(props) => {
                      return props.isActive ? (
                        <ChevronDownIcon className={'stroke-gray-400'} />
                      ) : (
                        <ChevronRightIcon className={'stroke-gray-400'} />
                      )
                    }}>
                    <Panel
                      style={{ border: '1px solid #EAECF0' }}
                      className="bg-gray-50 flex flex-col gap-2"
                      header={
                        <div
                          key={service?.general_service_id}
                          className="flex items-start justify-between gap-4 p-1 bg-gray-50">
                          {/* content */}
                          <span className="text-xs-medium xl:text-sm-medium text-gray-700">
                            {service?.general_service_name}
                          </span>

                          {/* remove button */}
                          <div className="flex justify-center items-start gap-2">
                            <div
                              onClick={(e) => {
                                e.stopPropagation()
                                handleOpenDrawer(GENERAL_SERVICE, service)
                              }}
                              className="flex flex-row cursor-pointer items-center justify-center space-x-2">
                              <IcEdit className="text-primary-500 w-4 h-4" />
                            </div>
                            <div
                              className="flex items-start cursor-pointer text-center"
                              onClick={(e) => {
                                e.stopPropagation()
                                // console.log('generalService', service.general_service_id)
                                // removeCartItem('generalService', service.general_service_id)
                                handleOpenDeleteItemModal(service, 'generalService')
                              }}>
                              <XCircleIcon className="stroke-gray-400" />
                            </div>
                          </div>
                        </div>
                      }
                      key={key}>
                      <div>
                        <>
                          <div className="text-xs-regular text-gray-500 pb-2">
                            {service?.sparepart_name !== '' && (
                              <>
                                <p className="text-xs-medium text-gray-900">Sparepart</p>
                                <div key={key} className="flex justify-between items-center">
                                  <span>{service?.sparepart_name}</span>
                                  <span className="flex justify-center">x{service?.quantity}</span>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="text-xs-regular text-gray-500 pb-2">
                            <p className="text-xs-medium text-gray-900">Layanan</p>
                            <div className="flex items-center">
                              <span>{service?.general_service_name}</span>
                            </div>
                          </div>
                        </>
                      </div>
                    </Panel>
                  </Collapse>
                )
              })}
              <Divider className="my-0" />
            </div>
          )}
        </div>
      </CardContainer>
      {openDrawer.show && openDrawer.type == GENERAL_SERVICE ? (
        <DrawerPilihLayanan
          data={openDrawer?.data}
          open={openDrawer?.show}
          isLoading={openDrawer?.isLoading}
          onClose={handleCloseDrawer}
          selectedData={openDrawer?.selectedData}
          handleGeneralService={handleGeneralService.bind(
            this,
            openDrawer?.selectedData?.service_id
          )}
        />
      ) : null}
      {openDrawer.show && openDrawer.type == SERVICE_PACKETS ? (
        <DrawerPilihPacket
          open={openDrawer?.show}
          onClose={handleCloseDrawer}
          data={openDrawer?.data}
          isLoading={openDrawer?.isLoading}
          newObjIds={openDrawer?.newObjIds}
          handlePacketService={handlePacketService.bind(
            this,
            openDrawer?.selectedData?.packet_id,
            openDrawer?.selectedData?.service_id
          )}
          newObjKeyword={openDrawer?.newObjKeyword}
        />
      ) : null}
      {deleteCartItemModal?.show ? (
        <ModalDeleteCartItem
          open={deleteCartItemModal?.show}
          onClose={handleCloseDeleteItemModal}
          data={deleteCartItemModal?.data}
          serviceType={deleteCartItemModal?.serviceType}
          onConfirm={handleSubmitDeleteItem}
        />
      ) : null}
    </>
  )
}
function renderPacketServiceCart(general_services) {
  let layananList = []
  let sparepartList = []
  general_services?.map((item) => {
    if (item?.sparepart_name !== '') {
      sparepartList.push({
        label: item.sparepart_name,
        price: item.sparepart_price * item.quantity,
        quantity: item.quantity,
        total: item.sparepart_price_total,
        discounts: item?.voucher_sparepart_detail !== null ? item?.voucher_sparepart_detail : []
      })
    }
    layananList.push({
      label: item.general_service_name,
      price: item.class_price + item.service_price,
      quantity: 0,
      total: item.service_price_total,
      discounts: item?.voucher_service_detail !== null ? item?.voucher_service_detail : []
    })
  })
  return (
    <>
      <div className="text-xs-regular text-gray-500 pb-2">
        {sparepartList?.length > 0 && (
          <>
            <p className="text-xs-medium text-gray-900">Sparepart</p>
            <div className="flex gap-2 flex-col">
              {sparepartList?.map((item, key) => (
                <div key={key} className="flex justify-between items-center">
                  <span>{item?.label}</span>
                  <span className="flex justify-center ">x{item?.quantity}</span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <div className="text-xs-regular text-gray-500 pb-2">
        <p className="text-xs-medium text-gray-900">Layanan</p>
        <div className="flex flex-col gap-2">
          {layananList.map((item, key) => (
            <span key={key}>{item?.label}</span>
          ))}
        </div>
      </div>
    </>
  )
}
export default CardServiceCart
