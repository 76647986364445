import React, { useState } from 'react'
import { ChecklistIcon, PlusIcon } from 'assets/icons'
import { formatCurrency, toHoursAndMinutes } from 'utils'
import { CardContainer, CustomButton } from 'components'
import DrawerPilihPacket from '../drawer/drawer-pilih-packet'
const CardPacketService = ({ data, handlePacketService }) => {
  const general_services = []
  const newObjKeyword = {}
  let duration = 0
  const newObjIds = {}
  // MAPPING TO GET TOTAL DURATION,GENERAL_SERVICE NAME, LOWEST PRICE
  data?.general_services.map((generalService) => {
    // MASIH PAKE
    duration += generalService.duration
    if (generalService.is_free === true) {
      newObjIds[generalService.id] = {
        id: 0
      }
    } else if (generalService.is_free === false) {
      newObjIds[generalService.id] = null
    }
    general_services.push(generalService.name)
  })
  general_services.forEach((service) => {
    newObjKeyword[service] = null
  })

  const [expand, setExpand] = useState(false)

  const [openModal, setOpenModal] = useState(false)

  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const slicedData = expand ? general_services : general_services.slice(0, 5)
  return (
    <>
      <CardContainer heightMax>
        <div className="grid grid-cols-1 gap-6">
          {/* title & Recommended Tag */}
          <div>
            {/* Title */}
            <p className="text-lg-semibold text-gray-900">{data?.name}</p>
          </div>
          {/* Estimate & Recommendation Speedometer */}
          <div className="flex gap-6">
            <div className="flex flex-col">
              <p className="text-xs-medium text-gray-500">Estimasi</p>
              <p className="text-sm-medium text-gray-600">{toHoursAndMinutes(duration)}</p>
            </div>
          </div>
          {/* Service List Detail */}
          <div className="grid grid-cols-2 gap-2 content-between">
            {slicedData.map((data, index) => (
              <p
                key={index}
                className="text-gray-600 text-sm-medium grid grid-cols-[12px_1fr] items-center gap-2">
                <ChecklistIcon className={'h-3 w-3'} /> {data}
              </p>
            ))}
            {general_services.length > 5 ? (
              <div className="flex items-center">
                <p
                  onClick={() => {
                    setExpand((prevValue) => !prevValue)
                  }}
                  className={`cursor-pointer text-sm-medium ${
                    expand ? 'text-gray-500' : 'text-primary-600'
                  }`}>
                  {expand ? 'Lebih Sedikit' : `+${general_services.length - 5} more view all`}
                </p>
              </div>
            ) : null}
          </div>
          {/* Price & AddToCard Button */}
          <div className="flex md:flex-row flex-col gap-2 md:justify-between md:items-center">
            <div className="flex flex-col gap-1">
              <p className="text-sm-medium text-gray-500">Mulai dari</p>
              <p className="text-warning-500 text-lg-semibold">
                {formatCurrency(data?.price_total ?? 0)}
              </p>
            </div>
            <CustomButton onClick={handleOpenModal} type="primary">
              <div className="flex items-center gap-3">
                <PlusIcon className={'stroke-white'} />
                Tambah
              </div>
            </CustomButton>
          </div>
        </div>
      </CardContainer>
      {openModal ? (
        <DrawerPilihPacket
          data={data}
          onClose={handleCloseModal}
          open={openModal}
          newObjIds={newObjIds}
          newObjKeyword={newObjKeyword}
          handlePacketService={handlePacketService}
        />
      ) : null}
    </>
  )
}

export default CardPacketService
