import { IcChevronLeft, IcChevronRight, IcClose, IcImage } from 'assets/icons'
import React from 'react'

const PhotoPicker = React.forwardRef(
  (
    {
      name,
      title,
      notes,
      photo,
      isRequired,
      isTitle,
      isNotes,
      isDelete,
      isMultiple = false,
      onDeletePhoto,
      handleOnChange,
      handleonClick,
      w,
      h,
      objectPhotoDisplay = 'object-cover',
      isBorderDashed,
      index,
      onSwapOrder,
      photoLength
    },
    ref
  ) => (
    <div className={`relative flex-none space-y-2 ${w ?? 'w-60'}`}>
      {index !== undefined ? (
        <div className="flex absolute z-50 top-3 left-1 !mt-0 w-10 h-10 rounded-full items-center justify-center hover:cursor-pointer bg-gray-500/40">
          <span className="text-white font-semibold text-md">{index + 1}</span>
        </div>
      ) : null}
      {isDelete ? (
        <div
          onClick={onDeletePhoto}
          className="flex absolute z-50 top-3 right-1 !mt-0 w-10 h-10 rounded-full items-center justify-center hover:cursor-pointer bg-gray-500/40 hover:bg-gray-500">
          <IcClose className="text-white" />
        </div>
      ) : null}
      {onSwapOrder !== undefined && index > 0 ? (
        <div
          onClick={() => onSwapOrder('LEFT')}
          className="flex absolute z-50 top-[50%] left-1 w-10 h-10 rounded-full items-center justify-center hover:cursor-pointer bg-gray-500/40 hover:bg-gray-500">
          <span className="text-white font-semibold text-md flex justify-center items-center">
            <IcChevronLeft />
          </span>
        </div>
      ) : null}
      {onSwapOrder !== undefined && index < photoLength - 1 ? (
        <div
          onClick={() => onSwapOrder('RIGHT')}
          className="flex absolute z-50 top-[50%] right-1 w-10 h-10 rounded-full items-center justify-center hover:cursor-pointer bg-gray-500/40 hover:bg-gray-500">
          <span className="text-white font-semibold text-md flex justify-center items-center">
            <IcChevronRight />
          </span>
        </div>
      ) : null}
      {isTitle ? (
        <div>
          <span className="font-medium block text-gray-900 text-lg">
            {title}
            {isRequired ? <span className="text-red-600">{' *'}</span> : null}
          </span>
        </div>
      ) : null}
      <div
        onClick={isDelete ? () => {} : (e) => handleonClick(e)}
        className={`flex flex-col w-full ${
          h ?? 'h-44'
        } bg-gray-100 rounded-lg justify-center hover:cursor-pointer ${
          isBorderDashed ? 'border-2 border-dashed border-gray-300' : ''
        }`}>
        {photo ? (
          <img
            src={photo}
            alt={name}
            className={`h-full rounded-lg object-center ${objectPhotoDisplay}`}
          />
        ) : (
          <div className="flex flex-col space-y-2 items-center">
            <IcImage className="text-gray-400" />
            <span className="font-normal text-gray-400">Tambah Gambar</span>
          </div>
        )}
      </div>
      {isDelete ? null : (
        <input
          ref={ref}
          name={name}
          type="file"
          accept="image/*"
          multiple={isMultiple}
          style={{
            display: 'none'
          }}
          onChange={(e) => {
            handleOnChange(e)
          }}
        />
      )}
      {isNotes ? (
        <div>
          <span className="font-medium text-gray-500">{notes}</span>
        </div>
      ) : null}
    </div>
  )
)

export default PhotoPicker
