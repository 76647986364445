import { Input, Modal, Radio } from 'antd'
import React, { useState } from 'react'
import { showErrorMessage } from 'utils'

const ModalEditQuantitySparepartGeneral = ({ isOpen, onCancel, onSubmit, data }) => {
  const [forms, setForms] = useState({
    qty: data?.qty,
    uom: data?.uom ?? ''
  })
  return (
    <Modal
      title="Edit Sparepart"
      open={isOpen}
      onOk={() => {
        if (parseInt(forms?.qty) < 1) {
          showErrorMessage({ content: 'Data Qty tidak boleh 0' })
          return
        }
        if (forms?.uom === '') {
          showErrorMessage({ content: 'Data Satuan Wajib diisi' })
          return
        }
        if (forms?.qty > 2147483647) {
          showErrorMessage({ content: 'Quantity melebihi batas maksimal' })
          return
        }

        onSubmit({ ...data, qty: forms?.qty, uom: forms?.uom })
      }}
      centered
      onCancel={() => {
        onCancel()
      }}
      okText="Confirm"
      okButtonProps={{ className: 'bg-primary-500', type: 'danger' }}
      cancelButtonProps={{ className: 'hover:text-[#000] hover:border-[#000]', type: 'default' }}>
      <div className="flex justify-between items-center">
        <div>{data?.name}</div>
        <div className="flex items-center">
          <div>Qty: </div>
          <Input
            className="rounded-md py-1 w-10 ml-1 mr-3"
            type="numeric"
            max={2147483647}
            maxLength={10}
            value={forms?.qty}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, '')
              setForms((prev) => ({
                ...prev,
                qty: value === '' ? '' : value
              }))
            }}
          />
          <Radio.Group
            className="custom-radio-btn flex"
            name="radiogroup"
            value={forms?.uom}
            onChange={(e) => setForms((prev) => ({ ...prev, uom: e.target.value }))}>
            <Radio value={'Pcs'}>Pcs</Radio>
            <Radio value={'L'}>Liter</Radio>
          </Radio.Group>
        </div>
      </div>
    </Modal>
  )
}

export default ModalEditQuantitySparepartGeneral
