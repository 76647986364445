const ChevronUpIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      stroke=""
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 6.5L6.5 1.5L1.5 6.5"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ChevronUpIcon
