import { IcCheck } from 'assets/icons'
import React from 'react'

const CustomStep = ({ options = ['Edit Servis', 'Review'], currentStep = 0 }) => {
  return (
    <div className="flex items-center gap-4">
      {options.map((option, index) => (
        <React.Fragment key={index}>
          <div className="flex gap-2 items-center">
            <div
              className={`w-8 h-8 text-sm-medium flex justify-center items-center rounded-full ${
                currentStep > index
                  ? 'bg-[#E12B25]'
                  : currentStep == index
                  ? 'bg-primary-100 text-primary-500'
                  : 'bg-gray-100 text-gray-500'
              }`}>
              {currentStep > index ? <IcCheck className={'w-4 h-4 text-white'} /> : index + 1}
            </div>
            <span
              className={`text-md-medium ${
                currentStep > index
                  ? 'text-primary-500'
                  : currentStep == index
                  ? 'text-primary-500'
                  : 'text-gray-500'
              }`}>
              {option}
            </span>
          </div>
          {index !== options.length - 1 ? (
            <div className="w-[71px] rounded-full h-1 bg-gray-300"></div>
          ) : null}
        </React.Fragment>
      ))}
    </div>
  )
}

export default CustomStep
